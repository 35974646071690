/********** Template CSS **********/
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

/* font-family: 'Lato', sans-serif; */
:root {
  --primary: #7ebc12;
  --secondary: #ff0f10;
  --light: #f2f2f2;
  --dark: #1d2833;
  --button-secondary-bg: #f85d18;
  --links: #444;
  --links-hover: #f85d18;
  --max-width: 1440px;
  --theme-font: "Archivo", sans-serif;
  --max-lp-width: 1200px;
}

/*** Spinner ***/
/* #new_version #spinner {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#new_version #spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
} */
.search-bar::placeholder {
  color: rgba(0, 0, 0, 0.3654) !important;
}

.mb-4-5 {
  margin-bottom: 2.5rem;
}

#new_version ol,
#new_version ul {
  padding-left: 0rem;
}

#new_version .back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

body #new_version,
#new_version p {
  font-weight: 400;
  color: #1a1a1a;
  font-size: 1rem !important;
  line-height: 1.5 !important;
}

#new_version img {
  margin: auto;
  max-width: 100%;
}

/*** Heading ***/
#new_version h1,
#new_version h2,
#new_version .fw-bold {
  font-weight: 700 !important;
}

#new_version h3,
#new_version h4,
#new_version .fw-semi-bold {
  font-weight: 600 !important;
}

#new_version h5,
#new_version h6,
#new_version .fw-medium {
  font-weight: 500 !important;
}

/*** Button ***/
#new_version .btn {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  transition: 0.5s;
}

#new_version .btn.btn-primary,
#new_version .btn.btn-secondary {
  color: #ffffff;
}

#new_version .btn-square {
  width: 38px;
  height: 38px;
}

#new_version .btn-sm-square {
  width: 32px;
  height: 32px;
}

#new_version .btn-lg-square {
  width: 48px;
  height: 48px;
}

#new_version .btn-square,
#new_version .btn-sm-square,
#new_version .btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 6px;
}

/*** Navbar ***/
#new_version #navigationBar .navbar-light .wd1200 {
  display: flex;
}

#new_version .navbar-light .navbar-nav .nav-link {
  position: relative;
  padding: 15px 10px;
  margin: 0 5px;
  font-family: "Roboto", sans-serif;
  color: #ffffff !important;
  outline: none;
  font-weight: 700;
  font-size: 1.125rem !important;
  line-height: 1.25rem !important;
  cursor: pointer;
}

#new_version .sticky-top.navbar-light .navbar-nav .nav-link {
  color: #1565d8 !important;
}

#new_version .navbar-light.desktop {
  display: flex;
  background: #fff;
}

#new_version .navbar-light.mobile {
  display: none;
}

#new_version .navbar-light div.wd {
  width: 90%;
  max-width: var(--max-width);
  margin: auto;
  display: flex;
  align-items: center;
}

#new_version .container,
.modal-open #new_version .container {
  width: 100%;
  max-width: var(--max-width);
}

#new_version .navbar-light .navbar-nav .nav-link:hover,
#new_version .navbar-light .navbar-nav .nav-link.active {
  color: #fff !important;
}

#new_version .sticky-top.navbar-light .navbar-nav .nav-link:hover,
#new_version .sticky-top.navbar-light .navbar-nav .nav-link.active {
  color: #1565d8 !important;
}

#new_version .navbar-light .navbar-brand h1 {
  color: #ffffff;
}

#new_version .navbar-light .navbar-brand img {
  max-height: 80px;
  margin: 0px 0;
  width: 184px;
  height: 80px;
}

#new_version .whiteLogo {
  display: block;
}

#new_version .blackLogo {
  display: none;
}

#new_version .sticky-top .whiteLogo {
  display: none !important;
}

#new_version .sticky-top .blackLogo {
  display: block !important;
}

#new_version .sticky-top.navbar-light .navbar-brand img {
  max-height: 70px;
  width: 184px;
  height: 70px;
}

/*** Hero Header ***/
#new_version .hero-header {
  /* background:none; */
  width: 100%;
  height: auto;
  /* background-repeat: no-repeat; */
  background: #fbfbfb;
}

#new_version .breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.5);
}

/*** Section Title ***/
#new_version .section-title::before {
  position: absolute;
  content: "";
  width: 60px;
  height: 11px;
  bottom: 0;
  left: 0;
  border-top: 1px solid #5a5a5a;
  border-bottom: 1px solid #5a5a5a;
}

#new_version .section-title::after {
  position: absolute;
  content: "";
  width: 90px;
  height: 1px;
  bottom: 5px;
  left: 0;
  background: var(--primary);
}

#new_version .section-title.text-center::before {
  left: 50%;
  margin-left: -30px;
}

#new_version .section-title.text-center::after {
  width: 120px;
  left: 50%;
  margin-left: -60px;
}

#new_version .footer p {
  font-size: 0.938rem !important;
}

#new_version .footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid #ffffff;
  border-radius: 35px;
  transition: 0.3s;
}

#new_version .footer .btn.btn-social:hover {
  color: var(--primary);
}

#new_version .footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #ffffff;
  font-size: 0.938rem !important;
  font-weight: normal;
  transition: 0.3s;
}

#new_version .footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

#new_version .footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

#new_version .footer .copyright {
  padding: 25px 0;
  font-size: 0.938rem !important;
  border-top: 1px solid rgba(256, 256, 256, 0.3);
}

#new_version .footer .copyright a {
  color: var(--light);
}

#new_version .footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
}

#new_version .footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

#new_version .btn-transparent {
  background: transparent;
  border-radius: 50px;
  font-weight: 700;
  font-size: 1rem !important;
  line-height: 2.125rem !important;
  text-align: center;
  color: #fff;
  width: 125px;
  /* border: 2px solid #fff; */
  color: var(--links);
  display: flex;
  align-items: center;
  justify-content: center;
}

#new_version .btn-transparent:hover,
#new_version .btn-transparent:focus {
  /* color: #1565d8; */
  /* background-color: #ffffff; */
  /* border-color: #fff;
  box-shadow: 0 10px 20px 3px #2381cf33; */
  color: var(--links-hover);
}

#new_version .btn-login {
  color: #ffffff;
  background-color: var(--button-secondary-bg);
  border-radius: 0px;
  font-weight: 700;
  font-size: 1rem !important;
  line-height: 2.4rem !important;
  text-align: center;
  width: 125px;
  font-family: var(--theme-font);
  /* border: 2px solid #fff; */
}
.theme-img {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: url(../images/angle_right.svg) no-repeat center center;
  margin: 0 auto 0 15px;
}

#new_version .btn-login:hover,
#new_version .btn-login:focus {
  color: #fff;
  background-color: var(--button-secondary-bg);
}

#new_version .sticky-top.navbar-light .btn-transparent {
  /* background: #1565d8 !important;
  border: 2px solid #1565d8; */
  color: #fff;
}

#new_version .sticky-top.navbar-light .btn-transparent .theme-img {
  filter: brightness(0) invert(1);
}

#new_version .sticky-top.navbar-light .btn-transparent:hover,
#new_version .sticky-top.navbar-light .btn-transparent:focus {
  /* background: #fff !important;
  border: 2px solid #1565d8; */
}

#new_version .sticky-top.navbar-light .btn-login {
  /* border: 2px solid #1565d8; */
}

#new_version .sticky-top.navbar-light .btn-login:hover,
#new_version .sticky-top.navbar-light .btn-login:focus {
  /* background: #1565d8 !important;
  border: 2px solid #1565d8; */
}

#new_version .ms-6 {
  margin-left: 80px;
}

#new_version .para-text1 {
  font-weight: 800;
  font-size: 2.188rem !important;
  line-height: 2.625rem !important;
}

#new_version .para-text2 {
  font-weight: 700;
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}

#new_version .para-text3 {
  font-size: 1.25rem !important;
  line-height: 1.5rem !important;
}

#new_version .para-text4 {
  font-weight: 400;
  font-size: 1.375rem !important;
  line-height: 1.65rem !important;
}

#new_version .para-text5 {
  font-weight: 600;
  font-size: 2rem !important;
  line-height: 2.375rem !important;
}

#new_version .para-text7 {
  font-weight: 700;
  font-size: 2.375rem !important;
  line-height: 2.8rem !important;
}

#new_version .para-text8 {
  font-weight: 500;
  font-size: 1.063m !important;
  line-height: 1.5rem !important;
  text-align: center;
  color: #1a1a1a;
}

#new_version .wd1200 {
  width: 1156px;
  margin: auto;
  padding: 0;
}

#new_version .wd500 {
  width: 580px;
  /* margin: auto; */
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fbfbfb inset !important;
}

#new_version .text-lg-end {
  text-align: right;
}

#new_version .text-black {
  color: #3d3d3d;
  font-family: var(--theme-font);
}

#new_version .shadow-box {
  background: #ffffff;
  box-shadow: 1px 5px 20px rgba(179, 187, 194, 0.4);
  border-radius: 10px;
}

#new_version .mrl20 {
  margin-left: 20px;
}

#new_version .ht275 {
  height: 310px;
}

#new_version .ht275 p.hidingContent {
  max-height: 96px;
  overflow: hidden;
}

#new_version .typeBlocks {
  width: calc(33.33% - 30px);
  height: 60px;
  margin: 12px 15px;
  line-height: 3.75rem;
  color: #555;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  float: left;
  text-align: center;
}

#new_version .typeBlocks img {
  max-width: 20px;
  max-height: 20px;
  display: inline;
  margin-right: 5px;
  margin-top: -3px;
}

#new_version .overview {
  overflow: hidden;
  background: url(../images/report-bg.svg) no-repeat;
  width: 100%;
  height: 880px;
}

#new_version #advQuestion .col-lg-5 {
  max-height: 700px;
  max-height: 100%;
}

#new_version .overview .container {
  max-width: 100%;
}

#new_version .reports {
  margin: 94px 0;
}

#new_version .reports img {
  max-width: 100%;
}

#new_version .btn-free {
  position: relative;
  overflow: hidden;
  background: #fff;
  z-index: 1;
  text-align: center;
  color: #1d70e1;
  text-transform: capitalize;
  font-size: 1.125rem !important;
  font-weight: 700;
  padding: 14px 34px;
  border-radius: 30px;
  border: 1px solid transparent;
  transition: 0.3s;
}

#new_version .btn-free:hover {
  transform: translateY(-2px);
  color: #1d70e1;
}

#new_version .btn-plan,
#new_version .selected {
  border: 2px solid #f85d18;
  background-color: #fff;
  color: #f85d18;
  font-size: 1.125rem !important;
  font-weight: 700;
  padding: 12px 35px;
  border-radius: 30px;
  margin: auto;
  width: 100%;
}

#new_version .btn-plan:hover {
  transform: translateY(-2px);
  color: #fff;
  border: 2px solid #1565d8;
  background: #1565d8;
}

#new_version .btn-plan-blue {
  border: 2px solid #1565d8;
  background: #1565d8;
  color: #fff;
  font-size: 1.125rem !important;
  font-weight: 700;
  padding: 12px 35px;
  border-radius: 30px;
  margin: auto;
  width: 100%;
}

#new_version .btn-plan-blue:hover {
  transform: translateY(-2px);
  color: #1565d8;
  border: 2px solid #1565d8;
  background: #fff;
}

#new_version #pricing-plan .col-lg-3.col-md-3 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

#new_version #pricing-plan .col-lg-3 .shadow-box #featuresList.hide-plan,
#new_version #pricing-plan .col-md-3 .shadow-box #featuresList.hide-plan {
  height: 600px;
  overflow: hidden;
}

#new_version
  #signContainer
  #pricing-plan
  .col-lg-3
  .shadow-box
  #featuresList.hide-plan,
#new_version
  #signContainer
  #pricing-plan
  .col-md-3
  .shadow-box
  #featuresList.hide-plan {
  height: 610px;
}

#new_version #pricing-plan .pricing-label {
  font-weight: 700 !important;
  font-size: 1.375rem !important;
  line-height: 1.75rem !important;
  color: #3e576b;
}

#new_version #pricing-plan h3.price {
  line-height: 120% !important;
  font-weight: 700 !important;
  font-size: 1.688rem !important;
  color: #1a1a1a;
}

#new_version #pricing-plan h3 span {
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  line-height: 0.875rem !important;
  vertical-align: baseline;
  color: #1a1a1a;
}

#new_version #pricing-plan .desc {
  font-weight: 400;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  color: #667085;
}

#new_version #pricing-plan p {
  line-height: 1.625rem;
}

#new_version #pricing-plan img {
  display: inline-block;
  margin-right: 8px;
  max-width: 26px;
  max-height: 26px;
}

#new_version #pricing-plan .moreLink {
  font-weight: 400;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  color: #f85d18;
}

#new_version #pricing-plan .moreLink:hover {
  color: #f85d18;
}

#new_version .list-icon {
  width: 34px;
  display: inline-block;
  margin-bottom: 10px;
}

#new_version .packageDetail .list-icon {
  width: 34px;
  display: inline-block;
  margin-bottom: 0px;
}

#new_version .list-options {
  width: calc(100% - 40px);
  display: inline-flex;
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: 400;
}

#new_version .list-options-check {
  width: calc(100% - 40px);
  display: inline-flex;
  margin-bottom: 10px;
  font-size: 1.125rem;
  font-weight: 400;
}

#new_version .packageDetail .list-options {
  width: calc(100% - 40px);
  display: inline-flex;
  margin-bottom: 0px;
  font-size: 1rem;
  font-weight: 400;
}

#new_version #faq .accordion-item,
#new_version #faq .accordion-item:hover {
  margin: 0 0 20px;
  border: 0;
  border-radius: 10px;
  /* background-color: #1565D8; */
}

#new_version #faq .accordion-header,
#new_version #faq .accordion-header button {
  border-radius: 10px;
  border: 0;
  background-color: #fff;
  position: relative;
  font-weight: 600;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  letter-spacing: 1px;
  font-family: "Lato", sans-serif;
}

#new_version #faq .accordion-button.collapsed {
  box-shadow: 1px 5px 20px rgb(179 187 194 / 40%) !important;
}

#new_version #faq .accordion-button:not(.collapsed) {
  background-color: #1565d8;
  color: #fff;
  -webkit-box-shadow: 1px 5px 20px rgb(32 94 183 / 50%);
  box-shadow: 1px 5px 20px rgb(32 94 183 / 50%);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#new_version #faq #accordion .accordion {
  margin: 0 0 20px;
  border: 0;
  border-radius: 10px;
}

#new_version #faq #accordion .accordion-header.active a,
#new_version #faq #accordion .accordion-header:hover a,
#new_version #faq #accordion .accordion-header:hover:after {
  color: #fff;
}

#new_version #faq #accordion .accordion,
#new_version #faq #accordion .accordion-header.active

/* ,#new_version #faq #accordion .accordion-header:hover  */
 {
  background-color: #1565d8;
}

#new_version #faq #accordion .accordion-item.active,
#new_version #faq #accordion .accordion-item.active .accordion-header,
#new_version #faq #accordion .accordion-item.active .accordion-header button {
  background-color: #1565d8;
  color: #fff;
}

#new_version #faq #accordion .accordion-header a {
  font-weight: 600;
  font-size: 1.125rem !important;
  display: block;
}

#new_version #faq #accordion .collapse.show,
#new_version #faq #accordion .collapse,
#new_version #faq #accordion .accordion-body {
  background-color: #1565d8;
  color: #fff;
  border-radius: 0 0 10px 10px;
  -webkit-transition: 0.01s;
  transition: 0.01s;
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  letter-spacing: 1px;
}

#new_version #contact {
  padding: 90px 0 65px;
  background-image: url(../images/map.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #fafafa;
}

#new_version #signContainer #pricing-plan.hero-header {
  display: flow-root;
}

#new_version .wd400 {
  width: 400px;
  margin: auto;
  max-width: 100%;
}

#new_version .contact-info {
  padding: 0 0 0 15%;
  width: 300px;
  margin: auto;
}

#new_version .contact-info-single {
  margin: 0 0 30px;
  overflow: hidden;
}

#new_version .contact-info-single .icon {
  width: 55px;
  float: left;
  margin-right: 10px;
  text-align: center;
  margin-left: 30px;
}

#new_version .contact-info-single .content {
  width: auto;
  float: left;
}

#new_version .contact-info-single em {
  font-size: 1.875rem !important;
  color: #1565d8;
  border: 1.2px solid #1565d8;
  border-radius: 4px;
  width: 55px;
  height: 55px;
  display: inline-block;
  line-height: 3.438rem !important;
}

#new_version .contact-info-single p {
  font-size: 0.938rem !important;
  color: #555;
}

#new_version .contact-form label {
  height: 50px !important;
  line-height: 3.125rem !important;
  font-size: 0.875rem !important;
  padding: 0 0.75rem !important;
}

#new_version .contact-form input {
  height: 50px !important;
  line-height: 3.125rem !important;
  border: 1px solid #e6f0fa;
  font-size: 0.875rem !important;
  padding: 0 0.75rem !important;
}

#new_version .contact-form textarea {
  border: 1px solid #e6f0fa;
  padding-top: 12px;
  font-size: 0.875rem !important;
}

#new_version .password-text {
  position: relative;
}
#new_version .password-text input {
  border: 1px solid #c4c4c4;
  font-size: 0.875rem !important;
  background: #fff !important;
  width: 100%;
  padding: 10px 10px;
  border-radius: 4px;
}
#new_version .password-text button.eye-icon {
  background: none;
  position: absolute;
  right: 10px;
  top: 14px;
  cursor: pointer;
  width: 30px;
}
#new_version .password-text button.eye-icon img {
  width: 30px;
  height: 15px;
  max-height: none;
}

#new_version .contact-form button {
  background-color: #1565d8;
  border: 0;
  color: #fff;
  outline: 0;
  cursor: pointer;
  width: 140px !important;
  font-size: 1.125rem !important;
  font-weight: 700;
  padding: 0 0;
  border-radius: 30px;
  border: 1px solid transparent;
  transition: 0.3s;
  line-height: 16px;
}

#new_version .contact-form button:hover {
  background-color: #0857d8;
}

#new_version .bg-black {
  background-color: #000;
  height: 260px;
}

#new_version #footer h4 {
  color: #fff;
  font-weight: 700;
  margin: 0 0 15px;
  font-size: 1.25rem !important;
  line-height: 1.875rem !important;
}

#new_version .footer-social-icons ul li {
  display: inline-block;
}

#new_version .footer-social-icons ul li a {
  display: block;
  background-color: #1565d8;
  color: #fff;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  font-size: 1rem !important;
  line-height: 2.813rem !important;
  margin: 0 2px;
}

#new_version .footer-social-icons ul li a:hover {
  background-color: #fff;
  color: #1565d8;
}

#new_version #footer p {
  color: #fff;
  padding: 30px 0 30px;
  text-align: center;
}

#new_version #footer p a {
  color: #1565d8;
}

#new_version #footer p a:hover {
  color: #0857d8;
}

#new_version #signContainer .boxShadow {
  /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px; */
}

#new_version #signContainer .boxShadow .formContainer {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

#new_version #signContainer .wd500.boxShadow .formContainer,
#new_version #signContainer .wd500.boxShadow .thanks {
  border-radius: 10px;
}

#new_version .formContainer label,
.forgot-link {
  font-weight: 500;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: #696f79;
  margin-bottom: 8px;
  padding-left: 0;
  font-family: Lato, sans-serif !important;
}

#new_version #signContainer .boxShadow .formContainer input[type="text"],
#new_version #signContainer .boxShadow .formContainer input[type="email"],
#new_version #signContainer .boxShadow .formContainer input[type="password"] {
  height: 50px;
  line-height: 3.125rem;
  padding-right: 50px;
  padding-left: 0;
  position: relative;
}

#new_version #signContainer .boxShadow .formContainer.otp input[type="text"],
#new_version #signContainer .boxShadow .formContainer.otp input[type="number"] {
  height: 60px;
  padding-right: inherit;
  font-weight: 700;
  font-size: 2rem !important;
  line-height: 2.375rem !important;
  text-align: center;
  color: #a3a3a3;
  background: none;
}

#new_version #signContainer #login .boxShadow .formContainer em {
  position: relative;
  margin-top: -38px;
  float: right;
  margin-right: 10px;
  font-size: 1.625rem !important;
  color: #999999;
}

#new_version #signContainer .boxShadow .formContainer img {
  position: relative;
  margin-top: -38px;
  float: right;
  margin-right: 10px;
  width: 22px;
}

#new_version
  #signContainer
  .boxShadow
  .formContainer
  .remember
  input[type="checkbox"] {
  width: 15px;
  height: 15px;
  position: absolute;
  margin-top: 4px;
}

#new_version .remember label {
  display: inline-block;
  margin-left: 20px;
}

#new_version .btn-white {
  border: 2px solid #1565d8;
  background-color: #fff;
  color: #1565d8;
  border-radius: 6px;
}

#new_version .bordered-btn {
  border: 2px solid #1565d8 !important;
  background-color: #fff !important;
  color: #1565d8 !important;
  border-radius: 6px;
  text-transform: none !important;
}

#new_version .googleBtn {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

#new_version .googleBtn img {
  margin: 0 !important;
  top: 0;
}

#new_version .btn-white:hover {
  transition: 0.2s;
  box-shadow: 0 10px 20px 3px rgb(35 129 207 / 20%);
  background-color: #1565d8;
  color: #fff;
}

#new_version .btn-google {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  color: #000;
}

#new_version .btn-google:hover {
  transition: 0.2s;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  color: #000;
}

#new_version .btn-google img {
  display: inline;
  position: initial !important;
  float: left !important;
  width: auto !important;
  margin: auto !important;
}

#new_version .orange {
  text-align: right;
  float: right;
  color: #d87e15 !important;
  font-weight: 500;
  font-size: 1rem !important;
}

#new_version #signContainer #pricing-plan.hero-header {
  background: url(../images/bg-top-hero.svg);
  width: 100%;
  height: auto;
  min-height: 860px;
  background-repeat: no-repeat;
}

#new_version #signContainer #pricing-plan .col-lg-3 .shadow-box,
#new_version #signContainer #pricing-plan .col-md-3 .shadow-box {
  height: auto;
}

#new_version #signContainer #pricing-plan .desc {
  font-size: 1rem !important;
  line-height: 1.75rem !important;
  color: #667085;
}

#new_version #signContainer #pricing-plan .wd1200 .col-12 {
  border-radius: 10px;
}

#new_version .mt-150 {
  margin-top: 150px;
}

#new_version .latestUpdates {
  background: url(../images/update-bg.png) no-repeat;
}

#new_version #lUpdates {
  height: 590px;
  overflow-y: auto;
  overflow-x: hidden;
}

#new_version #lUpdates::-webkit-scrollbar {
  width: 5px;
}

/* Track */
#new_version #lUpdates::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
#new_version #lUpdates::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}

#new_version .news {
  background: linear-gradient(
    30deg,
    rgba(255, 255, 255, 0.34) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  border-radius: 10px;
}

#new_version .news .date {
  font-size: 1rem !important;
  line-height: 1.25rem !important;
  font-weight: 500;
}

#new_version .news .heading {
  font-size: 1.25rem !important;
  line-height: 1.5rem !important;
  font-weight: 700;
}

#new_version .news .image img {
  width: 100%;
  max-height: 260px;
}

#new_version .news .content {
  font-size: 1rem !important;
  line-height: 1.25rem !important;
  font-weight: 500;
}

#new_version .news .link a {
  font-size: 1rem !important;
  line-height: 1.25rem !important;
  font-weight: 700;
  color: #fff;
}

#new_version .ms-n10 {
  margin-left: -12px;
}

#new_version .pdlr12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

#new_version #changelog .arrowBg {
  background: url(../images/log-bg.svg) no-repeat;
  background-position-x: right;
}

#new_version #changelog .changelogDetails {
  border: 1px solid #145dc4;
  border-radius: 16px;
  width: 100%;
  float: left;
}

#new_version #changelog .changelogDetails .heading {
  background: #145dc4;
  border-radius: 16px 16px 0px 0px;
  font-weight: 700;
  font-size: 1.125rem !important;
  line-height: 2.5rem !important;
  width: 100%;
}

#new_version #changelog .changelogDetails .changelogList {
  width: 100%;
  margin: auto;
  float: left;
}

#new_version #changelog .changelogDetails .changelogList .listData {
  font-weight: 500;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  width: 100%;
  float: left;
}

#new_version #changelog .changelogDetails .changelogList .listData .info {
  width: calc(100% - 15px);
  float: left;
  line-height: 1.375rem !important;
  padding: 3px 0;
  margin-left: 15px;
  display: list-item;
}

#new_version .new {
  background: rgba(41, 216, 48, 0.1);
  color: #29d830;
}

#new_version .fix {
  background: rgba(20, 105, 178, 0.1);
  color: #f85d18;
}

#new_version .note {
  background: rgba(222, 163, 48, 0.1);
  color: #dea330;
}

#new_version.bg-transparent {
  background: transparent;
}

#new_version .admin-content-box {
  padding: 20px;
  padding: 13px;
}

#new_version #admin-container h2 {
  font-weight: 700;
  font-size: 2.5rem !important;
  line-height: 120% !important;
  letter-spacing: -0.02em;
  color: #101828;
  text-transform: capitalize;
}

#new_version #admin-container .col-md-12.mt-2 {
  padding: 0 !important;
}

#new_version #settings-content h2 .perMonth {
  font-weight: 700;
  font-size: 1rem !important;
  line-height: 120% !important;
  letter-spacing: -0.02em;
  color: #475467;
}

#new_version .admin-content-box h3 {
  color: #555;
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;

  color: #101828;
  margin-top: 8px;
}

#new_version #settings-content #billingTable h3 {
  font-weight: 700;
  font-size: 1.5rem !important;
  line-height: 1.813rem !important;
  color: #101828;
  margin-bottom: 12px;
}

#new_version #settings-content p.small {
  font-weight: 400;
  font-size: 0.875rem !important;
  line-height: 1.063rem !important;
  color: #667085;
}

#new_version #settings-content #pills-tabContent {
  border-top: 1px solid #cacaca;
}

#new_version #settings-content ul.nav.nav-pills .nav-link {
  border-radius: 0px;
  font-weight: 700;
  font-size: 1rem !important;
  line-height: 1.188rem !important;
  padding: 10px;
  color: #667085;
  margin-right: 20px;
}

#new_version #settings-content ul.nav.nav-pills .nav-link.active,
#new_version #settings-content ul.nav.nav-pills .show > .nav-link {
  background-color: transparent;
  font-weight: 700;
  font-size: 1rem !important;
  line-height: 1.188rem !important;
  border-bottom: 2px solid #f85d18;
  color: #f85d18;
}

#new_version #settings-content .billingContainer {
  padding: 26px 18px 26px 18px;
}

#new_version #settings-content .profileContainer {
  padding: 26px 18px 26px 7px;
}

#new_version .profileForm {
  width: calc(100% - 120px);
  float: left;
}

#new_version .profileForm input {
  width: 100%;
  background: rgba(31, 18, 30, 0.05);
  border: 1px solid rgba(31, 18, 30, 0.05);
  border-radius: 4px;
  height: 40px;
  padding: 0 12px;
}

#new_version .profileForm .error {
  color: #e20000;
}

#new_version .profileContainer em.fa-exclamation-circle {
  color: #ffa500;
}

#new_version .profileForm {
  font-family: "Roboto" sans-serif;
}

#new_version .profileForm .eyeIcon {
  width: auto;
  position: relative;
  float: right;
  margin-top: -44px;
}

#new_version .profileForm p {
  font-weight: 400;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: #525252;
}

#new_version .submitUpdateButton {
  height: 40px;
  /* width: 120px; */
  width: auto;
}

#new_version .submitUpdateButton button {
  height: 40px;
  background: #f85d18;
  border-radius: 3px;
  width: 120px;
  text-align: center;
  font-weight: 500;
  font-size: 1rem !important;
  line-height: 1.188rem !important;
  color: #ffffff;
}

#new_version #settings-content .billingContainer .planDetails {
  margin: 0px auto;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06),
    0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 5px;
  width: calc(50% - 12.5px);
  float: left;
  margin-right: 10px;
  padding: 32px;
}

#new_version #settings-content .billingContainer .paymentDetails {
  margin: 0px auto;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06),
    0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 5px;
  width: calc(50% - 12.5px);
  float: left;
  margin-left: 12.5px;
  padding: 32px;
}

#new_version #settings-content p.pName {
  font-weight: 600;
  font-size: 1.25rem !important;
  line-height: 1.5rem !important;
  color: #101828;
  margin-bottom: 12px;
}

#new_version #settings-content .timeline {
  padding: 8px 16px;
  background: rgba(248, 93, 24, 0.06) #f85d18;
  border-radius: 27px;
  font-weight: 500;
  font-size: 1rem !important;
  color: #f85d18;
  margin-left: 22px;
}

#new_version #settings-content p.response {
  font-weight: 700;
  font-size: 1.125rem !important;
  line-height: 1.375rem !important;
  color: #667085;
}

.clear {
  clear: both;
}

#new_version #settings-content .billingContainer a.details {
  font-weight: 600;
  font-size: 1rem !important;
  color: #f85d18;
}

#new_version #settings-content .billingContainer a {
  font-weight: 700;
  font-size: 1.125rem !important;
  color: #f85d18;
  justify-content: end;
}

#new_version #settings-content .billingContainer a em {
  font-size: 1.5rem !important;
  color: #f85d18;
}

.lh-34 {
  line-height: 1.625rem !important;
  margin-top: 7px !important;
}

#new_version #settings-content .billingContainer .addCard {
  width: 100%;
  height: 125px;
  color: #000;
  background: #fff5f1;
  border: 1px solid #fff5f1;
  border-radius: 5px;
  opacity: 1;
}

#new_version #settings-content .billingContainer .addCard .cardIcon {
  width: 115px;
  margin: auto;
  display: inline-block;
  padding: 49px 0;
  line-height: 1.625rem;
}

.cardIconcenter {
  justify-content: center;
  align-items: center;
  display: flex;
  color: #f85d18;
  height: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 18px;
}

#new_version #settings-content .billingContainer .addCard .cardDetails {
  width: 100%;
  float: left;
  padding: 22px 6px 0;
}

#new_version
  #settings-content
  .billingContainer
  .addCard
  .cardDetails
  .cardImage {
  width: 70px;
  float: left;
  margin-right: 20px;
}

#new_version
  #settings-content
  .billingContainer
  .addCard
  .cardDetails
  .cardImage
  img {
  max-width: 70px;
  height: 44px;
}

#new_version
  #settings-content
  .billingContainer
  .addCard
  .cardDetails
  .cardInfo {
  width: calc(100% - 194px);
  float: left;
  margin-right: 20px;
}

#new_version
  #settings-content
  .billingContainer
  .addCard
  .cardDetails
  .cardInfo
  p.cardNo {
  font-weight: 500;
  font-size: 1rem !important;
  line-height: 1.188rem !important;
  color: #344054;
  margin-bottom: 12px;
}

#new_version
  #settings-content
  .billingContainer
  .addCard
  .cardDetails
  .cardInfo
  p.cardExpiry {
  font-weight: 400;
  font-size: 1rem !important;
  line-height: 1.188rem !important;
  color: #475467;
}

#new_version
  #settings-content
  .billingContainer
  .addCard
  .cardDetails
  .cardInfo
  p.email {
  font-weight: 400;
  font-size: 1rem !important;
  line-height: 1.188rem !important;
  color: #475467;
  margin-top: 17px;
}

#new_version
  #settings-content
  .billingContainer
  .addCard
  .cardDetails
  .editCard {
  width: 72px;
  float: left;
  margin: 0;
  padding: 0;
}

#new_version
  #settings-content
  .billingContainer
  .addCard
  .cardDetails
  .editCard
  button {
  width: 72px;
  font-weight: 500;
  font-size: 0.875rem !important;
  line-height: 1rem !important;
  background: #fff;
  color: #666666;
  height: 40px;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
}

#new_version #settings-content .billingContainer #billingChart {
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06),
    0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 5px;
}

#new_version .admin-content-box .col-md1 {
  width: 250px;
}

#new_version .admin-content-box .col-md2 {
  width: calc(100% - 270px);
  margin-left: 20px;
  padding-right: 0;
}

#new_version .admin-content-box button.download-csv {
  height: 40px;
  font-weight: 500;
  font-size: 0.875rem !important;
  line-height: 2.5rem !important;
  background: var(--Secondary, #f85d18);
  border-radius: 3px;
  margin-top: 30px;
  padding: 0 16px;
  /* width: 173px; */
}
.rdrStaticRangeLabel {
  color: #000;
}

.rdrDayToday .rdrDayNumber span:after {
  background: #f85d18 !important;
}

#new_version .contact-btn {
  height: 40px;
  font-weight: 500;
  font-size: 1rem !important;
  line-height: 19px !important;
  background: #f85d18;
  border-radius: 3px;
  width: 118px;
}

#new_version button.eye-icon {
  position: absolute;
  right: 0;
  bottom: 14px;
  border: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
}

#new_version input[type="password"] + button.eye-icon .viIcon {
  display: none;
}

#new_version input[type="text"] + button.eye-icon .inIcon {
  display: none;
}

#new_version #signContainer .boxShadow .formContainer .eye-icon img {
  margin: 0;
}

.forgot-link {
  background: none;
  text-align: right;
}

.or-continue {
  text-align: center;
  color: #b5b5b5;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

#new_version .googleBtn {
  text-align: center;
  margin: 0 auto;
  width: 80px;
  height: auto;
  background: none;
}

#new_version #signContainer .boxShadow .formContainer .googleBtn img {
  display: inline-block;
  width: 50px;
  height: auto;
}

.marpadding32 {
  margin: 0 !important;
  padding: 32px 20px !important;
}

#new_version #settings-content .billingContainer .terminate {
  background: #0e4675;
  opacity: 0.84;
  border-radius: 5px 5px 0px 0px;
  position: relative;
  margin-top: 0px !important;
  float: left;
  margin-left: -49.8% !important;
  height: 190px !important;
  align-items: center;
  width: calc(50% - 12.5px);
}

#new_version #settings-content .billingContainer .terminate h3 {
  font-weight: 600;
  font-size: 1.375rem !important;
  line-height: 1.625rem !important;
  color: #ffffff;
  margin-bottom: 26px;
}

#new_version #settings-content .billingContainer .terminate p {
  font-weight: 400;
  font-size: 1.125rem !important;
  line-height: 1.313rem !important;
  color: #ffffff;
}

#new_version .rounded-18 {
  border-radius: 5px;
}

#new_version .rounded-bottom10 {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

#new_version .planListDetail {
  width: calc(25% - 16px);
  margin: 5px;
  display: flex;
  padding: 0;
}

#new_version .planListDetail .list-icon {
  width: 28px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 0 !important;
}

#new_version .planListDetail .list-icon img {
  height: auto;
  width: 28px;
}

#new_version .planListDetail .list-options {
  width: calc(100% - 33px);
  display: inline-flex;
  margin-bottom: 0 !important;
}

p.planHeading,
#new_version p.planHeading {
  font-weight: 700;
  font-size: 1.375rem !important;
  line-height: 1.625rem !important;
  color: #3e576b;
  margin: 0 0 16px;
}

p.planPrice,
#new_version p.planPrice {
  font-weight: 700;
  font-size: 1.688rem !important;
  line-height: 120% !important;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  margin: 0 0 16px;
}

p.planInfo,
#new_version p.planInfo {
  font-weight: 400;
  font-size: 1.125rem !important;
  line-height: 140% !important;
  color: #667085;
  margin: 0 0 0 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #d9d9d9;
}

#new_version .cred-container input {
  font-size: 0.938rem !important;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1.5px solid #b4b3b3;
}

#new_version .btn-plan:disabled {
  pointer-events: none;
  opacity: 1;
}

#new_version p.error {
  margin: 8px 5px;
  color: #d81515;
  font-size: 1rem;
}

#new_version #signContainer #login .boxShadow .formContainer p.error em,
#new_version p.error em {
  margin-top: 3px;
  float: left;
  font-size: 1rem !important;
  color: #d81515;
  margin-right: 5px;
}

#new_version .admin-content-box #searchFilter .exportImg {
  width: 20px;
  display: inline-block;
  margin-right: 5px;
  margin-top: -2px;
}

#new_version .admin-content-box #searchFilter input[type="text"] {
  height: 40px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 40px;
  font-family: "Roboto", sans-serif;
  color: rgba(0, 0, 0, 0.3654);
  width: 200px;
  padding: 0 15px 0 40px;
}

#new_version .admin-content-box #searchFilter em.fas.fa-search {
  position: relative;
  color: rgba(0, 0, 0, 0.54);
  font-size: 18px;
  left: 30px;
  top: 2px;
}
.RegularButton-rose-97,
.btnBlck,
.btnBlck li.MuiListItem-button:hover {
  background: #000 !important;
}
.yellowBtn {
  background: #ffbe5c !important;
}
.CustomDropdown-primaryHover-229 {
  margin: 0 !important;
}
.CustomDropdown-primaryHover-229:hover {
  margin: 0;
  background: #000 !important;
}
#new_version .admin-content-box #searchFilter .filterDropdown {
  /* position: relative; */

  font-family: "Roboto", sans-serif;
  display: inline-block;
  width: 100px;
  /* background: #f85d18; */
  background: var(--Secondary, #f85d18);
  border-radius: 3px;
  height: 40px;
  line-height: 40px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
  text-align: center;
}

#new_version .admin-content-box #searchFilter .filterDropdownShow {
  font-family: "Roboto", sans-serif;
  display: block;
  position: absolute;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 8px 16px rgba(16, 24, 40, 0.06),
    0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 10px;
  z-index: 1;
  margin-left: -76px;
  width: 175px;
  font-weight: 500;
  font-size: 16px;
  line-height: 43px;
  color: #4c5773;
  text-align: left;
  margin-top: 6px;
  overflow: hidden;
}

#new_version .admin-content-box #searchFilter .filterDropdownShow .filterTitle {
  padding: 0 15px;
}

#new_version .admin-content-box #searchFilter .filterDropdownHide,
#new_version .admin-content-box #searchFilter .filtersubDropdownHide,
#new_version
  .admin-content-box
  #searchFilter
  .filtersubDropdownShow
  a
  img.icon {
  display: none;
}

#new_version .admin-content-box #searchFilter .filtersubDropdownShow {
  font-family: "Roboto", sans-serif;
  display: block;
  position: absolute;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 8px 16px rgba(16, 24, 40, 0.06),
    0px 1px 3px rgba(16, 24, 40, 0.1);
  border-radius: 10px;
  z-index: 1;
  margin-left: -357px;
  width: 280px;
  font-weight: 500;
  font-size: 16px;
  line-height: 43px;
  color: #4c5773;
  text-align: left;
  margin-top: 6px;
  overflow: hidden;
}

#new_version
  .admin-content-box
  #searchFilter
  .filtersubDropdownShow.selectedDateRange {
  font-family: "Roboto", sans-serif;
  display: block;
  position: absolute;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 8px 16px rgb(16 24 40 / 6%), 0px 1px 3px rgb(16 24 40 / 10%);
  border-radius: 10px;
  z-index: 1;
  margin-left: -357px;
  width: 280px;
  font-weight: 500;
  font-size: 16px;
  line-height: 43px;
  color: #4c5773;
  text-align: right;
  float: right;
  margin-top: 6px;
  overflow: hidden;
  right: 32px;
}

#new_version .admin-content-box #searchFilter .filterDropdownShow a,
#new_version .admin-content-box #searchFilter .filtersubDropdownShow a {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #4c5773;
  line-height: 40px !important;
  text-decoration: none;
  display: block;
  cursor: pointer;
  text-align: left;
  padding: 0 15px;
}

#new_version .admin-content-box #searchFilter .filterDropdown a:hover,
#new_version
  .admin-content-box
  #searchFilter
  .filterDropdownShow
  a.selectedOption,
#new_version
  .admin-content-box
  #searchFilter
  .filtersubDropdownShow
  a.selectedOption {
  background: rgba(248, 93, 24, 0.06);
}

#new_version .admin-content-box #searchFilter .filterDropdownShow a img.icon {
  width: 18px;
  display: inline-block;
  margin-right: 10px;
  margin-top: -2px;
  max-height: 19px;
}

#new_version
  .admin-content-box
  #searchFilter
  .filtersubDropdownShow
  a.selectedOption
  img.icon {
  display: inline-block;
  text-align: right;
  float: right;
  width: 10px;
  margin-top: 15px;
}

#new_version .admin-content-box #searchFilter .addButton,
.addButton.training {
  /* width: 40px;
    height: 40px;
    background: #000000;
    border-radius: 23px; */
  display: inline;
  height: 40px;
  font-weight: 500;
  font-size: 0.875rem !important;
  line-height: 2.5rem !important;
  background: #000000;
  border-radius: 3px;
  /* margin-top: 30px; */
  padding: 0 16px;
  color: #fff;
}

#new_version
  .admin-content-box
  #searchFilter
  .filtersubDropdownShow
  .rdrDateRangePickerWrapper
  .rdrDateInput
  input {
  height: auto;
}

#new_version
  .admin-content-box
  #searchFilter
  .filtersubDropdownShow
  .rdrDateRangePickerWrapper
  .rdrInputRange
  span {
  margin-left: 10px;
}

#new_version
  .admin-content-box
  #searchFilter
  .filtersubDropdownShow
  .rdrDateRangePickerWrapper
  .rdrDateDisplay,
#new_version
  .admin-content-box
  #searchFilter
  .filtersubDropdownShow
  .rdrDateRangePickerWrapper
  .rdrStartEdge,
#new_version
  .admin-content-box
  #searchFilter
  .filtersubDropdownShow
  .rdrDateRangePickerWrapper
  .rdrInRange,
#new_version
  .admin-content-box
  #searchFilter
  .filtersubDropdownShow
  .rdrDateRangePickerWrapper
  .rdrEndEdge,
#new_version
  .admin-content-box
  #searchFilter
  .filtersubDropdownShow
  .rdrDateRangePickerWrapper
  .rdrDayStartPreview,
#new_version
  .admin-content-box
  #searchFilter
  .filtersubDropdownShow
  .rdrDateRangePickerWrapper
  .rdrDayInPreview,
#new_version
  .admin-content-box
  #searchFilter
  .filtersubDropdownShow
  .rdrDateRangePickerWrapper
  .rdrDayEndPreview {
  color: #f85d18 !important;
}

#new_version
  .admin-content-box
  #searchFilter
  .filtersubDropdownShow
  .rdrDateRangePickerWrapper
  .rdrDateDisplay
  .rdrDateDisplayItemActive {
  border-color: #f85d18;
}

#new_version .admin-content-box .wt-container-bxShadow {
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 2px rgb(16 24 40 / 6%), 0px 1px 3px rgb(16 24 40 / 10%);
  border-radius: 5px;
}

#new_version .admin-content-box .tableContainer {
  border: 1px solid #eaecf0;
  /* box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06), 0px 1px 3px rgba(16, 24, 40, 0.1);     */
  border-radius: 18px 18px 0px 0px;
  overflow-y: auto;
}

/* 
#new_version .admin-content-box .tableContainer {
    padding: 0 0 60px 0;
} */

#new_version
  #responses.admin-content-box
  .tableContainer
  table
  tr:last-child
  td {
  border-bottom: 1px solid rgb(234, 236, 240);
}

#new_version .fraudStatus .dropdown-toggle.greenColor {
  height: 29px;
  line-height: 2;
}

#new_version .admin-content-box .tableContainer table {
  width: 100%;
}

#new_version .admin-content-box .tableContainer table thead {
  background: #f9fafb;
  width: 100%;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06),
    0px 1px 3px rgba(16, 24, 40, 0.1);
}

#new_version .admin-content-box .tableContainer table tbody {
  border-top: 1px solid #eaecf0;
  width: 100%;
}

#new_version .admin-content-box .tableContainer table tr th {
  font-weight: 600;
  font-size: 15px;
  line-height: 17px;
  padding: 20px 10px;
  color: #475467;
  border: 0;
}

#new_version .admin-content-box .tableContainer table tr td {
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  color: #475467;
  padding: 28px 10px;
  border-top: 1px solid #eaecf0;
  vertical-align: middle;
}

#new_version .admin-content-box .tableContainer table tr:nth-of-type(1) td {
  border-top: 0;
}

#new_version .admin-content-box .tableContainer table tr th:nth-of-type(1),
#new_version .admin-content-box .tableContainer table tr td:nth-of-type(1) {
  padding-left: 30px;
}

#new_version .admin-content-box .tableContainer table tr td a {
  cursor: pointer;
}

#new_version .admin-content-box .tableContainer table tr td a img.icon {
  max-width: 21px;
  max-height: 22px;
  text-align: left;
  display: inline;
}

#new_version .admin-content-box .tableContainer table tr td a em {
  font-weight: 400;
  font-size: 22px;
  line-height: 16px;
  color: #f85d18;
}

#new_version .admin-content-box .tableContainer table tr td .genuine {
  color: #2cb442;
  background: rgba(24, 214, 32, 0.11);
  border-radius: 16px;
  padding: 8px 16px;
  line-height: 30px;
  width: 150px;
  text-align: center;
}

#new_version .admin-content-box .tableContainer table tr td .confirmed {
  color: #c03d14;
  background: rgba(192, 61, 20, 0.11);
  border-radius: 16px;
  padding: 8px 16px;
  line-height: 30px;
  width: 150px;
  text-align: center;
  white-space: nowrap;
}

#new_version .admin-content-box .tableContainer table tr td .suspected {
  color: #cfa025;
  background: rgba(207, 160, 37, 0.11);
  border-radius: 16px;
  padding: 8px 16px;
  line-height: 30px;
  width: 150px;
  text-align: center;
}

#new_version .admin-content-box ul.paginate {
  list-style-type: none;
  text-align: center;
}

#new_version .admin-content-box ul.paginate li {
  display: inline-flex;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  margin: 0 8px;
}

#new_version .admin-content-box ul.paginate li.previous,
#new_version .admin-content-box ul.paginate li.next {
  width: 120px;
  display: inline-flex;
  margin: 0;
}

#new_version .admin-content-box ul.paginate li a {
  width: 100%;
}

#new_version .admin-content-box ul.paginate li a:hover {
  background: rgba(248, 93, 24, 0.06);
  color: #f85d18;
  border-radius: 5px;
}

#new_version .admin-content-box ul.paginate li.previous {
  float: left;
}

#new_version .admin-content-box ul.paginate li.next {
  float: right;
}

#new_version .admin-content-box ul.paginate li.previous a,
#new_version .admin-content-box ul.paginate li.next a {
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  width: 100%;
  color: #fff;
  background: #f85d18;
  background: #000;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

#new_version .admin-content-box ul.paginate li.previous a:hover,
#new_version .admin-content-box ul.paginate li.next a:hover {
  color: #fff;
  background: #f85d18;
  background: #000;
}

#new_version .admin-content-box ul.paginate li.previous.disabled a,
#new_version .admin-content-box ul.paginate li.next.disabled a {
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  width: 100%;
  color: #666;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  cursor: auto;
}

#new_version .admin-content-box ul.paginate li.previous.disabled a:hover,
#new_version .admin-content-box ul.paginate li.next.disabled:hover {
  color: #666;
  background: #ffffff;
}

#new_version .admin-content-box ul.paginate li.selected {
  background: rgb(255 245 241);
  color: #f85d18;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  line-height: 40px;
  margin: 0 10px;
  text-align: center;
  cursor: pointer;
  border: 0;
  padding: 0;
}

#new_version .admin-content-box .tableContainer table tr th:last-of-type,
#new_version .admin-content-box .tableContainer table tr td:last-of-type,
#new_version
  #userManagement.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(6),
#new_version
  #userManagement.admin-content-box
  .tableContainer
  table
  tr
  td:nth-of-type(6),
#new_version
  #userManagement.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(7),
#new_version
  #userManagement.admin-content-box
  .tableContainer
  table
  tr
  td:nth-of-type(7),
#new_version
  #userManagement.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(8),
#new_version
  #userManagement.admin-content-box
  .tableContainer
  table
  tr
  td:nth-of-type(8) {
  text-align: center;
}

#new_version #auditLogs.admin-content-box .tableContainer table,
#new_version #userManagement.admin-content-box .tableContainer table {
  min-width: 1500px;
}

/* #new_version #responses.admin-content-box .tableContainer table {
    min-width: 1300px;
} */

#new_version
  #auditLogs.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(1),
#new_version
  #auditLogs.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(6),
#new_version
  #auditLogs.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(8),
#new_version
  #auditLogs.admin-content-box
  .tableContainer
  table
  tr
  th:last-of-type,
#new_version
  #userManagement.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(1),
#new_version
  #userManagement.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(8),
#new_version
  #userManagement.admin-content-box
  .tableContainer
  table
  tr
  th:last-of-type,
#new_version
  #responses.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(1),
#new_version
  #responses.admin-content-box
  .tableContainer
  table
  tr
  th:last-of-type {
  width: 90px;
}

#new_version
  #auditLogs.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(2),
#new_version
  #auditLogs.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(3),
#new_version
  #userManagement.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(2),
#new_version
  #userManagement.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(6),
#new_version
  #userManagement.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(7),
#new_version
  #responses.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(2),
#new_version
  #responses.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(4),
#new_version
  #responses.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(5),
#new_version
  #responses.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(6) {
  width: 190px;
}

#new_version
  #auditLogs.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(4),
#new_version
  #auditLogs.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(5),
#new_version
  #userManagement.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(3),
#new_version
  #userManagement.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(4),
#new_version
  #userManagement.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(5) {
  width: 150px;
}

#new_version
  #auditLogs.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(7),
#new_version
  #userManagement.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(3),
#new_version
  #responses.admin-content-box
  .tableContainer
  table
  tr
  th:nth-of-type(3) {
  width: 280px;
}

#new_version
  #settings-content
  #billingTable
  .tableContainer
  table
  tr
  td[colspan="7"] {
  text-align: center;
}

#new_version
  #settings-content
  #billingTable
  .tableContainer
  table
  tr
  th:nth-of-type(1) {
  width: 5%;
}

#new_version
  #settings-content
  #billingTable
  .tableContainer
  table
  tr
  th:nth-of-type(2) {
  width: 30%;
}

#new_version
  #settings-content
  #billingTable
  .tableContainer
  table
  tr
  th:nth-of-type(3) {
  width: 20%;
}

#new_version
  #settings-content
  #billingTable
  .tableContainer
  table
  tr
  th:nth-of-type(4) {
  width: 10%;
}

#new_version
  #settings-content
  #billingTable
  .tableContainer
  table
  tr
  th:nth-of-type(5) {
  width: 10%;
}

#new_version
  #settings-content
  #billingTable
  .tableContainer
  table
  tr
  th:nth-of-type(6) {
  width: 13%;
}

#new_version
  #settings-content
  #billingTable
  .tableContainer
  table
  tr
  th:nth-of-type(7) {
  width: 12%;
}

#new_version .admin-content-box .tableContainer table tr td img.noResponse {
  max-height: 120px;
  margin: 20px auto;
}

#new_version #settings-content #billingChart img {
  width: 100%;
  height: auto;
}

#new_version #settings-content .billingContainer #billingChart .col-12 {
  margin-top: 2rem;
}

#new_version #settings-content .billingContainer #plan-payment-container {
  margin: 0 !important;
  padding: 0 !important;
}

.cursor-point {
  cursor: pointer;
}

.Toastify__close-button > svg {
  width: 20px !important;
  height: 20px !important;
  margin-top: 50%;
}

.Toastify__toast {
  color: #555;
  font-weight: 500;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3), 0px 12px 24px rgba(0, 0, 0, 0.2) !important;
  font-size: 16px;
}

.Toastify__toast--success {
  border-left: 4px solid #07bc0c;
}

.Toastify__toast--error {
  border-left: 4px solid #e74c3c;
}

#new_version .navbar-expand-lg .navbar-collapse {
  height: 70px;
}

#new_version #settings-content .settingsContainer p {
  margin-bottom: 0;
}

/* Toggle Switch */
#new_version .switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 14px;
}

#new_version .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

#new_version .switchSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 32px;
  background-color: rgba(112, 112, 112, 0.12);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

#new_version .switchSlider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: -4px;
  bottom: -2px;
  background-color: #707070;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
}

#new_version input:checked + .switchSlider {
  background-color: rgba(248, 93, 24, 0.06);
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked,
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.css-1a5icme.Mui-checked,
.css-1a5icme.Mui-focused,
.orngColor.Mui-checked,
.orngColor.Mui-focused,
.Mui-focused {
  color: #f85d18 !important;
}
.css-vqmohf-MuiButtonBase-root-MuiRadio-root:hover,
.orngColor.Mui-checked:hover,
.orngColor:hover {
  background: rgba(248, 93, 24, 0.02) !important;
}
.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input:focus {
  background: transparent !important;
}
.css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:after {
  border-bottom: 2px solid #f85d18 !important;
}

#new_version input:focus + .switchSlider {
  box-shadow: 0 0 1px #2196f3;
}

#new_version input:checked + .switchSlider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
  background: #f85d18;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
}

#new_version .switchSlider.round {
  border-radius: 7px;
}

#new_version .switchSlider.round:before {
  border-radius: 50%;
}

#new_version .admin-content-box .col-md-12 .MuiChip-root {
  background: #fff;
  color: #000;
  font-size: 14px;
  border: 1px solid #fff;
}

#new_version .admin-content-box .col-md-12 .MuiChip-root span.MuiChip-label {
  padding-left: 7px;
  padding-right: 7px;
}

#new_version .admin-content-box .col-md-12 .MuiChip-root:hover {
  background: #fff9ef;
  border: 1px solid #f85d18;
  color: #f85d18;
}

#new_version .admin-content-box .col-md-12 .MuiChip-root .MuiChip-deleteIcon {
  width: 16px;
  color: #000000;
  margin: 0 7px 0 0;
  height: 16px;
}
#new_version #admin-container.pb-5 {
  min-height: 100vh;
  background: #fbfbfb;
}

#new_version.bg-transparent #admin-container.pb-5 {
  background: none;
}

#new_version .settingsContainer .accordion {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
  border-bottom: 1px solid rgb(248 93 24);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

#new_version .settingsContainer .accordion-button::after {
  width: 1rem;
  max-height: 1rem;
  margin-left: auto;
  content: "";
  background: url(../images/down-arrow.svg) no-repeat center center / 18px;
  transition: transform 0.2s ease-in-out;
  transform: rotate(0) !important;
}

#new_version .settingsContainer .accordion-button.collapsed::after {
  -moz-transform: scaleX(-1) scaleY(-1);
  -o-transform: scaleX(-1) scaleY(-1);
  -webkit-transform: scaleX(-1) scaleY(-1);
  transform: scaleX(-1) scaleY(-1);
}

#new_version .settingsContainer .accordion-item {
  margin: 0;
  border-radius: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border: 0;
}

#new_version .settingsContainer .accordion-item .accordion-header {
  background-color: #fff5f1 !important;
  box-shadow: none;
  border-radius: 0;
  /* border: 1px solid rgba(0, 0, 0, 0.12); */
  border: 1px solid #f85d18;
  border-bottom: 0;
  padding: 0 0;
}

#new_version .settingsContainer .accordion-item .accordion-header button {
  background-color: #fff5f1 !important;
  font-weight: 400;
  color: #1a1a1a;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  padding: 0 16px;
  min-height: 48px;
  box-shadow: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  letter-spacing: 0.00938em;
}

#new_version .settingsContainer .accordion-button.collapsed {
  box-shadow: none !important;
}

#new_version .settingsContainer .accordion-item:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

#new_version .settingsContainer .accordion-item:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .expand-details {
  width: 100%;
  display: inline-block;
}

#new_version .settingsContainer #accordion .accordion-body {
  background: #fff5f1 !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  letter-spacing: 0;
  /* border: 1px solid rgba(0, 0, 0, 0.12); */
  border: 1px solid #f85d18;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-top: 0;
}

#new_version .settingsContainer #accordion .collapse.show {
  background: #fff !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-bottom: 10px;
  box-shadow: none;
}

#new_version
  .settingsContainer
  #accordion
  .accordion-item:last-of-type
  .collapse.show {
  padding-bottom: 0;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .expand-details
  .expand-col1 {
  width: calc(25% - 20px);
  background: #ffffff;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
  border: 1px solid #f85d18;
  padding: 20px;
  margin: 10px;
  float: left;
}

.lottieAnimation.w-3 {
  width: 130px;
  margin: auto;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .expand-details
  .expand-col1
  .config-heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #555555;
  margin-bottom: 20px;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .expand-details
  .expand-col1
  .config-heading
  span {
  text-align: right;
  float: right;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .expand-details
  .expand-col1
  .config-content
  p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #777777;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .expand-details
  .expand-col2 {
  width: 11.1%;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  margin: 10px;
  float: left;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .expand-details
  .expand-col2
  .config-heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #555555;
  padding: 20px 20px 0 20px;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .expand-details
  .expand-col2
  .config-content
  .pdlr20 {
  padding: 20px;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .expand-details
  .expand-col2
  .config-content
  .col50 {
  width: 50%;
  float: left;
  height: 30px;
}

#new_version .settingsContainer .accordion-item .accordion-body .appconfig {
  width: 100%;
  display: inline-block;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .appconfig
  .config-heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #555555;
  margin-bottom: 20px;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .appconfig
  .fileInput {
  width: calc(25% - 20px);
  background: #e6e6e6;
  border: 1px dashed #000000;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin: 10px;
  float: left;
  height: 300px;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .appconfig
  .fileInput
  [type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .appconfig
  .fileInput
  [type="file"]
  + label {
  background: #e6e6e6;
  color: #777;
  cursor: pointer;
  display: inline-block;
  outline: none;
  padding: 50px 30px;
  position: relative;
  vertical-align: middle;
  text-align: center;
  width: 100%;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .appconfig
  .fileInput
  [type="file"]
  + label
  img {
  width: 100%;
  max-height: 68px;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .appconfig
  .fileInput
  [type="file"]
  + label:hover {
  background-color: #e6e6e6;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .appconfig
  .fileInput
  p {
  font-weight: 400;
  font-size: 16px !important;
  line-height: 19px;
  color: #777777;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .appconfig
  .logo-setting {
  width: calc(50% - 20px);
  background: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin: 10px;
  float: left;
  height: 200px;
  max-width: 500px;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .appconfig
  .logo-setting
  .delete
  img {
  display: inline;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .appconfig
  .logo-setting
  .logoImage {
  max-height: 205px;
  overflow: hidden;
  text-align: center;
}

#new_version
  .settingsContainer
  .accordion-item
  .accordion-body
  .appconfig
  .logo-setting
  .logoImage
  img {
  width: 100%;
  max-height: 205px;
}

#new_version .settingsContainer button.expandCollapse {
  background-color: #f85d18;
  color: rgb(255, 255, 255);
  width: 200px;
  float: right;
  padding: 12px 30px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto";
  border-radius: 3px;
}

/* Toggle Switch */
#new_version .settingsContainer .switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 14px;
}

#new_version .settingsContainer .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

#new_version .settingsContainer .switchSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4) !important;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

#new_version .settingsContainer .switchSlider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: -4px;
  bottom: -2px;
  background-color: #fff;
  transition: 0.4s;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

#new_version .settingsContainer input:checked + .switchSlider {
  background-color: rgba(248, 93, 24, 0.06) !important;
}

#new_version .settingsContainer input:focus + .switchSlider {
  box-shadow: 0 0 1px #1554f6;
}

#new_version .settingsContainer input:checked + .switchSlider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
  background: #f85d18;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

#new_version .settingsContainer .switchSlider.round {
  border-radius: 7px;
}

#new_version .settingsContainer .switchSlider.round:before {
  border-radius: 50%;
}

#new_version .datepicker {
  width: 100%;
  text-align: right;
}

#new_version .datepicker button {
  height: 40px;
  font-weight: 500;
  font-size: 0.875rem !important;
  line-height: 2.5rem !important;
  background: #f85d18;
  border-radius: 3px;
  margin-top: 30px;
  padding: 0 16px;
  font-family: "Roboto";
  color: #fff;
  margin-right: 20px;
  margin-bottom: 20px;
}

#new_version .datepicker button:hover {
  background-color: #000;
}

#new_version .datepicker button.cancel {
  height: 40px;
  font-weight: 500;
  font-size: 0.875rem !important;
  line-height: 2.5rem !important;
  background: #fff;
  border-radius: 3px;
  margin-top: 30px;
  padding: 0 16px;
  font-family: "Roboto";
  color: rgba(0, 0, 0, 0.87);
  margin-right: 20px;
  margin-bottom: 20px;
}

#new_version .datepicker button.cancel:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

#new_version .fraudStatus li {
  font-size: 14px;
  font-weight: 500;
  font-family: Lato, sans-serif;
}

#new_version .modal.fade.half-slide-modal .modal-dialog {
  width: 40%;
}

.previewSec {
  width: 100%;
  float: left;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 15px;
}

.surveryTopBar {
  background: #fff;
  border-bottom: 1px solid #0000000f;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  left: 0;
  margin: auto;
  padding: 15px;
  position: relative;
  right: 0;
  top: 0;
  z-index: 5;
}

.surveyLogo {
  align-items: center;
  display: inline-block;
  height: 57px;
  justify-content: center;
  width: 150px;
  display: flex;
  align-items: center;
}

#new_version .btn.btn-wht.px-2 {
  padding: 10px 10px !important;
}
#new_version .helpHead .menu-dots button {
  background: none;
  /* border: 1px solid rgba(96,96,96,.3) !important; */
  border-radius: 10px;
  cursor: pointer;
  height: 60px;
  width: 60px;
}

#new_version .ratings img {
  margin-right: 5px;
  width: 24px;
}

#new_version .helpHead .menu-dots button .img-fluid {
  top: 0;
}

.surveryTopBar .head-center {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  /* max-width: 740px; */
  max-width: 50vw;
}

.helpHead,
.helpHead .helpIco {
  align-items: center;
  display: flex;
}

.ratings {
  align-items: center;
  display: flex;
  margin-right: 20px;
}

.ratings span {
  color: #2c4b9d;
  font-family: Lato-Bold;
  font-size: 18px;
}

#new_version a.anchorLink,
#new_version a.anchorLink {
  color: #f85d18;
  font-weight: 600;
  font-size: 18px;
  font-family: var(--theme-font);
}

.formContainer span {
  color: #535457;
  font-size: 21px;
  display: flex;
  align-items: center;
  gap: 10px;
}

#new_version button.btn {
  border-radius: 0;
}

.surveyList {
  left: -170px;
  overflow: visible;
}

#new_version .surveyIcon .article,
#new_version .surveyIcon .backArrow {
  display: none;
}

#new_version .surveyIcon .forword,
#new_version .surveyIcon .backArrow {
  font-size: 12px;
  margin-left: -2px;
  text-indent: 0;
}

#new_version .surveyIcon .backArrow {
  margin-left: -0px;
}

#new_version .surveyOpen .surveyIcon .forword {
  display: none;
}

#new_version .surveyOpen .surveyIcon .backArrow {
  display: block;
}
#new_version button.surveyIcon:hover {
  /* right: -125px; */
  /* overflow: visible; */
  /* width: auto; */
  /* transition: width 0.3s ease-in-out;
    text-indent: 0px; */
  width: 120px;
}

.leftTxt {
  position: relative;
  left: -10px;
  transition: left 0.9s;
  margin-right: 10px;
}

#new_version button.surveyIcon:hover .leftTxt {
  left: 0;
  transition: left 0.9s;
}

.surveyIcon span.material-symbols-outlined {
  /* filter: invert(1); */
  margin-left: 56px;
}
/* #new_version button.surveyIcon:hover span.material-symbols-outlined {
  margin-left: 10px;
} */
#new_version button.surveyIcon {
  /* right: -26px; */
  width: 26px;
  display: block;
  position: absolute;
  background: #f85d18;
  display: flex;
  align-items: center;
  height: 35px;
  top: 59px;
  border-radius: 0 3px 3px 0;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  left: 100%;
  transition: width 0.6s;
  justify-content: end;
  display: flex;
}

#new_version .mainContainer .main-tab-container {
  min-width: 0;
}

@media (max-width: 1900px) {
  #new_version .publishedOn h5 {
    font-size: 0.9em;
  }
}

@media (max-width: 1680px) {
  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .expand-details {
    width: 100%;
  }

  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .expand-details
    .expand-col1 {
    width: calc(33.33% - 20px);
  }

  #new_version .publishedOn h5 {
    font-size: 0.8em;
  }

  .surveyHeading label {
    font-size: 12px;
  }

  .createSurvey .langSelection {
    margin: 0px 15px 30px;
    width: auto;
  }

  .addQuota {
    gap: 5px;
  }

  .createSurvey .langSelection {
    max-width: 100%;
    width: calc(100% - 30px);
  }

  #new_version
    .admin-content-box
    #searchFilter
    .filtersubDropdownShow.selectedDateRange {
    transform: scale(0.85) translate(72px, -50px);
  }
}

@media (max-width: 1600px) {
  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .expand-heading {
    font-size: 18px;
  }

  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .expand-details {
    width: 100%;
  }

  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .expand-details
    .expand-col1 {
    width: calc(33.33% - 20px);
  }

  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .expand-details
    .expand-col1
    .config-heading {
    font-size: 14px;
  }

  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .expand-details
    .expand-col1
    .config-content
    p {
    font-size: 13px !important;
  }

  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .expand-details
    .expand-col2 {
    width: 20%;
  }

  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .expand-details
    .expand-col2
    .config-heading {
    font-size: 14px;
  }

  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .appconfig
    .config-heading {
    font-size: 14px;
  }

  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .appconfig
    .fileInput {
    width: calc(33.33% - 20px);
  }

  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .appconfig
    .fileInput
    p {
    font-size: 13px !important;
  }

  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .appconfig
    .logo-setting {
    width: calc(66.67% - 20px);
    max-width: 300px;
    height: auto;
    max-height: 300px;
  }

  #new_version .helpHead .menu-dots button {
    border-radius: 7px;
    height: 40px;
    width: 40px;
  }

  .menu-dots .img-fluid {
    position: relative;
    top: 2px;
    width: 5px;
  }
  .formContainer span {
    font-size: 16px;
    line-height: normal;
    margin: 10px auto 30px;
  }
  #new_version a.anchorLink,
  #new_version a.anchorLink {
    font-size: 14px;
  }

  #new_version .admin-content-box #searchFilter .addButton {
    padding: 0 5px;
    max-width: 80px;
    /* max-width: none; */
    overflow: hidden;
  }

  #new_version .admin-content-box button.download-csv {
    padding: 0 5px;
  }
  #new_version .admin-content-box ul.paginate li.previous.disabled a,
  #new_version .admin-content-box ul.paginate li.next.disabled a {
    font-size: 14px !important;
  }
  button.show-dropdown {
    font-size: 12px !important;
  }
}

@media (max-width: 1400px) {
  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .expand-details
    .expand-col1
    .config-content
    p {
    font-size: 12px !important;
  }
}

@media (max-width: 1300px) {
  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .expand-details
    .expand-col1 {
    height: 185px;
    overflow: hidden;
  }

  #new_version .surveyList ul.listings .nameInitial {
    width: 30px;
    height: 30px;
    min-width: 30px;
    font-size: 20px;
  }

  #new_version .surveyList .icoSm {
    width: 25px;
    height: 25px;
  }

  #new_version .surveyList .active .icoSm.share,
  #new_version .surveyList .icoSm.share {
    background-size: 13px;
  }

  #new_version .surveyList .active .icoSm.link,
  #new_version .surveyList .icoSm.link {
    background-size: 16px;
  }

  #new_version .surveyList .active .icoSm.pause,
  #new_version .surveyList .icoSm.pause {
    background-size: 20px;
  }

  #new_version .surveyList .active .icoSm.delete,
  #new_version .surveyList .icoSm.delete {
    background-size: 20px;
  }

  #new_version .surveyList h4 {
    font-size: 20px;
  }

  #new_version .filterIcon {
    width: 35px;
    height: 35px;
  }

  #new_version .filterIcon svg {
    font-size: 1.5rem;
  }

  .top-sec-list > div > .col {
    line-height: 1;
  }
}

@media (max-width: 1600px) {
  #new_version .overview {
    height: 680px;
  }

  #new_version .admin-content-box .col-md-12 .MuiChip-root {
    font-size: 13px;
  }

  #new_version .reports {
    margin: -5px 0;
  }

  #new_version .overview .col-lg-4.p-5.mt-5 {
    margin-top: 0 !important;
  }

  #new_version #settings-content .billingContainer .col-md-6 {
    max-height: 300px;
  }

  #new_version #settings-content h2 {
    font-size: 1.5rem !important;
    font-weight: 600;
  }

  #new_version .admin-content-box .tableContainer table tr th,
  #new_version .admin-content-box .tableContainer table tr td {
    font-size: 14px;
  }
}

@media (max-width: 1440px) {
  #new_version .admin-content-box .col-md-12 .MuiChip-root {
    font-size: 12px;
  }

  body #new_version,
  #new_version p {
    font-size: 0.875rem !important;
  }

  #new_version .para-text3,
  #new_version .para-text4 {
    font-size: 1rem !important;
  }

  #new_version #signContainer .para-text3 {
    font-size: 0.813rem !important;
  }

  #new_version .para-text2 {
    font-size: 1.25rem !important;
  }

  #new_version .para-text1 {
    font-size: 1.5rem !important;
  }

  #new_version .para-text5 {
    font-size: 1.5rem !important;
  }

  /* #new_version #signContainer .boxShadow {
    margin-top: -30px !important;
  } */

  #new_version #signContainer .boxShadow.wd500 {
    width: 380px;
  }

  #new_version .col-2 {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  #new_version #pricing-plan .col-lg-3.col-md-3 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  #new_version #signContainer .boxShadow .formContainer.otp input[type="text"],
  #new_version
    #signContainer
    .boxShadow
    .formContainer.otp
    input[type="number"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  #new_version #signContainer .boxShadow.wd500 .formContainer .col-12.mt-4 {
    margin-top: 1rem !important;
  }

  #new_version .formContainer .remember label {
    margin-bottom: 0;
  }

  #new_version
    #signContainer
    .boxShadow.wd500
    .formContainer
    .text-black.para-text2.mb-4 {
    margin-bottom: 0 !important;
  }

  #new_version .btn-transparent,
  #new_version .btn-login,
  #new_version .btn-free,
  #new_version .btn-plan,
  #new_version .selected,
  #new_version .btn-plan-blue,
  #new_version .contact-form button {
    font-size: 0.875rem !important;
  }

  #new_version .btn-transparent,
  #new_version .btn-login {
    line-height: 1.875rem !important;
  }

  #new_version .hero-header .btn-free.mt-4 {
    margin-top: 0 !important;
  }

  #new_version .p-5 {
    padding: 1.5rem !important;
  }

  #new_version #changelog .p-5 {
    padding: 2.5rem !important;
  }

  #new_version .m-5 {
    margin: 1.5rem !important;
  }

  #new_version #about .col-lg-6.mt-5 {
    margin-top: 1rem !important;
  }

  #new_version .overview {
    max-height: 640px;
    background-position: right;
  }

  #new_version .overview .reports {
    margin: 55px 0;
  }

  #new_version .overview img {
    max-width: inherit;
  }

  #new_version .overview .col-lg-4 {
    flex: 0 0 auto !important;
    width: 38% !important;
    margin-top: 0 !important;
    /* padding-top: 0 !important; */
  }

  #new_version #pricing-plan .col-lg-3 .shadow-box #featuresList.hide-plan,
  #new_version #pricing-plan .col-md-3 .shadow-box #featuresList.hide-plan,
  #new_version
    #signContainer
    #pricing-plan
    .col-lg-3
    .shadow-box
    #featuresList.hide-plan,
  #new_version
    #signContainer
    #pricing-plan
    .col-md-3
    .shadow-box
    #featuresList.hide-plan {
    height: 480px;
  }

  #new_version #settings-content .billingContainer .terminate {
    height: 180px !important;
  }

  #new_version #settings-content .billingContainer .clear.pt-4 {
    padding-top: 0 !important;
  }

  #new_version #settings-content .billingContainer .col-md-6 {
    height: 295px;
    overflow: hidden;
  }

  #new_version
    #settings-content
    .billingContainer
    .col-md-6.px-5.pt-5.mx-3.pb-48 {
    padding: 1rem !important;
  }

  #new_version .ht275 {
    height: 300px;
  }

  #new_version .ht275 p.hidingContent {
    max-height: 215px;
    overflow: hidden;
  }

  #new_version .billingContainer {
    padding: 25px 10px !important;
  }

  #new_version .billingContainer .row.p-5,
  #new_version .billingContainer .row .col-12.p-5 {
    padding: 1rem !important;
  }

  #new_version .billingContainer .row .col-12 {
    width: calc(100% - 2rem);
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  #new_version .billingContainer .row .col-12.py-5 {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  #new_version #settings-content .billingContainer #plan-payment-container {
    margin: 0 !important;
    padding: 17px !important;
  }

  #new_version #settings-content .planDetails,
  #new_version #settings-content .paymentDetails {
    padding: 20px;
  }

  #new_version #settings-content p,
  #new_version #settings-content .timeline {
    font-size: 0.75rem !important;
  }

  #new_version #settings-content .settingsContainer p {
    font-size: 0.875rem !important;
    margin-bottom: 0;
  }

  #new_version #settings-content p.pName,
  #new_version #settings-content .billingContainer a,
  #new_version #settings-content .billingContainer a.details {
    font-size: 0.875rem !important;
    line-height: 1rem !important;
    justify-content: start;
  }

  #new_version #settings-content .billingContainer a,
  #new_version #settings-content .billingContainer a.details {
    justify-content: start;
  }
  #new_version #settings-content .billingContainer a {
    flex-direction: row-reverse;
    justify-content: end;
  }

  #new_version #settings-content .planDetails p.response {
    font-size: 0.875rem !important;
  }

  #new_version #settings-content .billingContainer a {
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: row-reverse;
  }

  #new_version #settings-content .billingContainer a em {
    font-size: 1rem !important;
  }

  .marpadding32 {
    margin: 0 !important;
    padding: 20px !important;
  }

  #new_version #settings-content #billingTable .tableContainer {
    margin: 10px 0 !important;
    width: 100% !important;
  }

  #new_version #settings-content .billingContainer .planDetails .row .lh-34 {
    margin-top: 31px !important;
  }

  #new_version #settings-content #billingTable h3 {
    font-size: 1.125rem !important;
  }

  #new_version
    #settings-content
    #billingTable
    .tableContainer
    table
    tr
    td
    img.noResponse {
    max-height: 100px;
  }

  #new_version .para-text7 {
    font-weight: 600;
    font-size: 1.25rem !important;
    line-height: 2rem !important;
  }

  .Toastify__toast {
    font-size: 14px;
  }

  #new_version #pricing-plan .pricing-label,
  #new_version #signContainer #pricing-plan .pricing-label {
    font-size: 1.125rem !important;
  }

  #new_version #pricing-plan h3.price,
  #new_version #signContainer #pricing-plan h3.price {
    font-size: 1.375rem !important;
  }

  #new_version #pricing-plan #featuresList .text-center.col-12.pt-2.mb-2 {
    padding: 0 !important;
    margin: 0 !important;
  }

  #new_version #pricing-plan #featuresList .py-4.mt-2 {
    padding-top: 1rem !important;
    margin-top: 0 !important;
    /* padding-left: 0.875rem !important;
        padding-right: 0.875rem !important; */
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0.5rem !important;
  }

  #new_version #pricing-plan #featuresList .p-0.px-3.pb-4 {
    padding-bottom: 0.5rem !important;
  }

  #new_version #pricing-plan #featuresList .border-bottom.pt-4,
  #new_version #pricing-plan #featuresList .border-bottom.py-4 {
    padding: 1rem 0rem !important;
    margin: 0 !important;
  }

  #new_version #signContainer #pricing-plan #featuresList .py-4.mt-2,
  #new_version #signContainer #pricing-plan #featuresList .border-bottom.pt-4,
  #new_version #signContainer #pricing-plan #featuresList .border-bottom.py-4 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  #new_version .list-icon {
    width: 28px;
  }

  #new_version #pricing-plan img {
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }

  #new_version #pricing-plan .list-options {
    width: calc(100% - 28px);
    font-size: 0.813rem;
  }

  #new_version #pricing-plan .list-options-check,
  #new_version #pricing-plan .desc,
  #new_version #signContainer #pricing-plan .desc {
    font-size: 0.875rem !important;
  }

  #new_version #pricing-plan .moreLink {
    font-size: 0.938rem !important;
    line-height: 1.5rem !important;
  }

  #new_version .accordion-header,
  #new_version .accordion-header button {
    font-size: 1rem !important;
  }

  #new_version #changelog .changelogDetails .changelogList .listData {
    font-size: 1rem !important;
    line-height: 1rem !important;
  }

  #new_version .navbar-expand-lg .navbar-collapse {
    height: auto;
    padding: 6px 0;
  }

  #new_version .navbar-light .navbar-brand img {
    margin: 5px 0;
  }

  #new_version .navbar-light .navbar-nav .nav-link {
    padding: 0px 10px;
    font-size: 1rem !important;
    line-height: 70px !important;
  }

  #new_version #advQuestion.py-5 {
    /* padding-bottom: 0 !important; */
  }

  #new_version #advQuestion .col-lg-7 {
    width: 66.66667%;
  }

  #new_version #advQuestion .col-lg-5 {
    width: 33.33333%;
  }

  #new_version #advQuestion .col-lg-5 img {
    max-height: 100%;
  }

  #new_version .typeBlocks {
    width: calc(25% - 30px);
  }

  #new_version #about #text-container.col-lg-6 {
    width: 55%;
  }

  #new_version #about #image-container.col-lg-6 {
    width: 45%;
  }

  #new_version #about.py-5 {
    padding-top: 0rem !important;
    padding-bottom: 1.5rem !important;
  }

  #new_version .admin-content-box .tableContainer table tr th,
  #new_version .admin-content-box .tableContainer table tr td {
    font-size: 14px;
  }

  #new_version #auditLogs.admin-content-box .tableContainer table,
  #new_version #userManagement.admin-content-box .tableContainer table {
    min-width: 1430px;
  }

  #new_version #responses.admin-content-box .tableContainer table {
    /* min-width: 1180px; */
  }

  #new_version
    #auditLogs.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(2),
  #new_version
    #auditLogs.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(3),
  #new_version
    #userManagement.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(2),
  #new_version
    #userManagement.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(6),
  #new_version
    #userManagement.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(7),
  #new_version
    #responses.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(2),
  #new_version
    #responses.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(4),
  #new_version
    #responses.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(5),
  #new_version
    #responses.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(6) {
    width: 170px;
  }

  #new_version
    #auditLogs.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(4),
  #new_version
    #auditLogs.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(5),
  #new_version
    #userManagement.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(4),
  #new_version
    #userManagement.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(5) {
    width: 140px;
  }

  #new_version
    #auditLogs.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(7),
  #new_version
    #userManagement.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(3),
  #new_version
    #responses.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(3) {
    width: 230px;
  }

  .sidebar-new.show {
    max-width: 220px;
  }

  #new_version figure.circleImg {
    aspect-ratio: 1;
  }

  .Dashboard-mainPanel-2,
  .Dashboard-mainPanel-122,
  nav.navbar + div {
    width: calc(100% - 220px) !important;
    margin-left: 220px;
  }

  #new_version .surveyList {
    max-width: 300px;
    min-width: 0;
    bottom: 0;
  }

  #new_version .mainContainer .main-tab-container {
    /* max-width: calc(100% - 300px); */
    max-width: calc(100% - 0px);
    width: 100%;
    min-width: 0;
  }

  #new_version .surveyList ul.listings .nameInitial {
    width: 30px;
    height: 30px;
    font-size: 20px;
    min-width: 30px;
  }

  #new_version .surveyList .icoSm {
    width: 25px;
    height: 25px;
  }

  #new_version .surveyList ul.listings li.list {
    padding: 10px 10px;
  }

  #new_version .surveyList ul.listings .right-end .timings {
    font-size: 12px;
  }

  #new_version .surveyList .active .icoSm.share,
  #new_version .surveyList .icoSm.share {
    background-size: 12px;
  }

  #new_version .Search-sec .search-bar {
    padding-left: 30px;
    padding-right: 5px;
    font-size: 12px;
  }

  #new_version .Search-sec .searchBtn svg {
    font-size: 21px;
  }

  #new_version .Search-sec .searchBtn {
    left: 6px;
  }

  #new_version .h4,
  #new_version h4 {
    font-size: 1.2rem;
  }

  #new_version .filterIcon svg {
    font-size: 1.4rem;
  }

  #new_version .filterIcon {
    width: 35px;
    height: 35px;
  }

  #new_version
    .admin-content-box
    #searchFilter
    .filtersubDropdownShow.selectedDateRange {
    transform: scale(0.75) translate(140px, -90px);
  }

  #new_version .formContainer label,
  .forgot-link {
    font-weight: 500;
    font-size: 0.8rem !important;
    font-family: var(--theme-font) !important;
    color: #999;
  }
  #new_version .navbar-light .navbar-brand img {
    max-width: 140px;
    height: auto;
  }

  #new_version #signContainer .boxShadow.wd500 {
    width: 35vw;
  }
  #new_version #signContainer .boxShadow .formContainer img {
    width: 14px;
  }
  #new_version .mt-5.or-continue {
    margin-top: 20px !important;
    font-size: 14px;
  }
  .formContainer span {
    margin: 10px auto 10px;
  }
  #new_version #signContainer .boxShadow .formContainer input[type="text"],
  #new_version #signContainer .boxShadow .formContainer input[type="email"],
  #new_version #signContainer .boxShadow .formContainer input[type="password"] {
    height: 40px;
    font-size: 14px;
  }

  #new_version #signContainer .boxShadow .formContainer .googleBtn img {
    width: 40px;
  }
  #new_version .alert-danger {
    font-size: 12px;
    padding: 10px 40px 10px 10px;
    margin: 13px 0 0 !important;
  }
  #new_version button.surveyIcon {
    width: 25px;
    /* right: -20px; */
    left: 100%;
    transition: width 0.6s;
    justify-content: end;
    display: flex;
    /* text-indent: -80px; */
  }
}

@media (max-width: 1366px) {
  #new_version #advQuestion .col-lg-5 {
    max-height: 600px;
  }

  #new_version p.error,
  #new_version #signContainer #login .boxShadow .formContainer p.error em,
  #new_version p.error em {
    font-size: 0.875rem !important;
  }

  /* #new_version .hero-header #text-container.col-lg-6,
    #new_version #about #text-container.col-lg-6 {
        width: 55%;
    } */

  #new_version .hero-header #image-container.col-lg-6,
  #new_version #about #image-container.col-lg-6 {
    width: 45%;
  }

  #new_version #about.py-5 {
    padding-top: 0rem !important;
    padding-bottom: 1.5rem !important;
  }

  #new_version .admin-content-box .tableContainer table tr th,
  #new_version .admin-content-box .tableContainer table tr td,
  #new_version .fraudStatus .dropdown-toggle,
  #new_version .fraudStatus li {
    font-size: 12px;
  }

  #new_version #auditLogs.admin-content-box .tableContainer table,
  #new_version #userManagement.admin-content-box .tableContainer table {
    min-width: 1350px;
  }

  #new_version #responses.admin-content-box .tableContainer table {
    /* min-width: 1100px; */
  }

  #new_version
    #auditLogs.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(2),
  #new_version
    #auditLogs.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(3),
  #new_version
    #userManagement.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(2),
  #new_version
    #userManagement.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(6),
  #new_version
    #userManagement.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(7),
  #new_version
    #responses.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(2),
  #new_version
    #responses.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(4),
  #new_version
    #responses.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(5),
  #new_version
    #responses.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(6) {
    width: 160px;
  }

  #new_version
    #auditLogs.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(4),
  #new_version
    #auditLogs.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(5),
  #new_version
    #userManagement.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(4),
  #new_version
    #userManagement.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(5) {
    width: 130px;
  }

  #new_version
    #auditLogs.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(7),
  #new_version
    #userManagement.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(3),
  #new_version
    #responses.admin-content-box
    .tableContainer
    table
    tr
    th:nth-of-type(3) {
    width: 220px;
  }
}

@media (max-width: 1280px) {
  #new_version #about.py-5 {
    padding-top: 0rem !important;
    padding-bottom: 1.5rem !important;
  }

  #new_version .overview .reports {
    margin: 70px 0;
  }

  .previewSec.mobileVersion {
    max-width: 232px !important;
  }

  #new_version .main-tab-container > .nav.nav-tabs {
    font-size: 12px;
    gap: 10px;
  }

  #new_version .nav.nav-tabs#previewVersion {
    top: -30px;
  }

  #new_version #preview .nav.nav-tabs .nav-link {
    height: 30px;
    width: 30px;
  }

  /* #new_version .overview .col-lg-4.p-5.mt-5 {
        margin-top: -22px !important;
    } */
}

@media (max-width: 1200px) {
  .previewSec.mobileVersion {
    max-width: 331px !important;
  }
}
@media only screen and (max-width: 1280px) {
  .sidebar-new .side_bar_logo {
    height: auto;
    padding: 15px 15px 0;
  }

  .sidebar-new.show {
    max-width: 220px;
  }

  #new_version figure.circleImg {
    aspect-ratio: 1;
  }

  #new_version li.dropdown a,
  #new_version li.dropdown:hover a {
    font-size: 12px;
  }

  #new_version li.dropdown p {
    font-size: 0.7rem !important;
  }

  #new_version .org-sec.dropdown-toggle::after {
    background-size: 12px;
  }

  .Dashboard-mainPanel-2,
  .Dashboard-mainPanel-122,
  nav.navbar + div {
    width: calc(100% - 220px) !important;
  }

  #new_version .surveyList ul.listings .right-end .timings {
    font-size: 12px;
  }

  #new_version .surveyList ul.listings li.list {
    padding: 10px 10px;
  }

  #new_version .surveyList {
    max-width: 300px;
  }

  #new_version .mainContainer .main-tab-container {
    /* width: calc(100% - 300px); */
    width: calc(100% - 0px);
  }

  .createSurvey .langSelection {
    margin: 0 5px;
  }

  #new_version .accordion-body {
    padding: 1rem 0.5rem;
  }

  #new_version .sub-question #innerAccordion .question-num {
    font-size: 16px;
  }

  #new_version #survey-accordion .accordion-item .hand-icons {
    right: 47px;
  }

  #new_version .Search-sec .searchBtn svg {
    font-size: 21px;
  }

  #new_version .Search-sec .search-bar {
    padding-left: 30px;
    padding-right: 5px;
    font-size: 12px;
  }

  #new_version .Search-sec .searchBtn {
    left: 6px;
  }

  #new_version .dropdown-item {
    font-size: 14px;
  }

  #new_version .btn.btn-md,
  #new_version .btn.btn-md:hover {
    padding: 5px 14px !important;
  }

  .top-sec-list {
    margin: 0 -7px;
  }

  .sub-question .row {
    margin: 0 -5px !important;
  }
}

@media (max-width: 1250px) {
  #new_version .container,
  #new_version .container-lg,
  #new_version .container-md,
  #new_version .container-sm {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  /* #new_version #pricing-plan .col-md-3 .shadow-box {
        margin-bottom: 25px;
    } */

  #new_version #homepage #pricing-plan {
    padding-bottom: 20px !important;
  }
}

@media (max-width: 1230px) {
  #new_version .navbar-light div.wd,
  #new_version .wd1200 {
    width: 1050px;
  }

  #new_version #video_section .hero-header {
    height: auto;
    min-height: 860px;
  }

  #new_version #settings-content .billingContainer a em {
    font-size: 1.125rem !important;
  }

  #new_version #settings-content .billingContainer a {
    font-size: 1rem !important;
    line-height: 1.125rem !important;
  }

  #new_version #settings-content .billingContainer .planDetails .row .lh-34 {
    margin-top: 37px !important;
    line-height: 1.125rem !important;
  }

  #new_version #settings-content p.response,
  #new_version #settings-content .billingContainer .terminate p,
  p.planInfo,
  #new_version .para-text3,
  #new_version #settings-content p.pName {
    font-size: 1rem !important;
  }

  #new_version #about .py-5 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }

  #new_version #pricing-plan .col-lg-3 .shadow-box #featuresList.hide-plan,
  #new_version #pricing-plan .col-md-3 .shadow-box #featuresList.hide-plan {
    height: 500px;
    overflow: hidden;
  }

  #new_version
    #signContainer
    #pricing-plan
    .col-lg-3
    .shadow-box
    #featuresList.hide-plan,
  #new_version
    #signContainer
    #pricing-plan
    .col-md-3
    .shadow-box
    #featuresList.hide-plan {
    height: 500px;
  }

  #new_version #pricing-plan .desc,
  #new_version #pricing-plan .desc,
  #new_version #signContainer #pricing-plan .desc {
    font-size: 0.97rem !important;
  }
}

@media (max-width: 1152px) {
  #new_version .overview .reports {
    margin: 0 0;
  }

  #new_version .overview .col-lg-4.p-5.mt-5 {
    margin-top: -22px !important;
  }

  #new_version .navbar-light .navbar-nav .nav-link {
    margin: 0 5px;
  }

  #new_version .navbar .btn-transparent.ms-6 {
    margin-left: 20px;
  }

  #new_version .overview {
    max-height: 500px;
  }

  #new_version .overview .col-lg-4 {
    width: 49% !important;
  }

  #new_version .contact-info {
    padding: 0 0 0 0;
  }
}

@media (max-width: 1100px) {
  #new_version .navbar-light div.wd,
  #new_version .wd1200 {
    width: 962px;
  }

  #new_version .navbar-light .navbar-nav .nav-link {
    padding: 15px 5px;
  }

  #new_version #settings-content .billingContainer #billingChart .col-12 {
    width: 100%;
    margin: 2rem 0 1rem auto !important;
  }

  #new_version .typeBlocks {
    height: 50px;
    line-height: 50px;
    font-size: 12px;
  }

  #new_version .typeBlocks img {
    max-width: 16px;
    max-height: 16px;
  }

  #new_version #pricing-plan .desc,
  #new_version #pricing-plan .desc,
  #new_version #signContainer #pricing-plan .desc {
    font-size: 0.875rem !important;
  }

  #new_version .row .right-img img {
    object-fit: contain;
    width: 36%;
    margin: auto;
  }

  #new_version .full-height-sec {
    height: auto;
  }

  .leftTxt,
  #new_version .surveyIcon .forword,
  #new_version .surveyIcon .backArrow,
  #new_version .surveyOpen .surveyIcon .backArrow {
    display: none;
  }
  #new_version .surveyIcon .article {
    display: block;
    margin: 0;
  }

  #new_version button.surveyIcon:hover {
    width: 60px;
  }
}

@media screen and (max-width: 1250px) and (min-width: 992px) {
  #new_version {
    width: 100%;
    overflow-x: hidden;
  }
}

@media (max-width: 991.98px) {
  #new_version p,
  #new_version h1,
  #new_version h2,
  #new_version h3,
  #new_version h4,
  #new_version h5,
  #new_version h6,
  body #new_version {
    text-align: center;
  }

  #new_version #pricing-plan p,
  #new_version #pricing-plan #featuresList,
  #new_version #pricing-plan #featuresList h4,
  #new_version #pricing-plan #featuresList p,
  #new_version #pricing-plan #featuresList h3,
  #new_version #pricing-plan a.moreLink,
  #new_version #accordion .collapse.show,
  #new_version #accordion .accordion-body,
  #new_version .contact-info-single .content p,
  #new_version .contact-info-single .content,
  #new_version #signContainer,
  #new_version .navbar-light .navbar-collapse,
  #new_version .admin-content-boxx #new_version #settings-content,
  #new_version #settings-content p,
  #new_version .admin-content-box h3,
  #new_version.packageDetail {
    text-align: left;
  }

  #new_version .navbar-light .btn-transparent {
    display: block;
    /* margin-bottom: 10px; */
  }

  #new_version #contact .col-lg-4.col-md-6,
  #new_version #contact .col-lg-8.col-md-6,
  #new_version #advQuestion .col-lg-7,
  #new_version #advQuestion .container .col-lg-5 {
    width: 100%;
    margin: auto;
  }

  #new_version .typeBlocks {
    font-size: 14px;
  }

  #new_version .typeBlocks img {
    max-width: 20px;
    max-height: 20px;
  }

  #new_version #video_section.homepage .container-xxl .container.my-5.py-5 {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  #new_version #advQuestion .container .col-lg-5.text-lg-end {
    margin-top: 20px;
  }

  #new_version #advQuestion .container .col-lg-5.text-lg-end .me-0 {
    margin: auto !important;
  }

  #new_version .navbar-light.desktop {
    display: none;
  }

  #new_version .navbar-light.mobile {
    display: flex;
    padding: 0 40px !important;
  }

  #new_version .sticky-top.navbar-light {
    position: relative;
    background: #000;
  }

  #new_version .navbar-light .navbar-collapse {
    margin-top: 15px;
    height: auto;
  }

  #new_version .navbar-light .navbar-nav .nav-link,
  #new_version .sticky-top.navbar-light .navbar-nav .nav-link {
    padding: 0 0;
    /* margin-left: 20px; */
    width: calc(100% - 30px);
    margin: 0 auto;
    color: var(--dark) !important;
    line-height: 50px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  #new_version .navbar-light .navbar-brand h1 {
    color: var(--primary);
  }

  #new_version .navbar-light .navbar-brand img {
    max-height: 70px;
  }

  /* #new_version .navbar-light .navbar-nav .nav-link:hover,
  #new_version .navbar-light .navbar-nav .nav-link.active {
    color: #1565d8 !important;
  } */

  /* #new_version .navbar-light .btn-transparent {
    background: #1565d8 !important;
    border: 2px solid #1565d8;
  } */

  /* #new_version .navbar-light .btn-transparent:hover,
  #new_version .navbar-light .btn-transparent:focus {
    background: #fff !important;
    border: 2px solid #1565d8;
  } */

  #new_version .navbar-light .btn-login {
    /* box-shadow: 0 10px 20px 3px #2381cf33; */
    /* border: 2px solid #1565d8; */
  }

  /* #new_version .navbar-light .btn-login:hover,
  #new_version .navbar-light .btn-login:focus {
    background: #1565d8 !important;
    border: 2px solid #1565d8;
  } */

  #new_version #signContainer .hero-header {
    height: auto;
    display: flow-root;
  }

  #new_version #signContainer #pricing-plan.hero-header .container {
    max-width: 95%;
  }

  #new_version .container,
  #new_version .container-lg,
  #new_version .container-md,
  #new_version .container-sm {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    overflow: hidden;
  }

  #new_version .navbar-light div.wd,
  #new_version .wd1200 {
    width: 100%;
  }

  #new_version .navbar-light .navbar-collapse.show {
    box-shadow: 0 5px 5px rgb(0 0 0 / 02%);
  }

  #new_version .btn-free {
    /* margin-bottom: 3rem; */
  }

  #new_version .overview {
    height: auto;
    max-height: none;
    overflow: hidden;
    padding-top: 0 !important;
    /* margin-top: -60px !important; */
    background-size: cover;
  }

  #new_version .overview .container {
    overflow: hidden;
  }

  #new_version .overview .reports {
    margin-top: 85px;
  }

  #new_version .overview .reports img {
    max-height: 450px;
    margin: auto;
  }

  #new_version .overview .col-lg-4 {
    width: 100% !important;
  }

  #new_version #features .col-sm-4 {
    width: 100% !important;
    margin-bottom: 20px;
  }

  #new_version .ht275 {
    height: auto;
  }

  #new_version #pricing-plan .col-lg-3,
  #new_version #pricing-plan .col-md-3,
  #new_version #signContainer #pricing-plan .wd1200 .col-12 .col-lg-3.col-md-3 {
    width: 50%;
    margin-bottom: 20px;
  }

  #new_version #pricing-plan .col-lg-3 .shadow-box #featuresList.hide-plan,
  #new_version #pricing-plan .col-md-3 .shadow-box #featuresList.hide-plan,
  #new_version
    #signContainer
    #pricing-plan
    .col-lg-3
    .shadow-box
    #featuresList.hide-plan,
  #new_version
    #signContainer
    #pricing-plan
    .col-md-3
    .shadow-box
    #featuresList.hide-plan {
    height: 460px;
  }

  #new_version #pricing-plan .col-lg-3.col-md-3 .border-bottom {
    padding: 1.5rem !important;
    margin: 1.5rem !important;
  }

  #new_version #signContainer .boxShadow {
    margin-bottom: 20px !important;
  }

  #new_version #signContainer .container.my-5.py-5 {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  #new_version .hero-header #text-container.col-lg-6,
  #new_version #about #text-container.col-lg-6,
  #new_version .hero-header #image-container.col-lg-6,
  #new_version #about #image-container.col-lg-6 {
    width: 100%;
  }

  #new_version .hero-header #image-container.col-lg-6,
  #new_version #advQuestion .col-lg-5 {
    height: 550px;
    margin: auto;
  }

  #new_version .hero-header #image-container.col-lg-6 img.img-fluid,
  #new_version #advQuestion .col-lg-5 img {
    /* height: 100% !important; */
  }

  #new_version .hero-header.py-5 {
    padding: 0 !important;
  }

  #new_version .navbar-light.mobile.py-3 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  #pricing-plan .mr0,
  #faq .mr0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  /* For sticky header in responsive */
  #navigationBar {
    top: 0;
    background: #fff;
    z-index: 9;
    position: sticky !important;
  }

  #new_version .slider-sec .carousel-indicators {
    justify-content: center;
  }
  .surveyIcon span.material-symbols-outlined {
    margin-left: 80px;
  }
}

@media (min-width: 992px) {
  #new_version .navbar-light {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9;
    max-height: 80px;
  }

  #new_version .navbar-light.mobile.py-3 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  #new_version .sticky-top.navbar-light {
    position: fixed;
    background: #000;
  }

  #new_version .sticky-top.navbar-light .navbar-brand h1 {
    color: var(--primary);
  }

  /* .previewSec.mobileVersion {    
    max-height: calc(100vh - 230px);
  } */
  .LoginmainContainer {
    max-width: 51vw;
  }
  #new_version .btn.btn-wht.px-2 {
    min-width: 120px;
    justify-content: center;
  }
}

@media (max-width: 800px) {
  #new_version .overview .col-lg-4.p-5.mt-5 {
    width: 100% !important;
  }

  #new_version #pricing-plan .col-lg-3.col-md-3 {
    width: 50%;
    margin-bottom: 24px;
  }

  #new_version .overview .reports img {
    max-height: 450px;
  }

  #new_version #pricing-plan .col-lg-3 .shadow-box #featuresList.hide-plan,
  #new_version #pricing-plan .col-md-3 .shadow-box #featuresList.hide-plan {
    height: 480px;
  }
}

@media (max-width: 767px) {
  #new_version #pricing-plan .col-lg-3,
  #new_version #pricing-plan .col-md-3,
  #new_version #signContainer #pricing-plan .wd1200 .col-12 .col-lg-3.col-md-3,
  #new_version #pricing-plan .col-lg-3.col-md-3 {
    width: 100%;
  }

  #new_version #pricing-plan .col-lg-3 .shadow-box #featuresList.hide-plan,
  #new_version #pricing-plan .col-md-3 .shadow-box #featuresList.hide-plan,
  #new_version
    #signContainer
    #pricing-plan
    .col-lg-3
    .shadow-box
    #featuresList.hide-plan,
  #new_version
    #signContainer
    #pricing-plan
    .col-md-3
    .shadow-box
    #featuresList.hide-plan {
    height: auto;
  }

  #new_version .typeBlocks {
    width: calc(33.33% - 30px);
  }
  .surveyIcon span.material-symbols-outlined {
    margin-left: 80px;
  }
  .leftTxt,
  #new_version .surveyIcon .forword,
  #new_version .surveyIcon .backArrow,
  #new_version .surveyOpen .surveyIcon .backArrow {
    display: none;
  }
  #new_version .surveyIcon .article {
    display: block;
    margin: 0;
  }

  #new_version button.surveyIcon:hover {
    width: 60px;
  }
}

@media (max-width: 575px) {
  #new_version .container {
    width: calc(100% - 3rem);
    margin-left: 1.5rem !important;
  }

  #new_version #contact .col-lg-8.col-md-6 {
    width: 90%;
    margin: auto;
  }

  #new_version #advQuestion .col-lg-5 {
    height: 550px;
  }

  #new_version .hero-header #image-container.col-lg-6,
  #new_version .hero-header #image-container.col-lg-6 img.img-fluid,
  #new_version #advQuestion .col-lg-5 img {
    height: auto;
  }

  #new_version #pricing-plan .col-lg-3.col-md-3 {
    width: 100%;
    margin-bottom: 24px;
  }

  #new_version #pricing-plan .col-lg-3 .shadow-box #featuresList.hide-plan,
  #new_version #pricing-plan .col-md-3 .shadow-box #featuresList.hide-plan {
    height: auto;
  }

  #new_version .typeBlocks {
    width: calc(50% - 30px);
  }

  #new_version .overview .col-lg-4.p-5.mt-5 {
    margin-top: 0px !important;
  }

  #new_version .overview .reports {
    margin-top: 0px;
  }

  #new_version .overview {
    padding: 45px 0 25px !important;
  }
}

@media (max-width: 420px) {
  #new_version .container {
    width: 100%;
    margin-left: auto !important;
  }

  #new_version .hero-header .container {
    width: calc(100% - 2rem);
    margin-left: 1rem !important;
  }
}

@media (max-width: 352px) {
  #new_version .typeBlocks {
    width: calc(100% - 30px);
  }
}

.video-sec .full-width-section {
  width: 65%;
  margin: 0 auto;
  margin-top: 50px;
}

.video-sec .full-width-section .image-box-inner {
  position: relative;
  text-align: center;
  overflow: hidden;
  transition: 0.3s all;
  -ms-transition: 0.3s all;
  -webkit-transition: 0.3s all;
}

.video-sec .full-width-section .image-box-inner img {
  width: 100%;
  border-radius: 20px;
}

.video-sec .full-width-section .image-box-inner .play-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 140px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .video-sec .full-width-section .image-box-inner .play-btn {
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
}

.video-sec .popup-content {
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  position: fixed;
  display: none;
  z-index: 14;
}

@media only screen and (max-width: 600px) {
  .video-sec .popup-content {
    z-index: 99999;
  }
}

.video-sec .popup-content .close-popup {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.video-sec .popup-content .close-popup svg {
  fill: #fff;
  width: 25px;
  height: 25px;
}

.video-sec .popup-content .popup-content-inner {
  max-width: 1000px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100vh;
}

@media only screen and (max-width: 1100px) {
  .video-sec .popup-content iframe {
    height: 400px;
  }
}

@media only screen and (max-width: 1100px) and (max-width: 600px) {
  .video-sec .popup-content iframe {
    max-width: 90%;
    height: 220px;
  }
}

.popup-triggered {
  overflow: hidden !important;
  width: 100%;
}

.popup-triggered .buzz-sec {
  height: 100%;
  width: 100%;
  position: relative;
}

.popup-triggered .popup-content {
  display: block;
}

/* Loader High */
/* .surveyPageLoader { display: none;} */

ul.survey-list {
  max-height: calc(100vh - 100px);
  overflow: auto;
}

@media screen and (max-width: 1600px) and (min-width: 1400px) {
  .surveyList {
    min-width: 426px;
  }

  .main-tab-container {
    /* max-width: calc(100% - 426px); */
  }
  #new_version .fraudStatus .dropdown-toggle.greenColor {
    line-height: normal;
  }
}

@media screen and (min-width: 1930px) {
  .main-tab-container {
    width: auto;
    flex: 1;
  }
}

@media (min-width: 1660px) {
  #new_version .surveyList ul.listings .nameInitial {
    width: 35px;
    height: 35px;
    font-size: 23px;
    min-width: 35px;
  }

  #new_version .surveyList ul.listings .right-end .timings {
    white-space: nowrap;
  }
}

/* Mobile slider Css */
#new_version #upperslider.carousel {
  display: flex;
}

#upperslider .carousel-inner img {
  max-height: 700px;
  width: auto;
}

#upperslider .carousel-indicators {
  top: 0;
  left: 0;
  right: 20px;
  height: auto;
  bottom: auto;
  text-indent: 0;
  height: 300px;
  width: 360px;
  position: relative;
  margin: 0;
}

#new_version #upperslider .carousel-indicators button {
  width: auto !important;
  height: auto !important;
  text-indent: 0 !important;
  opacity: 0;
  position: absolute;
  top: 100%;
  margin: 0;
  background: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 20px;
  right: 20px;
}

#new_version #upperslider .carousel-indicators button .rounded-strip {
  border-radius: 10px;
  padding: 10px;
  display: inline-block;
  width: 150px;
  font-size: 16px;
  position: relative;
  font-weight: bold;
}

.rounded-strip:after {
  left: 98%;
  top: 37%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-width: 13px;
  margin-top: -13px;
  transform: rotate(-10deg);
}

.slide-down.active .rounded-strip:after {
  animation: borderInColor 3s ease-in both;
}

.slide-up.active .rounded-strip:after {
  animation: borderInColorRev 3s ease-in both;
}

#new_version #upperslider .carousel-inner {
  position: relative;
  width: 350px;
  overflow: hidden;
  margin: 0;
  float: right;
}

#new_version
  #upperslider
  .carousel-indicators
  button.slide-down.active
  .rounded-strip {
  animation: fadeInColor 3s ease-in both;
}

#new_version
  #upperslider
  .carousel-indicators
  button.slide-up.active
  .rounded-strip {
  animation: fadeInColorRev 3s ease-in both;
}

#new_version
  #upperslider
  .carousel-indicators
  button.slide-down.active
  .wht-content {
  animation-delay: 1s;
  animation: fadeInTxt 3s ease-in both;
}

#new_version
  #upperslider
  .carousel-indicators
  button.slide-up.active
  .wht-content {
  animation-delay: 1s;
  animation: fadeInTxtRev 3s ease-in both;
}

.wht-content {
  display: flex;
  justify-content: center;
  border-radius: 12px;
  background-color: #fff;
  color: #333;
  padding: 14px;
  width: 270px;
  text-align: right;
  font-size: 14px;
}

#new_version #upperslider .carousel-indicators button.slide-down {
  top: 0;
  bottom: auto;
}

#new_version #upperslider .carousel-indicators .slide-down.active {
  animation: fadeInDown 3s ease-in both;
  opacity: 1 !important;
}

#new_version #upperslider .carousel-indicators .slide-up.active {
  animation: fadeInUp 3s ease-in both;
  opacity: 1 !important;
}

/* #new_version #upperslider .carousel-indicators .slide-down.active span {
    animation: fadeIn 3s ease-in both;
    animation-delay: 1s;
}

#new_version #upperslider .carousel-indicators .slide-up.active span {
    animation: fadeIn 3s ease-in both;
} */

@keyframes fadeInTxt {
  from {
    color: #1e70e3;
  }

  to {
    color: #f7b700;
  }
}

@keyframes fadeInTxtRev {
  from {
    color: #f7b700;
  }

  to {
    color: #1e70e3;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 300px, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, -300px, 0);
  }
}

@keyframes fadeInColor {
  from {
    background: #4b8aee;
  }

  to {
    background: #f9cc4c;
  }
}

@keyframes borderInColor {
  from {
    border-left-color: #4b8aee;
  }

  to {
    border-left-color: #f9cc4c;
  }
}

@keyframes borderInColorRev {
  from {
    border-left-color: #f9cc4c;
  }

  to {
    border-left-color: #4b8aee;
  }
}

@keyframes fadeInColorRev {
  from {
    background: #f9cc4c;
  }

  to {
    background: #4b8aee;
  }
}

button {
  border: 0 !important;
}

/* //Mobile slider Css */

/* Checkboxs */
h4.heading-title,
h5.heading-title {
  margin: 10px auto !important;
  display: flex;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.checkCont input:checked ~ .checkmark {
  background-color: #f85d18;
  border-color: #f85d18;
}

.checkCont input:checked ~ .checkmark:after {
  display: block;
}

.checkmark {
  background-color: #fcfcfc;
  border: 1px solid #f85d18;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  height: 24px;
  min-width: 24px;
  position: relative;
  width: 24px;
}

.checkBoxListing .checkCont .checkmark {
  margin: 0 20px 0 0;
}

.checkCont {
  color: #000;
  cursor: pointer;
  display: block;
  font-family: Roboto, sans-serif;
  font-size: 20px;
}

.checkBoxList .checkCont {
  font-weight: 500;
}

.checkCont input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.checkmark:after {
  content: "";
  display: flex;
  display: none;
  height: 100%;
  width: 100%;
}

.checkCont .checkmark:after {
  background: url(../images/checkMark.svg) no-repeat 50%/18px;
}

#new_version label.checkCont {
  align-items: center;
  display: inline-flex;
  flex-direction: row-reverse;
  gap: 10px;
}

.surveyAssigned-list {
  list-style: none;
}

ul.surveyAssigned-list {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Checkboxes */

.configure_interviewer label {
  color: rgba(82, 82, 82, 1);
  font-size: 16px;
  margin: 0 auto 10px;
  font-family: "Roboto";
}

#new_version .configure_interviewer .form-group input.form-control {
  background: rgba(31, 18, 30, 0.05);
  border: 1px solid rgba(31, 18, 30, 0.05);
  border-radius: 4px;
  height: 40px;
}

.surveyAssigned-list.d-grid {
  grid-template-columns: auto auto auto auto auto;
  justify-content: start;
  gap: 30px;
}

.admin-content-box .surveyAssigned-list {
  gap: 0;
  row-gap: 40px;
  flex-wrap: wrap;
}

.admin-content-box .surveyAssigned-list li {
  width: 20%;
}

#new_version .admin-content-box label.checkCont {
  align-items: start;
  position: relative;
  top: 4px;
}

/* #new_version .admin-content-box .checkmark {
    top: 2px;
} */
#new_version .admin-content-box .checkmark {
  top: 0px;
  margin: 0 10px 0 0;
}

.Dashboard-mainPanel-2 {
  width: calc(100% - 260px);
  margin-left: 260px;
}

.iframe-viewer-wrap.survey_analytics_wrap {
  margin-top: 0;
}

.Sidebar-list li .nav-link i.interviewer-icon {
  background: url(../img/survey/interviewer-icon.svg) no-repeat center
    center/24px;
}

.Sidebar-list li .nav-link.active i.interviewer-icon {
  background: url(../img/survey/interviewer-icon-sel.svg) no-repeat center
    center/24px;
}

#new_version .sub-question #innerAccordion .accordion-item .accordion-body {
  padding-top: 0;
}

#new_version .form-floating > .form-control:focus ~ label,
#new_version .form-floating > .form-control:not(:placeholder-shown) ~ label,
#new_version .form-floating > .form-select ~ label {
  color: #555555;
  opacity: 1;
  transform: scale(1) translateY(-1.2rem) translateX(0rem);
}

#new_version .form-floating > label {
  color: #555555;
  opacity: 1;
}
#new_version .form-control {
  background-color: #fbfbfb;
  border: 0;
  border-bottom: 2px solid #999999;
  border-radius: 0;
}
#new_version .form-control:focus {
  border-color: var(--button-secondary-bg);
}
#new_version .form-control.input-border {
  font-size: 1em;
}

#new_version .createSurvey .langSelection .langLabel span {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

span.trash_icon {
  margin-top: -14px;
}

#new_version .addlangDropdown {
  align-items: center;
}

span.changeDropdown {
  color: #f85d18 !important;
  font-weight: normal !important;
}

#new_version .form-floating > .form-control:focus,
#new_version .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 22px !important;
}

#new_version #survey-accordion .text-fields p {
  color: #555555;
  font-family: "Lato", sans-serif;
  font-size: 14px !important;
  margin: 0;
}

#new_version sup {
  font-size: 23px;
  top: 0;
  color: #f14336;
}

#new_version .Search-sec .searchBtn {
  opacity: 0.54 !important;
}

#new_version .no-data-sec h2 {
  margin-top: -30px;
}

#new_version.updatePassword .eyeIcon {
  height: 50px;
}

#new_version .form-floating > label {
  padding-left: 0;
}

#new_version.updatePassword .form-floating > .form-control:focus ~ label,
#new_version.updatePassword
  .form-floating
  > .form-control:not(:placeholder-shown)
  ~ label {
  transform: scale(0.8) translateY(-0.8rem) translateX(0rem);
}

#new_version.updatePassword .form-floating.passTxt p {
  font-size: 0.775rem !important;
}

#new_version #survey-accordion .form-group {
  margin-bottom: 10px;
}

.createSurvey .langSelection {
  max-width: 100%;
}

#new_version .createSurvey .langSelection .langLabel {
  min-width: 185px;
  max-width: 185px;
  flex: 0 0 31%;
  position: relative;
}

.createSurvey .langSelection .addLangbutton,
.add-btn {
  position: relative;
}

#new_version .lang-div {
  max-width: 100%;
  flex-wrap: wrap;
  /* overflow: auto; */
}

#new_version .langDropdown {
  position: static;
}

#new_version .addlangDropdown .dropdown-menu {
  left: 0 !important;
  transform: translate(0px, 58px) !important;
}

#new_version .addlangDropdown .add-btn .dropdown-menu {
  transform: translate(0px, 48px) !important;
}

.modal-dialog h4.heading-title {
  margin-top: 0 !important;
  justify-content: center;
}

#new_version .langDropdown span.trash_icon a,
.addLangbutton img {
  position: relative;
  top: 7px;
}

#new_version .addLangbutton img {
  position: relative;
  margin-top: 0px;
  margin-bottom: 10px;
}

#new_version.updatePassword .form-control {
  padding: 0.4rem 0 !important;
}

#new_version.updatePassword .form-floating.passTxt p {
  margin: 4px 0;
}

/* Survey preview */

.surveyMain {
  position: relative;
}

#new_version .countSec {
  padding-left: 15px;
  position: absolute;
  top: 0px;
  z-index: 1;
  background: #fff;
  width: 100%;
}

#new_version .active.carousel-item-start,
#new_version .carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(0) translateY(-100%);
}

#new_version .active.carousel-item-end,
#new_version .carousel-item-next:not(.carousel-item-start) {
  transform: translateX(0) translateY(100%);
}

#new_version .countSec h2 {
  color: rgba(24, 24, 27, 0.06);
  font-family: "Roboto", sans-serif;
  font-size: 104px;
  font-style: normal;
  font-weight: 900 !important;
  margin: 0;
}

.webView {
  display: flex;
  justify-content: center;
}

.demo-container .webView {
  height: calc(100vh - 190px);
  position: relative;
  top: 0px;
  padding: 20px 0 0;
}

#new_version h3.surveyQues {
  font-size: 22px;
  line-height: 2;
  margin: 0 0 8px;
  font-family: "Lato", sans-serif;
  font-weight: 900 !important;
}

.sideBarStatus {
  border-radius: 16px;
  box-sizing: border-box;
  height: 100%;
  margin-top: 0;
  min-width: 270px;
}

.sideBarStatus,
.stbBackground {
  background: #fff;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;
  padding: 25px;
  position: relative;
  z-index: 4;
  display: none;
}

.demo-container {
  background-color: #f0f0f0;
}

/* .surveyContent.alignBottomCont {
    bottom: 0;
    height: calc(100vh - 280px);
    max-height: 100%;
    max-height: calc(100vh - 280px);
    order: 3;
    overflow-x: visible;
    overflow-y: auto;
    position: relative;
    width: 100%;
} */
.surveyContent.alignBottomCont .surveyData {
  padding: 120px 0 0;
  height: calc(100vh - 320px);
  overflow: auto;
}

.genderSelectCont,
.postalInput,
.surveyCheckBoxSection,
.trip-diary,
.underLineInputCont {
  background: #fff;
  box-sizing: border-box;
  display: flow-root;
  margin-top: 40px;
  padding: 0 25px;
}

.LoginmainContainer {
  background: #fff;
  border-radius: 16px;
  box-sizing: border-box;
  height: 100vh;
  height: 100%;
  /* max-width: 450px; */
  max-width: 50vw;
  width: 100%;
  overflow: hidden;
  overflow: auto;
}

.image-grid {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 6px 0;
}

.image-grid figure.img-fig {
  margin-bottom: 10px;
}

.image-grid figure.img-fig {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  object-fit: cover;
  width: 30%;
}

.image-grid figure.img-fig img {
  border-radius: 10px;
  height: 100%;
  max-height: none;
  max-width: none;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.btnWithCheckBox label.checkCont,
.checkBoxListing .checkCont {
  align-items: center;
  display: inline-flex;
  flex-direction: row-reverse;
}

#new_version .checkBoxList .checkCont {
  font-weight: 500;
  margin-bottom: 25px;
  gap: 0;
}

.nextPrev {
  align-items: center;
  background: #f9f9f9;
  display: flex;
  margin: 0;
  order: 4;
  /* padding: 10px 50px; */
  padding: 10px 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 100%;
  justify-content: center;
}

.mobileVersion .nextPrev {
  padding: 10px 20px;
}

#new_version p.subRadioText {
  margin: 0;
}
.nextPrev button {
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  cursor: pointer;
  height: 60px;
  margin: 0 6px 0 0;
  width: 70px;
}

#new_version .nextPrev button {
  background: #fff;
  border: 1px solid #d9d9d9 !important;
  border-radius: 10px;
  cursor: pointer;
  height: 60px;
  margin: 0 6px 0 0;
  width: 70px;
}

#new_version .carousel-control-next.next {
  background: #fff url(../images/arrow_right.svg) no-repeat center center / 16px !important;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  cursor: pointer;
  font-size: 0;
  height: 60px;
  margin: 0 6px 0 0;
  width: 70px;
}

#new_version .carousel-control-prev-icon {
  background: #fff url(../images/arrow_left.svg) no-repeat center center / 16px !important;
}

#new_version .carousel-control-next-icon,
#new_version .carousel-control-prev-icon {
  background: none;
}

.heading_container {
  position: absolute;
  top: 8%;
}

ul.mainListing {
  list-style: none;
  margin: 15px 0;
  padding: 0;
}

ul.mainListing li {
  color: #6e6e6e;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  margin-bottom: 8px;
}

ul.mainListing li.mainSub {
  font-weight: bold;
}

ul.mainListing li.SideBardActive,
.SideBardActive {
  color: #f85d18;
}

#new_version .carousel-control-next,
#new_version .carousel-control-prev {
  position: relative;
}

.boxCheckmark {
  background: #fcfcfc;
  border: 2px solid #757575;
  border-radius: 50%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  height: 24px;
  left: 0;
  position: absolute;
  top: 3px;
  transition: all 0.2s ease;
  width: 24px;
}

.selectCustCont .selectBox,
#new_version .homeTypeSelect .dropdown-toggle {
  background: #fff;
  border-radius: 0;
  min-height: 24px;
  padding: 13px 0;
  position: relative;
  width: 100%;
  text-align: left;
}

.dropSelected .selectBox,
#new_version .homeTypeSelect .dropdown-toggle {
  border: 0;
  color: grey;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homeTypeSelect {
  position: relative;
}

#new_version .selectCustCont ul li a.dropdown-item {
  list-style: none;
  padding: 15px;
}

#new_version .underLineInput .textArea,
#new_version .underLineInput input.underInput {
  background: transparent;
  /* border: none;
  border-bottom: 1px solid #e7e7e7; */
  border: 1px solid #c4c4c4;
  color: #3d3d3d;
  color: grey;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: 55px;
  line-height: 24px;
  /* margin-left: -60px;
  margin-right: 10px;
  margin-top: 30px; */
  outline: none;
  /* padding: 0 0 0 60px; */
  position: relative;
  text-align: center;
  text-align: left;
  width: 100%;
  border-radius: 4px;
  padding: 16.5px 14px;
  margin: 0 0 20px;
  box-sizing: border-box;
  min-height: 50px;
}

#new_version .selectCustCont ul {
  background: #fff;
  border-radius: 10px !important;
  box-shadow: 0 0 10px #00000029;
  display: none;
  left: 0;
  list-style: none;
  margin: 0;
  max-height: 165px;
  overflow: auto;
  padding: 0;
  position: absolute;
  right: 0;
  top: 60px;
  width: 100%;
  z-index: 2;
  border: 0 !important;
}

#new_version .cal-sec input,
#new_version .timepicker input {
  background: #fff url(../img/cal_icon.svg) no-repeat 100%;
  color: grey;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  padding: 20px 0;
}

#new_version .timepicker input {
  background: #fff url(../img/time_icon.svg) no-repeat 100%;
}

.boxCheckmark:after {
  content: "";
  display: none;
  position: absolute;
}

.checkBoxContainer .boxCheckmark:after {
  background: #f85d18;
  border-radius: 100px;
  bottom: 0;
  height: 16px;
  left: 0;
  line-height: 0;
  margin: auto;
  right: 0;
  top: 0;
  width: 16px;
}

.checkBoxContainer input:checked ~ .boxCheckmark:after {
  display: block;
}

.checkBoxContainer input:checked ~ .boxCheckmark {
  background-color: #fff;
  border-color: #f85d18;
}

.checkBoxContainer {
  align-items: center;
  color: grey;
  cursor: pointer;
  display: flex;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
  padding-left: 38px;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
}

.checkBoxContainer input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

/* sliders */
.sliderSec {
  left: 0;
  margin: 30px auto 0;
  /* max-width: calc(100% - 80px); */
  max-width: 100%;
  position: absolute;
  right: 0;
}

.sliderSec {
  max-width: calc(100% - 0px);
}

.sliderSec .MuiSlider-rail {
  position: relative;
  left: -40px;
  width: calc(100% + 80px);
}

.sliderSec .MuiBox-root {
  max-width: calc(100% - 0px);
  z-index: 1;
  position: relative;
  padding: 0 40px;
  left: 0;
  box-sizing: border-box;
  width: 100%;
}

.swiper-slide-active span {
  border-radius: 100px;
  color: #fff;
}

.swiper-slide-active span.nps1 {
  background: #fc902d;
}

.swiper-slide-active span.nps2 {
  background: #ed9433;
}

.swiper-slide-active span.nps3 {
  background: #de9838;
}

.swiper-slide-active span.nps4 {
  background: #cf9c3d;
}

.swiper-slide-active span.nps5 {
  background: #c1a042;
}

.swiper-slide-active span.nps6 {
  background: #b2a448;
}

.swiper-slide-active span.nps7 {
  background: #a3a84d;
}

.swiper-slide-active span.nps8 {
  background: #94ac52;
}

.swiper-slide-active span.nps9 {
  background: #86af57;
}

.swiper-slide-active span.nps10 {
  background: #77b35c;
}

.npsQuses::before {
  border-top: 1px solid #000000;
}

.npsQuses::after {
  border-bottom: 1px solid #000000;
}

.agecalculate {
  margin-top: 20px;
  text-align: left;
  color: #3d3d3d;
  font-size: 14px;
}

.surveyInfo {
  box-sizing: border-box;
}

/* // For Shadows */
.css-frv3qg-MuiSlider-thumb:before,
.MuiSlider-thumb:before {
  box-shadow: 0px 3px 1px -2px rgba(201, 140, 22, 0.2),
    0px 2px 2px 0px rgba(201, 140, 22, 0.14),
    0px 1px 5px 0px rgba(201, 140, 22, 0.12) !important;
}

.css-frv3qg-MuiSlider-thumb:hover,
.css-frv3qg-MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-root .MuiSlider-thumb:hover,
.MuiSlider-thumb .Mui-focusVisible {
  box-shadow: 0px 0px 0px 8px rgba(201, 140, 22, 0.16) !important;
}

.css-frv3qg-MuiSlider-thumb.Mui-active,
.MuiSlider-thumb.Mui-active,
.MuiSlider-thumb.Mui-active:hover {
  box-shadow: 0px 0px 0px 14px rgba(201, 140, 22, 0.16) !important;
}

/* // for good rate shadow */
.goodRate .css-frv3qg-MuiSlider-thumb:before,
.goodRate .MuiSlider-thumb:hover,
.goodRate .Mui-focusVisible {
  box-shadow: 0px 3px 1px -2px rgba(20, 178, 83, 0.2),
    0px 2px 2px 0px rgba(20, 178, 83, 0.14),
    0px 1px 5px 0px rgba(20, 178, 83, 0.12) !important;
}

.goodRate .css-frv3qg-MuiSlider-thumb:hover,
.goodRate .css-frv3qg-MuiSlider-thumb.Mui-focusVisible,
.goodRate .MuiSlider-thumb:hover,
.goodRate .MuiSlider-thumb.Mui-focusVisible {
  box-shadow: 0px 0px 0px 8px rgba(20, 178, 83, 0.16) !important;
}

.goodRate .css-frv3qg-MuiSlider-thumb.Mui-active,
.goodRate .MuiSlider-thumb.Mui-active {
  box-shadow: 0px 0px 0px 14px rgba(20, 178, 83, 0.16) !important;
}

/* // for poor rate shadow */
.poorRate .css-frv3qg-MuiSlider-thumb:before,
.poorRate .MuiSlider-thumb:before {
  box-shadow: 0px 3px 1px -2px rgba(201, 8, 8, 0.2),
    0px 2px 2px 0px rgba(201, 8, 8, 0.14), 0px 1px 5px 0px rgba(201, 8, 8, 0.12) !important;
}

.poorRate .css-frv3qg-MuiSlider-thumb:hover,
.poorRate .css-frv3qg-MuiSlider-thumb.Mui-focusVisible,
.poorRate .MuiSlider-thumb:hover,
.poorRate .MuiSlider-thumb.Mui-focusVisible {
  box-shadow: 0px 0px 0px 8px rgba(201, 8, 8, 0.16) !important;
}

.poorRate .css-frv3qg-MuiSlider-thumb.Mui-active,
.poorRate .MuiSlider-thumb.Mui-active {
  box-shadow: 0px 0px 0px 14px rgba(201, 8, 8, 0.16) !important;
}

.css-1diafny-MuiSlider-root,
.sliderSec .MuiSlider-colorSecondary,
.sliderSec .MuiSlider-rail {
  color: #c98c16 !important;
  border-radius: 0 !important;
  height: 2px !important;
  opacity: 1 !important;
}

/* // For Good Rating */
.goodRate .css-1diafny-MuiSlider-root,
.goodRate.sliderSec .MuiSlider-colorSecondary,
.goodRate.sliderSec .MuiSlider-rail {
  color: #14b253 !important;
  border-radius: 0 !important;
  height: 2px !important;
  opacity: 1 !important;
}

/* // For Poor Rating */
.poorRate .css-1diafny-MuiSlider-root,
.poorRate.sliderSec .MuiSlider-colorSecondary,
.poorRate.sliderSec .MuiSlider-rail {
  color: #c90808 !important;
  border-radius: 0 !important;
  height: 2px !important;
  opacity: 1 !important;
}

.css-nnid7-MuiSlider-valueLabel.MuiSlider-valueLabelOpen,
.MuiSlider-valueLabelOpen,
.MuiSlider-valueLabel,
.css-nnid7-MuiSlider-valueLabel {
  background: none !important;
  top: 50% !important;
  transform: translate(0px, -50%) !important;
  color: #fff;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.css-frv3qg-MuiSlider-thumb,
.MuiSlider-thumb {
  width: 50px !important;
  height: 50px !important;
}

.css-1gv0vcd-MuiSlider-track,
.MuiSlider-track {
  border: 0 !important;
}

.css-14pt78w-MuiSlider-rail {
  opacity: 1 !important;
}

/* /sliders */

#new_version .underLineInput .textArea,
#new_version .underLineInput input.underInput {
  background: transparent;
  /* border: none;
  border-bottom: 1px solid #e7e7e7;
  color: #3d3d3d; */
  color: grey;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  height: 55px;
  line-height: 24px;
  margin-left: -60px;
  margin-right: 10px;
  margin-top: 30px;
  outline: none;
  /* padding: 0 0 0 60px; */
  position: relative;
  text-align: center;
  text-align: left;
  width: 80px;
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  margin: 0;
  padding: 5px 10px;
}

#new_version .underLineInput .textArea {
  min-height: 75px;
  min-height: 0;
  height: auto;
  resize: none;
  color: gray;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 16.5px 14px;
  margin: 0;
  /* padding: 5px 10px; */
}

#new_version .locationListShow .customRoundedInput.withClose input {
  background: transparent;
  border: none;
  border-bottom: 2px solid #e7e7e7;
  border-radius: 0;
  color: #3d3d3d;
  color: grey;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  height: 55px;
  line-height: 24px;
  margin-left: -60px;
  margin-right: 10px;
  margin-top: 30px;
  outline: none;
  padding: 0 0 0 60px;
  position: relative;
  text-align: left;
  width: 80px;
}

#new_version .locationListShow .customRoundedInput.withClose input {
  margin-top: 0;
  padding-right: 47px;
  width: calc(100% - 0px);
}

.locationListShow .customRoundedInput:after {
  background: url(../img/location_icon.svg) no-repeat 50%;
  content: "";
  display: inline-block;
  height: 30px;
  position: absolute;
  right: 60px;
  top: 17px;
  width: 30px;
}

#new_version .locationListShow .customRoundedInput img {
  bottom: 0;
  cursor: pointer;
  left: -40px;
  margin: auto;
  position: absolute;
  top: 0;
}

#new_version .locationListShow .customRoundedInput {
  margin-bottom: 20px;
  position: relative;
}

#new_version .locationListShow .customRoundedInput.withClose img.clsInp {
  cursor: pointer;
  left: auto;
  margin-top: 16px;
  opacity: 0.5;
  right: 12px;
  width: 30px;
}

.ratingsSec {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  flex-direction: row-reverse;
  max-width: 400px;
  margin: 40px auto 0;
}

.videoSec {
  border: 0;
  border-radius: 10px;
  height: 200px;
  margin: 20px auto 0;
  width: 100%;
}

.gridQuesRadioCheck {
  max-height: 300px;
  overflow: auto;
  padding-bottom: 20px;
}

.gridQuesRadioCheck table tr td.leftAligned {
  background: #fff;
  left: 0;
  padding-left: 0;
  position: sticky;
  text-align: left;
  z-index: 2;
}

.gridQuesRadioCheck table tr td {
  color: grey;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 700;
  min-width: 90px;
  padding: 10px 0;
  text-align: center;
}
#new_version .accordion-item:not(:first-of-type) {
  border: 1px solid #f85d18;
}

#new_version .settingsContainer .accordion-item:not(:first-of-type) {
  border: 0px solid #f85d18;
}
.gridQuesRadioCheck table tr:first-of-type {
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3;
}

.csat-survey .emoji-container .emoji {
  border: 0;
  border-radius: 50%;
  display: inline-flex;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
  transition: all 0.3s;
  width: 100%;
}

.csat-survey .emoji-container {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin: 40px 0 52px;
  max-width: 100%;
  max-width: 400px;
  margin: 40px auto 0;
}

.csat-survey .emoji-container .emoji.active,
.csat-survey .emoji-container .emoji:hover {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.csat-survey .emoji-container .emoji.active:after,
.csat-survey .emoji-container .emoji:hover:after {
  display: block;
}

.csat-survey .emoji-container .emoji:after {
  background: linear-gradient(180deg, #ff7814, #f85d18 87.53%);
  border-radius: 50%;
  bottom: -17px;
  box-shadow: 0 2.8125px 2.8125px rgba(20, 106, 178, 0.4);
  content: "";
  display: inline-block;
  display: none;
  height: 8px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 8px;
}

#new_version #preview .nav.nav-tabs .nav-link,
#new_version #iframeTab .nav.nav-tabs .nav-link {
  background: #edf6fd !important;
  height: 40px;
  width: 40px;
  border: 2px solid #f85d18 !important;
  padding: 0;
  border-radius: 5px;
  margin: 0 0 0 10px;
}

#new_version #preview .nav.nav-tabs .active {
  background: #f85d18 !important;
}

#new_version #preview .nav.nav-tabs .nav-link#desk-tab,
#new_version #iframeTab .nav.nav-tabs .nav-link#desk-tab {
  background: rgba(248, 93, 24, 0.08) url(../img/desk-icon.svg) no-repeat center
    center / 60% !important;
}

#new_version #preview .nav.nav-tabs .nav-link#desk-tab.active,
#new_version #iframeTab .nav.nav-tabs .nav-link#desk-tab.active {
  background: #f85d18 url(../img/desk-icon_sel.svg) no-repeat center center /
    60% !important;
}

#new_version #preview .nav.nav-tabs .nav-link#mobileView-tab,
#new_version #iframeTab .nav.nav-tabs .nav-link#mobileView-tab {
  background: rgba(248, 93, 24, 0.08) url(../img/mobile-icon.svg) no-repeat
    center center / 40% !important;
}

#new_version #preview .nav.nav-tabs .nav-link#mobileView-tab.active,
#new_version #iframeTab .nav.nav-tabs .nav-link#mobileView-tab.active {
  background: #f85d18 url(../img/mobile_icon-sel.svg) no-repeat center center /
    40% !important;
}

#new_version .tab-content .tab-pane {
  position: relative;
}

#new_version .nav.nav-tabs#previewVersion {
  position: absolute;
  right: 0;
  top: -50px;
  left: auto;
  justify-content: end;
  width: auto;
}

.success-animation {
  left: -20px;
  max-height: 170px;
  position: relative;
  width: 100px;
}

.success-animation .lottieAnimation {
  height: auto;
}

.sec-column {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 320px);
  height: calc(100vh - 360px);
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.secDetails,
.surveyDetail {
  text-align: left;
}

/* .surveyContent.alignBottomCont .surveyData {
    padding: 80px 0 0;
} */
#new_version .secDetails h2,
#new_version .surveyDetail h2 {
  color: #18181b;
  font-size: 1.5em;
  margin: 20px auto;
}

.completeSurveySection {
  padding: 20px 25px;
}

#new_version .secDetails h3,
#new_version .surveyDetail h3 {
  color: #3d3d3d !important;
  font-size: 19px;
  font-family: "Lato", sans-serif;
  font-weight: 900 !important;
}

#new_version .surveyDetail button.blue-btn,
#new_version .surveyDetail button.btnRounded {
  background: #f85d18;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 700;
  height: auto;
  margin: 30px auto 5px;
  min-height: 60px;
  padding: 20px 0;
  width: 100%;
  text-transform: none;
}

#new_version .midContent > p {
  color: #3d3d3d;
  font-family: "Lato", sans-serif;
  font-size: 22px !important;
  font-weight: 500;
  max-width: 530px;
  text-align: center;
  width: 100%;
  margin: 10px auto 40px;
}

.demo-container
  .webView
  .LoginmainContainerDemo
  .referEarn
  .midContent
  .skip-share {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 50px;
  width: 80%;
}
.skip-share {
  align-items: center;
  display: flex;
  flex-direction: column;
}

#new_version .skip,
#new_version .skip:hover {
  background: #f85d18;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 700;
  max-width: 20px auto 15px;
  height: auto;
  line-height: 1.5;
  max-width: 430px;
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

#new_version .skip-share .share-btn {
  background: #fff;
  border: 1px solid #f85d18 !important;
  border-radius: 10px;
  box-sizing: border-box;
  color: #f85d18;
  cursor: pointer;
  display: block;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 700;
  max-width: 430px;
  outline: none;
  padding: 20px 80px;
  text-transform: capitalize;
  transition: all 0.2s;
  margin: 20px auto 0;
  width: 100%;
}

.referEarn .midContent {
  padding: 0 10%;
}

/* Assign Interviewers */
span.tags {
  background: rgba(20, 106, 178, 0.11);
  color: #f85d18;
  padding: 10px 15px;
  border-radius: 10px;
  margin: 0 20px 0 0;
  font-weight: bold;
}

#new_version button.btn.addSurvey,
#new_version button.btn.addSurvey:hover {
  width: 150px;
}

#new_version button.btn.addSurvey.light {
  background: #f9fafb;
  color: #f85d18;
}

#new_version .admin-content-box .tableContainer.interviewers table tr td {
  padding: 18px 10px;
  font-weight: 700;
}

#new_version #moreSchedule .modal-dialog {
  width: 30%;
  max-width: 430px;
}

#new_version h4.names {
  font-size: 16px;
}

#new_version #moreSchedule h2 {
  font-family: "Roboto";
  font-style: normal;
  font-size: 20px;
  line-height: 23px;
  color: #101828;
}

.time-listing {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.time-listing span.tags {
  width: 100%;
  margin: 10px 0 10px;
  text-align: center;
}

.inactive {
  display: inline-flex;
  color: #dd2424;
  padding: 10px 20px;
  background: rgba(221, 36, 36, 0.11);
  border-radius: 20px;
  margin-left: 10px;
  font-weight: 600;
}

.previewSec.mobileVersion {
  max-width: 370px;
  /* max-width: 339px !important; */
  overflow: hidden;
  margin: auto;
  border-radius: 50px;
  padding-top: 60px;
  box-sizing: border-box;
  height: calc(100vh - 90px);
  margin-bottom: 20px;
  background: #fff;
  max-width: 18.8vw;
}

.mobileVersion .demo-container .webView {
  padding: 0;
  border-radius: 0;
}

.mobileVersion .demo-container {
  position: relative;
  z-index: 9;
}

.mobileVersion .sideBarStatus {
  display: none;
}

.mobileVersion .LoginmainContainer {
  border-radius: 0;
  height: calc(100% - 76px);
  height: calc(100% - 0px);
}

.mobileVersion .mobile-device {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../img/mock2.png) no-repeat center center / contain;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  max-width: 500px;
  padding: 20px 0;
  z-index: 9;
}

#new_version .mobileVersion .countSec {
  padding: 0;
  left: -20px;
  left: 10px;
}

.mobileVersion .surveryTopBar {
  top: 0px;
}

.mobileVersion .demo-container .webView {
  height: calc(100vh - 160px);
  height: calc(100vh - 240px);
}

.mobileVersion .genderSelectCont,
.mobileVersion .postalInput,
.mobileVersion .surveyCheckBoxSection,
.mobileVersion .trip-diary,
.mobileVersion .underLineInputCont {
  background: #fff;
  box-sizing: border-box;
  display: flow-root;
  margin-top: 20px;
  padding: 0 25px;
}

#new_version h3.surveyQues {
  line-height: normal;
}

#new_version .mobileVersion .countSec {
  height: 110px;
}

.midImage > div {
  max-height: 200px !important;
}
#new_version .surveyList {
  position: fixed;
  z-index: 9;
  transition: all 0.4s;
  overflow: visible;
}
#new_version .surveyList.surveyOpen {
  position: sticky;
  /* left: 260px; */
  left: 0;
  transition: all 0.4s;
}

@media screen and (max-width: 1600px) {
  #new_version .mobileVersion .midContent > p {
    font-size: 16px !important;
  }

  .mobileVersion .referEarn .midContent {
    padding: 0 8%;
  }

  .mobileVersion .nextPrev {
    padding: 10px 20px;
  }

  #new_version .countSec h2 {
    font-size: 76px;
  }

  #new_version .carousel-control-next.next,
  #new_version .nextPrev button {
    height: 50px;
    width: 60px;
  }

  #new_version .mobileVersion .countSec {
    height: auto;
  }

  #new_version h3.surveyQues {
    font-size: 18px;
    line-height: 1.8;
  }

  .checkCont,
  .checkBoxContainer {
    font-size: 1.1em;
  }

  #new_version p.surveyParaCont {
    font-size: 14px !important;
  }

  .gridQuesRadioCheck table tr td {
    font-size: 15px;
  }

  .surveyContent.alignBottomCont .surveyData {
    padding: 70px 0 0;
  }

  #new_version .midContent > p {
    font-size: 17px !important;
  }

  #new_version .skip,
  #new_version .skip:hover,
  #new_version .skip-share .share-btn {
    padding: 15px 0;
  }
}

@media screen and (max-width: 1710px) {
  .previewSec.mobileVersion {
    max-width: 327px;
    max-width: 18.6vw;
  }

  .mobileVersion .surveyContent.alignBottomCont .surveyData {
    padding: 90px 0 0;
  }
}

@media screen and (min-width: 1930px) {
  /* .previewSec.mobileVersion {
        max-width: 100%;
    } */
  .previewSec.mobileVersion {
    max-width: 450px;
  }

  #new_version .main-tab-container {
    flex: 0 0 auto;
    width: calc(100% - 410px);
  }

  .previewSec.mobileVersion .mobile-device {
    display: none;
  }
}

@media screen and (max-width: 1590px) {
  .previewSec.mobileVersion {
    max-width: 18.4vw;
    /* max-width: 28.6vw; */
    /* max-width: 18.7vw; */
  }
  .LoginmainContainer {
    /* max-width: 446px; */
    max-width: 50vw;
  }

  .previewSec.mobileVersion {
    max-height: 1000px;
  }
}

@media screen and (max-width: 1450px) {
  #new_version .mobileVersion .countSec h2 {
    font-size: 70px;
  }

  #new_version .mobileVersion .countSec {
    height: auto;
  }

  .mobileVersion .surveyContent.alignBottomCont .surveyData {
    padding: 60px 0 0;
  }

  .mobileVersion .surveyLogo {
    width: 80px;
    height: auto;
  }

  #new_version h3.surveyQues {
    font-size: 15px;
    line-height: 1.5;
  }

  .checkCont,
  .checkBoxContainer {
    font-size: 13px;
  }

  .checkBoxListing .checkCont .checkmark {
    height: 18px;
    min-width: 18px;
    position: relative;
    width: 18px;
    margin-right: 10px;
  }

  .boxCheckmark {
    height: 18px;
    width: 18px;
    top: 1px;
  }

  .checkBoxContainer {
    padding-left: 28px;
    margin-bottom: 10px;
  }

  .checkCont .checkmark:after {
    background-size: 10px;
  }

  .checkBoxContainer .boxCheckmark:after {
    height: 10px;
    width: 10px;
  }

  .dropSelected .selectBox,
  #new_version .homeTypeSelect .dropdown-toggle {
    font-size: 14px;
  }

  #new_version .selectCustCont ul li a.dropdown-item {
    padding: 7px 10px;
    font-size: 12px;
  }

  #new_version .checkBoxList .checkCont {
    margin-bottom: 10px;
  }

  .mobileVersion .genderSelectCont,
  .mobileVersion .postalInput,
  .mobileVersion .surveyCheckBoxSection,
  .mobileVersion .trip-diary,
  .mobileVersion .underLineInputCont {
    padding: 0 15px;
  }

  .mobileVersion .ratings {
    margin-right: 10px;
  }

  #new_version p.surveyParaCont {
    font-size: 0.675rem !important;
  }

  #new_version .mobileVersion .helpHead .menu-dots button {
    border-radius: 7px;
    height: 30px;
    width: 20px;
  }

  .mobileVersion .menu-dots .img-fluid {
    position: relative;
    top: 2px;
    width: 4px;
  }

  #new_version .mobileVersion .ratings img {
    margin-right: 5px;
    width: 14px;
  }

  .previewSec.mobileVersion {
    padding-top: 40px;
    /* border-radius: 28px; */
    border-radius: 45px;
    /* height: calc(100vh - 80px); */
    height: calc(100vh - 75px);
    margin-bottom: 10px;
    max-width: 318px;
  }

  #new_version .carousel-control-next.next,
  #new_version .nextPrev button,
  #new_version .carousel-control-prev-icon {
    border-radius: 4px;
    height: 35px;
    width: 40px;
    background-size: 11px !important;
  }

  .mobileVersion .demo-container .webView {
    height: calc(100vh - 180px);
  }

  .mobileVersion .LoginmainContainer {
    border-radius: 0;
    height: calc(100% - 0px);
  }

  .surveyContent.alignBottomCont .surveyData {
    height: calc(100vh - 270px);
  }

  .mobileVersion .nextPrev {
    padding: 10px 15px;
  }

  .referEarn .midContent {
    padding: 0 8%;
  }

  /* #new_version .mobileVersion .underLineInput .textArea,
  #new_version .mobileVersion .underLineInput input.underInput {
    margin-left: -20px;
  } */

  #new_version .mobileVersion .cal-sec input,
  #new_version .mobileVersion .timepicker input {
    font-size: 12px;
    background-size: 14px;
  }

  .mobileVersion .css-frv3qg-MuiSlider-thumb,
  .MuiSlider-thumb {
    width: 30px !important;
    height: 30px !important;
  }

  .mobileVersion .MuiSlider-valueLabel,
  .mobileVersion .css-nnid7-MuiSlider-valueLabel {
    font-size: 16px !important;
  }

  #new_version .mobileVersion .underLineInput .textArea,
  #new_version .mobileVersion .underLineInput input.underInput {
    height: 45px;
    font-size: 16px;
    /* padding-left: 22px; */
  }

  .videoSec {
    height: 110px;
  }

  #new_version .locationListShow .customRoundedInput.withClose input {
    font-size: 15px;
  }

  .checkmark {
    width: 18px;
    height: 18px;
    min-width: 18px;
  }

  .gridQuesRadioCheck table tr td {
    font-size: 12px;
  }

  #new_version .secDetails h2,
  #new_version .surveyDetail h2 {
    font-size: 1.2em;
  }

  #new_version .secDetails h3,
  #new_version .surveyDetail h3 {
    font-size: 14px;
  }

  .success-animation {
    left: -10px;
    max-height: 150px;
    width: 60px;
  }

  .completeSurveySection {
    padding: 20px 15px;
  }

  #new_version .LoginmainContainer .carousel-inner {
    height: 100%;
  }

  #new_version .LoginmainContainer .carousel {
    height: calc(100% - 60px);
  }

  #new_version .surveyDetail button.blue-btn,
  #new_version .surveyDetail button.btnRounded,
  #new_version .skip,
  #new_version .skip:hover,
  #new_version .skip-share .share-btn {
    font-size: 14px;
    padding: 10px 0;
    min-height: 0;
  }

  .midImage > div {
    max-height: 130px !important;
  }

  #new_version .mobileVersion .midContent > p {
    font-size: 12px !important;
  }

  ul.mainListing li {
    font-size: 18px;
  }
  .surveyHeading .title span {
    font-size: 16px;
    display: flex;
    align-items: center;
    line-height: normal;
  }
  #new_version .carousel-control-next.next,
  #new_version .carousel-control-prev-icon {
    background-size: 8px !important;
  }
  .css-nnid7-MuiSlider-valueLabel,
  .css-nnid7-MuiSlider-valueLabel:hover,
  .css-nnid7-MuiSlider-valueLabel.MuiSlider-valueLabelOpen,
  .MuiSlider-valueLabelOpen,
  .MuiSlider-valueLabel,
  .css-nnid7-MuiSlider-valueLabel {
    font-size: 12px !important;
  }
  .surveyList {
    left: -80px;
    overflow: visible;
  }

  /* #new_version button.surveyIcon:hover {
    right: -114px;
  } */
  #new_version .surveyList.surveyOpen {
    /* left: 220px; */
  }
}
@media screen and (max-width: 1450px) and (min-width: 1290px) {
  .previewSec.mobileVersion {
    max-width: 261px;
    max-height: 580px;
    margin-bottom: 20px;
  }
  .mobileVersion .mobile-device {
    max-height: 594px;
  }
  .mobileVersion .LoginmainContainer {
    height: calc(100% - 28px);
    height: 480px;
  }

  .surveyContent.alignBottomCont .surveyData {
    /* height: calc(100vh - 360px); */
    height: calc(100vh - 260px);
  }

  #new_version .nav.nav-tabs#previewVersion {
    /* top: -110px; */
  }
  #myTabContent.tab-content.previewtabs {
    padding: 2vh 0 0;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
  }
  #new_version .tab-content.previewtabs .tab-pane {
    width: 100%;
  }
  .sec-column {
    height: calc(100vh - 384px);
  }
  #new_version .admin-content-box .tableContainer table tr td,
  #new_version .admin-content-box .tableContainer table tr th {
    max-width: 190px;
    white-space: break-spaces;
    word-wrap: break-word;
  }
}

/* @media screen and (max-width: 1430px) and (max-height: 670px) {
  .previewSec.mobileVersion {
    max-width: 262px;
  }
}

@media screen and (max-width: 1442px) and (min-width: 1438px) {
  .previewSec.mobileVersion {
    max-width: 318px;
  }
}
@media screen and (max-width: 1442px) and (min-height: 680px) and (max-height: 690px) {
  .previewSec.mobileVersion {
    max-width: 271px;
  } 
}*/

.checkboxes label::before {
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  background: #ddd;
  border-radius: 3px;
  cursor: pointer;
}

.checkboxes input:checked + ::before {
  background: #f85d18;
}

.checkboxes {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
}

.checkboxes input {
  position: absolute;
  opacity: 0;
}

.tableContainer .checkmark {
  border: 1px solid #d0d5dd;
}

.uploadImg {
  display: inline-block;
  margin: 0 10px 0 0 !important;
  vertical-align: middle;
  height: auto;
  line-height: normal;
}

.upload-file-btn {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  opacity: 0;
}

.form-group label {
  color: #525252;
  font-size: 16px;
  margin: 0 auto 10px;
  line-height: normal;
}

button.addButton.training {
  display: flex;
  gap: 10px;
  border-radius: 6px !important;
  padding: 0 10px;
  width: 120px;
  text-align: center;
  justify-content: center;
  font-size: 16px !important;
}

button.addButton.training img {
  max-width: 20px !important;
  margin: 0 !important;
}

.form-control.select,
.form-control.input-text {
  background: rgba(31, 18, 30, 0.05) !important;
  border: 1px solid rgba(31, 18, 30, 0.05) !important;
  border-radius: 4px !important;
}

.grey-box-sec {
  display: flex;
  align-items: end;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 0;
  background: #f7f7f7;
  border: 1px solid #eaecf0;
  border-radius: 5px;
  margin: 0 auto 20px;
}

.section-bar {
  padding: 10px 20px 20px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.content-sec-msg {
  width: 100%;
}

.content-sec-msg .comment-repeat {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 20px 20px 0;
}

.comment-repeat:hover {
  justify-content: start;
}

.comment-repeat:hover .comment-bar {
  max-width: 90%;
  transition: all 0.3s;
}

#new_version .comment-bar.col {
  width: 100%;
  transition: all 0.3s;
  /* flex: 0 0 calc(100% - 150px) !important; */
}

button.trashIcon {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  background: #f85d18 url(../img/deleteIcon.svg) no-repeat center center / auto;
}

.msg-icon {
  align-self: start;
  width: 40px;
  max-width: 30px;
}

.trash-sec {
  align-items: end;
  display: flex;
  justify-content: end;
}

.comment-bar {
  padding: 20px 20px 20px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  text-align: right;
  border: 0;
  outline: 0;
}

.comment-bar p {
  margin: 0 !important;
}

.check-cross a {
  display: flex;
  width: 40px;
  height: 40px;
  background: #979797;
  border-radius: 50%;
}

.check-cross a.checkIcon.active {
  background-color: #2cb442;
}

.check-cross {
  display: flex;
  gap: 10px;
  padding: 10px 0px 10px 20px;
  justify-content: space-between;
  max-width: 110px;
}

.check-cross .crossIcon {
  background: #f85d18;
}

.check-cross img {
  height: 15px;
}

button.add-btn {
  background: #000000;
  border-radius: 50% !important;
  width: 42px;
  height: 42px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.add-btn svg {
  width: 30px;
  height: 30px;
}

#new_version .greyd-sec td {
  padding: 15px 20px !important;
}

#new_version .greyd-sec a.blue-txt {
  color: #f85d18;
  text-decoration: underline;
}

span.blue-txt,
a.blue-txt {
  color: #f85d18;
}

.trashIcon,
.resendIcon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url(../img/deleteIcon.svg) no-repeat center center;
  margin-right: 3px;
}

.resendIcon {
  background: url(../img/resend.svg) no-repeat center center / 20px;
}

.resend-btn {
  background: #f85d18;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px !important;
}

#new_version button.btn.trash-btn {
  background: #d1554c;
  color: #fff;
  margin: 0 0 0 10px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

tr.greyd-sec {
  background: #eaecf0;
}

.cross {
  display: none;
  width: 30px;
  height: 30px;
  background: url(../img/cross.svg) no-repeat center center / 18px;
}

.comment-repeat:hover .cross {
  display: block;
}

button.show-dropdown {
  background: rgba(248, 93, 24, 0.06);
  border: 1px solid #f85d18 !important;
  padding: 10px;
  border-radius: 10px !important;
  overflow: hidden;
  color: #f85d18;
  font-weight: bold;
  font-family: "Roboto";
}

.collapsed .que-title {
  max-width: calc(100% - 210px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.que-title {
  max-width: calc(100% - 210px);
}

.grey {
  color: #3e3e3e !important;
}

.surveyDetail p {
  font-size: 19px !important;
}

#new_version .surveyResponse table {
  margin: 0;
}

#new_version #surveyTab.nav.nav-tabs {
  width: auto;
}

.date-check {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

#new_version .date-check label {
  padding: 0;
  gap: 10px !important;
  justify-content: start;
}

.survey-themes {
  display: inline-flex;
}

.primary-colors,
.theme-app .white-bg {
  background: #fff;
  border-radius: 10px;
  margin: 10px;
  overflow: hidden;
}

#new_version .primary-colors h5 {
  font-size: 16px;
}

.theme-colors,
.bg-color-theme {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  padding: 20px;
  border-right: 1px solid #ececec;
  align-items: start;
  justify-content: start;
  align-self: start;
}

.bg-color-theme {
  border: 0;
}

.sqr-color {
  display: inline-flex;
  width: 100px;
  height: 65px;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
}

.white {
  background: #fff;
}

.blue {
  background: #f85d18;
}

.red {
  background: #b33939;
}

.orng {
  background: #cd6133;
}

.yellow {
  background: #f79f1f;
}

.green {
  background: #009432;
}

.pink {
  background: #d26d90;
}

.sqr-color input {
  opacity: 0;
  position: absolute;
}

.sqr-color label {
  position: absolute;
  display: inline-flex !important;
  width: 100%;
  cursor: pointer;
  height: 100%;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.sqr-color input:checked + label {
  background: url(../images/check_icon.svg) no-repeat center center;
  font-size: 0;
}

.bg-color-theme .sqr-color input:checked + label {
  background: url(../images/check_icon_grey.svg) no-repeat center center;
  font-size: 0;
}

.bg-color-theme .sqr-color {
  border: 1px solid #cbcbcb;
}

.white-bg {
  background-color: #fff;
}

.menu-icon {
  display: flex;
  width: 40px;
  height: 40px;
  background: url(../images/menu-icon.svg) no-repeat center center;
  align-items: center;
  justify-content: center;
}

.color-box {
  display: flex;
  width: 100%;
  height: 62px;
  max-width: calc(100% - 30px);
  margin: 10px auto 15px;
  border-radius: 10px;
  flex-direction: column;
  align-items: unset;
  justify-content: space-evenly;
  padding: 10px 0 0;
}

.line {
  background-color: #fff;
  height: 2px;
  width: 60%;
  display: inline-block;
  margin: 4px 20px 0px;
}

.bottom-menu {
  display: flex;
  width: 100%;
  justify-content: space-around;
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.menuIcon {
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.menuIcon img {
  width: 30px;
}

#new_version button.blnk-btn {
  display: block;
  width: 66px !important;
  border: 0;
  background: #fff;
  height: 17px;
  border-radius: 4px !important;
  margin: 10px auto !important;
}

#new_version .orgn {
  align-items: center;
}

#new_version button.editBtn,
#new_version button.saveBtn {
  display: inline-block;
  width: auto;
  padding: 3px 10px;
  background: #f85d18;
  color: #fff;
  min-width: 0;
  border-radius: 2px;
  font-size: 12px !important;
  margin: 0 0px 0 3px;
}

#new_version button.editBtn {
  background: url(../images/edit_icon.svg) no-repeat center center;
  height: 20px;
  width: 16px;
  padding: 0;
  filter: brightness(0);
}

#new_version button.saveBtn {
  background: url(../images/saveIcon.svg) no-repeat center center / 20px;
  height: 20px;
  width: 20px;
  padding: 0;
}

#new_version .orgn p.orgName {
  font-size: 13px !important;
  max-width: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textArea_text {
  height: auto;
  border: 0;
  resize: none !important;
  background: none;
  border-bottom: 1px solid #000;
  width: 115px;
  font-size: 13px !important;
}

#new_version .org-sec.dropdown-toggle::after {
  display: none;
}

#new_version .templates {
  border: 1px solid #ddd;
  max-width: 30%;
  border-radius: 18px;
  border: 1px solid #d8d8d8;
  background: #fff;
  display: flex;
  justify-content: center;
  position: relative;
  /* height: 500px;
  height: 480px; */
  height: 540px;
  align-items: center;
  overflow: hidden;
  padding: 0 !important;
  max-width: 15.8%;
  min-width: 237px;
}

#new_version .templates img.template-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#new_version .editor-container .CodeMirror {
  width: 50%;
  height: 600px;
}
.add-blank {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.5s;
}
.edit-btn {
  background: #f85d18;
  color: #fff;
  padding: 10px 40px;
  border-radius: 5px !important;
}

#new_version .templates:hover .overlay {
  transition: all 0.5s;
  opacity: 1;
}
#new_version .add-blank h4 {
  font-size: 12px;
  margin: 15px auto 5px;
}

#new_version .add-blank small {
  font-size: 12px;
  color: #667085;
}
.add-blank img {
  width: 30px;
}
.blue-circle {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background: #f85d18;
  position: absolute;
}

.blue-circle.top {
  top: -20px;
  left: -20px;
}

.blue-circle.bottom {
  bottom: -20px;
  right: -20px;
}

.coding-sec {
  height: 500px;
  position: relative;
}
.CodeMirror {
  height: 100% !important;
}

.coding-sec,
.email-template-sec {
  border-radius: 20px;
  overflow: hidden;
  padding: 0 !important;
  min-width: 50%;
  height: 700px;
}

.email-template-sec {
  border: 1px solid #d8d8d8;
}

#mobileView-tab-pane {
  width: 400px;
  margin: 0 auto;
  height: 500px;
}

.cm-s-material.CodeMirror {
  padding: 40px 0 0;
}
.top-stripe {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  padding: 7px 20px;
}

#iframeTab .nav-tabs {
  display: flex;
  justify-content: center;
  padding: 10px 0 !important;
  width: 100% !important;
}

#iframeTab {
  border: 1px solid #d8d8d8;
  border-radius: 20px;
  padding: 0px 30px 20px;
}

#new_version button.listIcon,
#new_version button.gridIcon {
  display: inline-flex;
  width: 40px;
  height: 40px;
}

#new_version button.listIcon {
  background: url(../images/list-check.svg) no-repeat center center;
}

#new_version button.gridIcon {
  background: url(../images/apps.svg) no-repeat center center;
}

#new_version button.gridIcon.active {
  background: #f85d18 url(../images/apps-active.svg) no-repeat center center;
}

#new_version button.listIcon.active {
  background: #f85d18 url(../images/list-check-active.svg) no-repeat center
    center;
}

#new_version .buttons-sec {
  position: absolute;
  right: 0;
  top: 40px;
  width: auto;
  gap: 10px;
  padding-right: 18px;
  display: flex;
}

#new_version .profileContainer h5 {
  height: 30px;
  margin: 0 auto 0px;
}

.edit-para {
  display: inline-flex;
  align-items: start;
}
#new_version .edit-para button.editBtn {
  width: 40px;
  height: 30px;
  background-size: 15px;
}

#new_version button.editBtn.lg {
  background-size: 100%;
  top: 5px;
  margin-left: 10px;
  position: relative;
}

.bordered {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px 5px 5px 15px;
  color: #475467;
  font-family: "Roboto", sans-serif;
  gap: 10px;
  display: flex;
  align-items: center;
}

.bordered .blue-bg {
  /* background: #f85d18; */
  background: var(--Secondary, #f85d18);
  color: #fff;
  padding: 4px 20px;
  border-radius: 5px;
  display: inline-flex;
  font-weight: bold;
}

.analyticsSec {
  border: 1px solid #ddd;
  border-radius: 5px;
}

.columnSec {
  gap: 10px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  align-items: center;
}

#new_version .form-control.edited-text {
  border: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  padding: 0;
  font-size: 24px;
  line-height: 29px;
  color: #101828;
  font-weight: 700;
  font-family: Lato, sans-serif;
}

#new_version .form-control.edited-text:disabled {
  border: 0;
  background: none;
}

#iframeTab.top-resolution .nav-tabs {
  padding: 15px 0 !important;
}

.dateTime a {
  /* height: 100%; */
  display: flex;
  align-items: center;
  align-self: center;
  gap: 10px;
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 8px 16px 0px rgba(16, 24, 40, 0.06);
  padding: 10px 20px;
  border-radius: 5px;
}

#new_version .row .right-img {
  width: auto;
  padding: 80px 20px !important;
  border-radius: 15px;
  background: #fff;
}

@media (max-width: 1500px) {
  #new_version button.btn.addSurvey,
  #new_version button.btn.addSurvey:hover {
    width: auto;
  }

  #new_version .orgn p.orgName {
    font-size: 10px !important;
  }

  #new_version .orgn p.orgName,
  .textArea_text {
    font-size: 10px !important;
  }
  #new_version .profileContainer h5 {
    font-size: 16px;
  }

  #new_version .form-control.input-text {
    font-size: 14px;
  }

  #iframeTab {
    padding: 0px 10px 20px;
  }

  #new_version .container-xxl .container,
  .modal-open #new_version .container-xxl .container {
    max-width: var(--max-lp-width);
    width: 90%;
  }
  #new_version #login.hero-header,
  #new_version #reg.hero-header {
    padding: 0;
  }

  #new_version .container-xxl .container .gap-4,
  .modal-open #new_version .container-xxl .container .gap-4 {
    gap: 100px !important;
  }

  #new_version .container-xxl .container,
  .modal-open #new_version .container-xxl .container {
    max-width: 100%;
  }
  #new_version button.btn,
  #new_version .btn.btn-primary,
  #new_version .btn.btn-secondary {
    padding: 10px 10px !important;
  }
  .formContainer .fa-envelope:before {
    font-size: 17px;
  }
  #new_version .row .right-img img {
    height: 95% !important;
  }
}

@media (max-width: 1280px) {
  #new_version #preview .nav.nav-tabs .nav-link {
    width: 30px;
    height: 30px;
  }

  #new_version .main-tab-container > .nav.nav-tabs {
    font-size: 12px;
    gap: 10px;
  }

  #new_version .nav.nav-tabs#previewVersion {
    top: -38px;
  }

  .email-template-sec iframe {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  #new_version .container-xxl .container {
    width: 100%;
    margin: 0 auto !important;
  }

  #new_version .row .right-img {
    order: 1;
    margin-bottom: 30px;
  }
  #new_version .row .right-img {
    padding: 40px 20px !important;
  }
  #new_version #signContainer .boxShadow.wd500 {
    order: 2;
  }
  .LoginmainContainer,
  .surveryTopBar .head-center {
    max-width: none;
  }
  #new_version .admin-content-box label.switch {
    width: 30px !important;
    margin: 7px 0 0 0;
  }
  .mobileVersion .surveyContent.alignBottomCont .surveyData {
    padding-top: 100px;
  }
  #new_version .mobileVersion .countSec {
    left: 10px;
  }

  .sec-column {
    height: calc(100vh - 450px);
  }

  .question_container {
    margin: 0 auto 10px;
  }
  #new_version #settings-content p.small {
    line-height: 1.5em !important;
  }
  #new_version .profileForm .px-3 {
    padding-left: 0.7rem !important;
    padding-right: 0.7rem !important;
    margin-bottom: 10px;
  }
}

/* Fonts */
#new_version .btn {
  font-family: var(--theme-font);
}
body {
  background: #fbfbfb;
}

#new_version .btn-primary,
#new_version .btn-primary:hover,
#new_version .btn-check:focus + .btn-primary,
#new_version .btn-primary:focus {
  background: #f85d18;
}

.font-sm {
  font-size: 12px;
}

#new_version a.cursor-point {
  color: #696f79;
}

#new_version .progress-bar {
  background-color: #f85d18;
}

#new_version .row .right-img {
  padding: 20px 100px !important;
  max-width: 50vw;
  max-height: calc(100vh - 0px);
  height: 100%;
  display: flex;
  align-items: center;
  background: #fff url(../images/bg-mobile.svg) no-repeat center center / 75%;
}

#new_version .row .right-img img {
  height: 80%;
  min-height: 0;
  object-fit: cover;
}

.full-height-sec {
  height: calc(100vh - 130px);
}

#new_version .nav-link:focus,
#new_version .nav-link:hover {
  color: #f85d18;
}
#new_version .text-primary {
  color: #f85d18 !important;
}

#new_version .dropdown-item.active,
#new_version .dropdown-item:active {
  background-color: #000;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #000 !important;
}

.CustomDropdown-primaryHover-82,
.jss130 {
  margin: 0 !important;
}
.CustomDropdown-primaryHover-82:hover,
.jss130:hover {
  background: #000 !important;
  margin: 0;
}

#new_version button.navbarToggle,
.deskVersion {
  display: none;
}

.mobile-logo {
  display: none;
}

#new_version .filterIcon:disabled {
  opacity: 1;
  background: #f4f4f4;
}

#new_version h6.percentH6 {
  position: absolute;
  top: 50%;
  font-size: 14px;
  right: 50%;
  margin: 0 auto;
  transform: translate(50%, -50%);
}

#new_version .admin-content-box button.btn.addSurvey {
  padding: 9px 20px !important;
}

.analysisSec {
  flex-direction: row;
  flex-wrap: wrap;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
  position: absolute;
}

@keyframes ellipsis {
  to {
    width: 40px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 40px;
  }
}

.wordWraped {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Responsive Section */
@media screen and (max-width: 1200px) {
  #new_version .full-height-sec {
    flex-direction: column;
    flex-direction: row;
  }
  #new_version #signContainer .boxShadow.wd500,
  #new_version .row .right-img {
    width: 100% !important;
    max-width: none !important;
    height: auto !important;
  }
  #new_version .navbar-light .navbar-toggler {
    display: none;
  }
  #new_version .MuiSelect-select,
  .MuiList-root.MuiMenu-list .MuiButtonBase-root.MuiListItem-root,
  .MuiList-root.MuiMenu-list li.MuiButtonBase-root {
    max-height: 40px !important;
  }
  .sidebar-new.show {
    border-right: 1px solid #ddd;
  }

  #new_version .thanks img {
    max-width: 180px;
    display: inline-block;
    width: 180px;
    height: 180px;
  }
  .mobileVersion .mobile-device {
    display: none;
  }
  .previewSec.mobileVersion {
    border: 10px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    margin: 10px auto 0;
    max-width: 260px !important;
  }
}
@media screen and (max-width: 767px) {
  #new_version .row .right-img {
    padding: 20px 10px !important;
    height: 70vh !important;
    /* max-height: calc(50vh - 0px); */
    max-height: 550px;
    min-height: 0;
    margin: 0;
    background: none;
    min-height: 91px;
  }
  #new_version p.error {
    text-align: left;
  }
  #new_version .navbar-light .navbar-toggler {
    display: none;
  }
  #new_version .navbar-light.mobile {
    justify-content: center;
  }
  #new_version .gap-5.full-height-sec {
    gap: 2rem !important;
    justify-content: start !important;
    height: auto;
  }
  #new_version .navbar-light .navbar-brand {
    margin: 0;
  }
  #new_version .navbar-light .navbar-brand img {
    margin: 2px 0;
  }
  #new_version .login-btn-mob {
    margin: 24px 0 0;
    padding: 0 !important;
    gap: 1.5rem;
  }
  #new_version .btn-login {
    line-height: 1.675rem !important;
    height: 41px;
  }
  .full-height-sec {
    height: calc(100vh - 160px);
  }

  #new_version .thanks img {
    max-width: 140px;
    display: inline-block;
    width: 140px;
    height: 140px;
  }

  #new_version .sticky-top.navbar-light {
    position: fixed;
    top: 0;
    width: 100%;
  }
  #new_version .sticky-top .login-btn-mob {
    display: none !important;
  }

  #new_version .sticky-top.navbar-light .navbar-brand img {
    height: 45px;
  }
  #new_version .row .right-img {
    background: url(../images/bg-mobile.svg) no-repeat center center / 100%;
  }
  #new_version .row .right-img img {
    width: auto;
  }
}

/* For iPad Responsive */

@media screen and (max-width: 1100px) {
  .sidebar-new {
    /* left: -100%; */
    left: -300px;
    top: 0;
  }
  #new_version.MenuOpen button.navbarToggle span.material-symbols-outlined {
    background: #000 url(../images/cross-icon.svg) no-repeat center center /
      30px;
    font-size: 0;
    width: 40px;
    height: 40px;
    display: flex;
    position: relative;
    left: -2px;
    top: -1px;
  }
  .Dashboard-mainPanel-2,
  .Dashboard-mainPanel-122,
  nav.navbar + div {
    width: calc(100% - 0px) !important;
  }
  #new_version .surveyList {
    position: absolute;
    left: -300px;
    top: 0;
    z-index: 1;
    width: 100%;
  }

  #new_version .surveyList.show {
    right: 0;
  }
  #new_version .accordion-header .d-flex {
    padding: 0 15px;
    gap: 0px;
    justify-content: space-between;
  }

  #new_version .mainContainer .main-tab-container {
    width: calc(100% - 0px);
    max-width: none;
  }
  #new_version #survey-accordion .text-fields p,
  #new_version .createSurvey .langSelection .langLabel span {
    text-align: left;
  }
  .Dashboard-mainPanel-2,
  .Dashboard-mainPanel-122,
  nav.navbar + div {
    margin: 0;
  }
  #new_version
    .admin-content-box
    .wt-container-bxShadow
    .tableContainer
    + .d-flex {
    gap: 20px;
  }
  #new_version .d-flex,
  #new_version h3.surveyQues,
  #new_version p.surveyParaCont,
  #new_version .countSec h2,
  #new_version .secDetails h2,
  #new_version .surveyDetail h2,
  #new_version .secDetails h3,
  #new_version .surveyDetail h3 {
    text-align: left;
  }

  .genderSelectCont,
  .postalInput,
  .surveyCheckBoxSection,
  .trip-diary,
  .underLineInputCont {
    padding: 20px 15px;
  }
  .userBtnSection,
  .module-details-table,
  .surveyResponse,
  .auditLogs,
  .userMgmt,
  .settingsContainer,
  .cstmTab,
  .respondentInfo,
  .logDetails,
  .viewUser,
  .userModule {
    margin: 0;
    width: 100%;
  }
  #new_version .admin-content-box .col-md2 {
    max-width: 100%;
    width: 100%;
  }
  #new_version .admin-content-box .col-md1 {
    width: 100%;
  }
  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .expand-details
    .expand-col1 {
    width: calc(50% - 20px);
  }

  #new_version .primary-colors h5 {
    text-align: left;
  }

  #new_version .justify-content-start.cred-container {
    width: 100%;
    gap: 10px;
    justify-content: space-between !important;
  }
  .twillio,
  .sendgrid {
    margin: 0;
    width: calc(50% - 10px);
  }
  .makeStyles-text-1052,
  .makeStyles-text-74 {
    max-width: 100%;
  }
  #new_version .profileContainer h5 {
    text-align: left;
  }
  #new_version .templates {
    min-width: 217px;
  }
  .previewSec.mobileVersion {
    max-width: 442px !important;
  }
  #new_version .MuiSelect-select,
  .MuiList-root.MuiMenu-list .MuiButtonBase-root.MuiListItem-root,
  .MuiList-root.MuiMenu-list li.MuiButtonBase-root {
    max-height: 40px !important;
  }
}

@media screen and (max-width: 1100px) {
  .modalOpen {
    position: fixed;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  #new_version .main-tab-container > .nav.nav-tabs {
    gap: 0 10px !important;
    width: 100%;
    max-width: none;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  #new_version .nav.nav-tabs#previewVersion {
    top: 0;
    position: relative;
    left: 0;
    right: 0;
    margin: 10px auto;
    /* display: none; */
  }

  /* .mobileVersion .mobile-device {
    display: inline-block;
  } */
  .previewSec.mobileVersion {
    /* max-width: 320px !important; */
    max-width: 260px !important;
  }
  .previewSec.mobileVersion {
    height: calc(100vh - 128px);
    max-height: calc(100vh - 170px);
  }
  .mobileVersion .LoginmainContainer {
    height: calc(100% - 60px);
  }

  .mobileVersion .demo-container .webView {
    height: calc(100vh - 233px);
  }
  #myTabContent.tab-content {
    padding: 0px 0 10px;
  }

  /* .previewSec {
    display: none;
  } */

  .previewSec .demo-container,
  .previewSec .surveryTopBar {
    display: none;
  }
  .previewSec.mobileVersion .demo-container,
  .previewSec.mobileVersion .surveryTopBar {
    display: block;
  }
  .previewSec.mobileVersion .deskVersion {
    display: none;
  }

  .previewSec.mobileVersion,
  .deskVersion {
    display: block;
  }
  .deskVersion {
    filter: blur(1.5px);
  }

  #new_version .sub-question #innerAccordion .question-num {
    font-size: 16px;
    position: absolute;
    z-index: 1;
    left: -29px;
    right: 0;
    width: 30px;
    height: 30px;
    background: #000;
    padding: 0;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 5px 0 0 5px;
    color: #fff;
    margin-top: 5px;
  }
  #new_version .accordion-header .d-flex {
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }

  #new_version button .addBtn.mt-4.mb-3 {
    margin: 0 auto !important;
  }

  #new_version .text-danger.errorMsg {
    font-size: 10px;
  }

  #new_version .light-blue-btn {
    padding: 7px 13px;
    font-size: 10px;
    display: flex;
    align-items: center;
  }
  #new_version .addLangbutton img {
    width: 20px;
  }
  #new_version .createSurvey .langSelection .langLabel span {
    font-size: 14px;
  }

  #new_version .createSurveyForm .card-body {
    padding: 15px 14px 0px 15px;
  }
  .createSurvey .surveyHeading {
    flex-direction: column;
    align-items: start;
    gap: 10px;
    height: auto;
    margin: 0 auto 15px !important;
  }

  .cstmTab,
  .nav.nav-tabs {
    padding: 0;
  }

  #new_version .accordion-item .my-4 {
    margin: 0 !important;
    width: 100%;
  }
  #new_version .accordion-item .my-4.preview-box {
    margin: 20px auto 0 !important;
  }

  .form-group label {
    position: relative;
    top: 0;
  }

  #new_version .option-desc .d-flex {
    flex-direction: column;
  }

  #new_version .descriptionInput,
  #new_version .titleInput {
    width: 100%;
    max-width: 100%;
  }

  #new_version .card.options-sec .form-control.grey {
    font-size: 12px;
  }

  #new_version #survey-accordion .uniqueId {
    width: 100%;
    margin: 10px auto !important;
    max-width: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #new_version .accordion-header .hand-icons .d-flex {
    display: flex;
    flex-direction: row;
    padding: 0;
  }

  .collapsed .que-title,
  .que-title {
    max-width: calc(100% - 100px);
    font-size: 12px;
  }
  #new_version #survey-accordion .accordion-item .accordion-button {
    padding: 10px 14px 10px;
    font-size: 14px;
  }

  #new_version .modal.fade.half-slide-modal .modal-dialog {
    text-align: left;
    width: 100%;
  }

  .rdrCalendarWrapper.rdrDateRangeWrapper {
    display: none;
  }

  #searchFilter {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
  }

  #new_version .admin-content-box #searchFilter .filterDropdownShow {
    margin: 0;
  }

  #new_version
    .admin-content-box
    #searchFilter
    .filtersubDropdownShow.selectedDateRange {
    transform: scale(1) translate(-67px, 82px);
  }

  .exportBtn {
    justify-content: space-between;
    display: flex;
    width: 100%;
  }

  #new_version
    .sub-question
    #innerAccordion
    .accordion-item
    .accordion-button::before,
  #new_version .card.options-sec .opt-no {
    background-size: 22px;
  }
  #new_version .card.options-sec .opt-no {
    min-width: 30px;
    height: 30px;
    margin: 0;
    background-position: 3px 4px;
    border-radius: 6px 6px 0 0;
  }

  #new_version label {
    margin: 0;
  }

  #new_version .card.options-sec textarea.form-control {
    height: 60px;
  }

  #new_version .sub-question #innerAccordion .accordion-item {
    border-radius: 4px;
  }

  #new_version
    .sub-question
    #innerAccordion
    .accordion-item
    .accordion-button.collapsed::after,
  #new_version
    .sub-question
    #innerAccordion
    .accordion-item
    .accordion-button:not(.collapsed)::after {
    background-size: 15px !important;
  }

  #new_version #survey-accordion .accordion-item .hand-icons {
    padding: 0 5px;
  }

  #new_version #survey-accordion .accordion-item .option-desc .hand-icons {
    margin: 5px 0 0;
  }

  #new_version .hand-icons img,
  .sm-icon img {
    max-width: 14px;
    max-height: 14px;
  }
  #new_version .sm-icon {
    width: 14px;
    height: 14px;
    margin: 0 6px 0 0;
  }

  #new_version .accordion-item .accordion-body form.p-4 {
    padding: 0.1rem 0.7rem !important;
  }
  .form-group label,
  #new_version .form-control.input-border {
    font-size: 12px;
  }
  .form-group label,
  #new_version .form-control.input-border {
    transform: scale(1);
  }
  #new_version .card.options-sec .card-body {
    padding: 10px 6px 12px;
  }
  #new_version .surveyAnalytics {
    display: flex;
    flex-direction: column;
    align-items: start !important;
    justify-content: start;
    gap: 10px;
  }

  #new_version .surveyAnalytics .bordered {
    padding: 5px 5px 5px 5px;
  }
  #new_version .surveyAnalytics .right-end {
    width: 100%;
  }
  #new_version .surveyAnalytics .right-end .bordered span {
    font-size: 12px;
  }
  #new_version .surveyAnalytics .bordered .blue-bg {
    font-size: 12px;
  }
  #new_version .admin-content-box h3 {
    font-size: 18px;
  }
  #new_version .admin-content-box > .p-6 {
    padding: 10px 10px !important;
  }
  .recharts-wrapper,
  .recharts-legend-wrapper,
  .recharts-wrapper svg {
    max-width: 100%;
    width: 100% !important;
  }
  .recharts-wrapper svg {
    width: 100%;
  }

  #new_version .recharts-legend-wrapper {
    top: 0 !important;
    left: 0 !important;
  }

  #new_version
    .recharts-legend-wrapper
    > .recharts-default-legend
    > li.recharts-legend-item {
    display: flex !important;
    align-items: center;
    justify-content: start;
    flex-direction: row-reverse;
  }

  #new_version
    .recharts-legend-wrapper
    > .recharts-default-legend
    > .recharts-legend-item-text {
    white-space: nowrap;
  }

  #new_version textarea {
    max-width: 100%;
  }

  #new_version hr {
    max-width: 100%;
    margin: 40px auto 0 !important;
  }
  #new_version .admin-content-box label {
    font-size: 14px;
    margin: 0 auto 10px;
  }
  .updateBtn {
    margin-bottom: 15px;
  }
  .qrCodeSec {
    display: inline-block;
    width: 100%;
    min-width: 100%;
    margin-bottom: 40px;
  }

  #new_version .admin-content-box #searchFilter .addButton {
    padding: 0 5px;
    max-width: none;
    width: 100%;
  }

  #new_version .admin-content-box button.download-csv {
    width: 100%;
  }

  #new_version .exportBtn {
    gap: 10px;
  }

  #new_version .admin-content-box label {
    text-align: left;
    width: 100%;
  }

  .exportBtn > div,
  .filterSec > div {
    flex: 1;
  }
  #new_version .card.options-sec {
    flex-direction: column;
  }

  #new_version button.navbarToggle,
  #new_version button.surveyIcon {
    position: fixed;
    left: 0;
    width: 50px;
    height: 50px;
    background: #000;
    border-radius: 0 6px 6px 0 !important;
    top: 20px;
    color: #fff;
    font-size: 30px;
    display: block;
    display: flex;
    justify-content: center;
    padding: 0;
    align-items: center;
  }
  #new_version .btn.btn-wht.px-2 {
    display: inline-block;
    width: auto;
  }

  #new_version .surveyList {
    overflow: visible;
  }

  #new_version button.surveyIcon {
    /* top: 120px;
    border-radius: 0 6px 6px 0 !important;
    right: auto;
    left: 0;
    background: #f85d18; */

    border-radius: 50px !important;
    /* right: auto; */
    left: auto;
    right: 35px;
    background: #f85d18;
    top: 90vh;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: 5px solid #fff !important;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
    /* box-shadow: 110px 0px 0px rgba(255,255,255, 0.7); */
  }

  #new_version .surveyOpen button.surveyIcon {
    /* left: 80%; */
    left: auto;
    right: 35px;
  }
  #new_version .admin-content-box ul.paginate {
    width: 100%;
  }

  #new_version .surveyList.surveyOpen {
    left: 0;
    z-index: 9999;
    position: fixed;
    box-shadow: 0px 0px 0px 100vw rgba(255, 255, 255, 0.7);
    border-right: 1px solid #c2c2c2;
    overflow: hidden;
  }
  #new_version .surveyList {
    left: -300px;
    z-index: 9999;
    position: fixed;
    transition: all 0.4s;
  }

  #new_version .surveyList h4 {
    text-align: left;
  }

  #new_version .surveyOpen button.surveyIcon span {
    font-size: 35px;
    line-height: normal;
    font-size: 0;
    width: 40px;
    height: 40px;
    top: -2px;
    margin: 0;
    position: relative;
    background: url(../images/cross-icon.svg) no-repeat center center / 30px;
  }

  #new_version button.surveyIcon:hover:before {
    content: "Survey List";
    position: absolute;
    left: -30px;
    right: 0;
    margin: 0 auto;
    top: -50px;
    background: #000;
    padding: 10px;
    width: 100px;
    font-size: 14px;
    text-align: center;
    border-radius: 6px;
  }

  #new_version button.navbarToggle span {
    font-size: 32px;
    line-height: 1.4;
  }
  .sidebar-new.show {
    max-width: 300px;
    /* left:0; */
  }

  .contentSec .form-group {
    margin: 10px 0px !important;
  }

  #new_version .contentSec form.flow-root {
    margin-bottom: 0 !important;
  }

  #searchFilter > .d-flex {
    flex-wrap: wrap;
  }
  #new_version .admin-content-box #searchFilter input[type="text"] {
    width: calc(100% - 30px);
  }

  .gap-y-2 {
    gap: 5px 0px;
  }

  #dashboardWrap #new_version .container {
    margin: 0 !important;
    min-height: 100vh;
  }

  #new_version .templates {
    min-width: 100%;
    width: 100%;
    height: 100%;
  }
  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .expand-details
    .expand-col1 {
    width: 100%;
    margin: 0 auto 10px;
  }

  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .appconfig
    .logo-setting {
    max-width: 100%;
    width: 100%;
    margin: 0;
  }

  #new_version #settings-content .billingContainer .planDetails,
  #new_version #settings-content .billingContainer .paymentDetails {
    width: 100%;
    margin: 0 auto 10px;
  }

  #new_version #settings-content .billingContainer .datedropdown {
    margin: 0;
    width: 100%;
    text-align: left;
  }

  #new_version
    .admin-content-box
    .wt-container-bxShadow
    .tableContainer
    + .d-flex {
    gap: 20px;
    flex-direction: column;
    align-items: start !important;
  }

  #new_version .admin-content-box ul.paginate li.previous,
  #new_version .admin-content-box ul.paginate li.next,
  #new_version .admin-content-box ul.paginate li.previous a,
  #new_version .admin-content-box ul.paginate li.next a {
    width: 40px !important;
    height: 40px !important;
    font-size: 0 !important;
  }
  #new_version .admin-content-box ul.paginate li.previous.disabled a,
  #new_version .admin-content-box ul.paginate li.next.disabled a {
    font-size: 0 !important;
  }

  #new_version .admin-content-box ul.paginate li.next.disabled a {
    background: url(../images/right-angle.svg) no-repeat center center;
  }
  #new_version .admin-content-box ul.paginate li.previous.disabled a {
    background: url(../images/left-angle1.svg) no-repeat center center;
  }

  #new_version .admin-content-box ul.paginate li.next a,
  #new_version .admin-content-box ul.paginate li.next a:hover {
    background: #f85d18 url(../images/right-angle-wht.svg) no-repeat center
      center;
  }
  #new_version .admin-content-box ul.paginate li.previous a {
    background: #f85d18 url(../images/left-angle.svg) no-repeat center center;
  }

  #new_version .admin-content-box ul.paginate li:nth-child(6),
  #new_version .admin-content-box ul.paginate li:nth-child(7),
  #new_version .admin-content-box ul.paginate li:nth-child(8) {
    display: none;
  }

  #new_version .admin-content-box .tableContainer table tr th,
  #new_version .admin-content-box .tableContainer table tr td,
  #new_version .fraudStatus .dropdown-toggle,
  #new_version .fraudStatus li {
    white-space: nowrap;
  }
  .search-bar-sec {
    position: relative;
  }
  #new_version .admin-content-box #searchFilter input[type="text"] {
    width: 100%;
  }

  #new_version .admin-content-box #searchFilter em.fas.fa-search {
    position: absolute;
    left: 10px;
    top: 14px;
  }

  #new_version .nav {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: auto;
  }
  #new_version #settings-content .profileContainer {
    padding: 26px 7px 26px 7px;
  }

  #new_version #settings-content .billingContainer #plan-payment-container {
    padding: 0 !important;
  }
  #new_version .billingContainer {
    padding: 15px 15px !important;
  }
  #new_version #settings-content .timeline {
    padding: 0;
    float: right;
  }

  .admin-content-box .surveyAssigned-list li {
    width: auto;
  }

  #searchFilter > div {
    gap: 10px;
  }

  #new_version .mainContainer .main-tab-container {
    overflow: hidden;
  }

  .css-10r1l7x-MuiFormControl-root,
  .MuiFormControl-root {
    min-width: 100%;
  }
  .primary-colors,
  .theme-app .white-bg,
  .survey-themes {
    width: 100%;
  }
  .theme-colors,
  .bg-color-theme {
    gap: 10px;
    width: 100%;
  }
  .sqr-color {
    width: calc(33% - 6px);
  }

  #new_version .justify-content-start.cred-container {
    flex-direction: column;
  }

  .twillio,
  .sendgrid,
  .twillio > div,
  .sendgrid > div {
    margin: 0 auto;
    width: calc(100% - 10px);
  }

  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .expand-details
    .expand-col1 {
    height: auto;
  }
  .previewSec.mobileVersion {
    /* max-width: 341px !important; */
    max-width: 440px !important;
  }
  #new_version .admin-content-box .tableContainer table tr th:nth-of-type(1),
  #new_version .admin-content-box .tableContainer table tr td:nth-of-type(1) {
    padding-left: 10px;
  }

  .MenuOpen .sidebar-new.show {
    /* box-shadow: 0 0 200px rgba(0,0,0,0.9); */
    box-shadow: 0vw 0px 0px 100vw rgba(255, 255, 255, 0.7);
    left: 0;
    top: 0;
    border-right: 1px solid #c2c2c2;
  }

  #new_version.MenuOpen button.navbarToggle {
    left: 300px !important;
    transition: all 0.4s;
    z-index: 9999;
  }

  #new_version .mainContainer.h-100 {
    min-height: 100vh !important;
    max-height: none;
  }

  #new_version .mainContainer.h-100 .no-data-sec svg {
    margin-bottom: 30px;
  }

  /* .nextPrev button.prev svg, .nextPrev button.next svg {
    width: 1.2rem;
    height: 1.2rem;
  } */

  .mobile-logo {
    display: block;
    text-align: center;
    justify-content: center;
    display: flex;
    padding: 20px 0;
  }

  #new_version #survey-accordion {
    margin-bottom: 100px !important;
  }
  #new_version .planListDetail {
    width: 100%;
  }

  .planDetailsSec {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }

  #new_version
    #settings-content
    .billingContainer
    .planDetails
    .row
    .lh-34.planDtl,
  #new_version
    #settings-content
    .billingContainer
    .planDetails
    .row
    .lh-34.upgrdPlan {
    margin: 0 !important;
    padding: 10px 0 0 !important;
  }

  #new_version #settings-content .billingContainer .planDetails a,
  #new_version #settings-content .billingContainer .upgrdPlan a,
  #new_version #settings-content .billingContainer a em {
    font-size: 13px !important;
  }

  .count-show {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 0 auto 10px;
  }
  .updateBtn {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 992px) {
  .LoginmainContainer {
    max-width: 51vw;
  }
  .previewSec.mobileVersion {
    max-height: calc(100vh - 230px);
  }
  #new_version .btn.btn-wht.px-2 {
    min-width: 120px;
    justify-content: center;
  }
  #new_version textarea {
    max-width: 100%;
    width: 100%;
  }
  #new_version .mobileVersion .underLineInput .textArea,
  #new_version .mobileVersion .underLineInput input.underInput {
    height: auto;
  }
  .question_container {
    padding: 5px 0;
    /* width: 20px;
    height: 20px;
    display: inline-block; */
  }
  /* #new_version label {
    padding: 0;
    width: 20px;
    height: 20px;
  } */
  .admin-content-box .surveyAssigned-list li {
    flex: 0 0 100%;
    display: flex;
    justify-content: start;
  }
  /* #new_version .admin-content-box .surveyAssigned-list li svg {
    width: auto;
    flex: auto;
    display: flex;
    justify-content: start;
  } */
}

@media screen and (max-width: 850px) and (min-width: 820px) {
  .previewSec.mobileVersion {
    max-width: 440px !important;
  }
  .mobileVersion .demo-container {
    border-radius: 0 0 50px 50px;
    overflow: hidden;
  }
  .sec-column {
    height: calc(100vh - 400px);
    height: calc(100vh - 450px);
  }
  /* .mobileVersion .LoginmainContainer {
    height: calc(100% - 90px);
  } */
  .mobileVersion .demo-container .webView {
    height: calc(100vh - 290px);
  }
  #new_version .qrcode {
    max-height: 200px;
    display: inline-block;
    width: 100%;
  }
  .qrCodeSec {
    max-height: 200px;
  }
  #new_version .qrcode svg {
    height: 200px !important;
  }
  .recharts-default-legend .recharts-legend-item {
    gap: 10px;
    justify-content: start;
  }
  .recharts-layer.recharts-pie {
    transform: scale(1) translate(60%, 5%);
  }
  .recharts-legend-item-text {
    white-space: nowrap;
    min-width: calc(100% - 50px);
  }
  .recharts-pie-labels .recharts-layer {
    transform: translate(-2%, 0);
  }
}
@media screen and (max-width: 819px) and (min-width: 768px) {
  .previewSec.mobileVersion {
    max-width: 400px !important;
  }
}
@media screen and (max-width: 767px) {
  .mobileVersion .mobile-device {
    display: none;
  }
  .previewSec.mobileVersion {
    height: calc(100vh - 226px);
  }

  .mobileVersion .demo-container .webView {
    height: calc(100vh - 296px);
  }
  .previewSec.mobileVersion {
    padding-top: 10px;
    border-radius: 30px;
    border: 0;
    /* box-shadow: 0 0 0px 15px rgba(0,0,0,0.03); */
    box-shadow: none;
  }
  #new_version .light-blue-btn {
    padding: 7px 7px;
  }
  #new_version
    .settingsContainer
    .accordion-item
    .accordion-body
    .appconfig
    .fileInput {
    width: calc(100% - 20px);
  }

  .config-content .MuiFormControl-root {
    min-width: 100%;
  }
  .packageDetail {
    overflow: auto;
    max-height: 63vh;
  }

  #new_version .MuiSelect-select,
  .MuiList-root.MuiMenu-list .MuiButtonBase-root.MuiListItem-root,
  .MuiList-root.MuiMenu-list li.MuiButtonBase-root {
    max-height: 40px !important;
  }
  #new_version .addSection-btn {
    padding: 0;
  }
  #new_version #settings-content .profileContainer {
    padding: 30px 10px !important;
    text-align: left;
  }
  #new_version .form-control.edited-text {
    font-size: 18px;
  }

  #new_version .coding-section {
    padding: 0 !important;
    margin: 10px auto !important;
  }

  #new_version .h-100.templateSec {
    height: 600px !important;
  }
  #new_version .tab-content .tab-pane {
    width: 100%;
  }
  #new_version .mailing-section {
    padding: 0 !important;
    margin: 30px auto;
  }
  #iframeTab.top-resolution .nav-tabs {
    display: none;
  }

  #iframeTab {
    padding: 10px 10px;
  }

  #new_version .submitUpdateButton {
    margin: 0;
    padding: 0 !important;
  }

  #new_version .log_section p {
    padding: 10px 0;
    margin: 0;
    border-bottom: 1px solid #f1f1f1;
    align-items: center;
    flex-direction: row !important;
  }
  .log_section > p > span:first-child {
    min-width: 40%;
  }

  #new_version .log_section p span.grey {
    min-width: 40%;
  }

  .download-btn-sec {
    flex-direction: row;
    justify-content: space-between;
  }
  .download-btn-sec {
    flex-direction: row-reverse;
  }

  #new_version .billingContainer .row .col-12 {
    margin: 0 !important;
    width: 100%;
  }
  #new_version .btn.btn-wht {
    white-space: nowrap;
  }
  .mobileVersion .LoginmainContainer {
    height: calc(100% - 0px);
  }
  .analyticsSec {
    overflow: auto;
  }

  .recharts-responsive-container {
    min-width: 600px;
    transform: scale(0.5) translate(-50%, 0px);
  }
  .analyticsSec {
    overflow: hidden;
  }
  .columnSec {
    overflow: hidden;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;
  }
  .columnSec .sm-text {
    position: sticky;
    left: 0;
  }
  /* .columnSec > div {
    width: 100%;
    overflow: auto;
  } */
  #new_version .recharts-legend-item svg.recharts-surface {
    flex: 0 0 20px;
  }
  #new_version
    .recharts-legend-wrapper
    > .recharts-default-legend
    > li.recharts-legend-item {
    justify-content: space-between;
  }
  .recharts-layer.recharts-pie {
    transform: scale(1.7) translate(4%, -2%);
  }

  .LoginmainContainer,
  .surveryTopBar .head-center {
    max-width: none;
  }
  #new_version .underLineInput .textArea {
    padding: 10px 14px;
  }
  .surveyContent.alignBottomCont .surveyData {
    height: calc(100vh - 370px);
  }
}

button.Mui-selected.MuiButtonBase-root.MuiPickersDay-root,
button.Mui-selected.MuiButtonBase-root.MuiPickersDay-root:hover,
.css-1hrukl9.Mui-selected {
  background: #f85d18 !important;
}
.css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root:hover,
.css-4ggly9:hover {
  background-color: rgb(210 68 25 / 4%);
}
button.MuiButton-text.MuiButton-textPrimary.MuiButtonBase-root,
.css-1ujsas3 {
  color: #f85d18;
}

.css-7lip4c,
.css-118whkv,
.css-2ujp1m,
.css-12ha4i7,
.css-d0vs79-MuiClockPointer-root,
.css-umzx0k-MuiClock-pin,
.css-eg3pzz-MuiClockPointer-thumb {
  background: #f85d18 !important;
}

.css-118whkv,
.css-2ujp1m,
.css-eg3pzz-MuiClockPointer-thumb,
.css-12t0dn4-MuiClockPointer-thumb {
  border: 16px solid #f85d18 !important;
}

/* .tag-cloud {
  width: 100%;
}

.tag-cloud > span.tag-cloud-tag {
  display: inline-block;
  width: auto;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13px !important;
  background: #fbfbfb;
} */

.columnSec .sm-text {
  text-align: left;
  font-size: 16px;
  width: 100%;
  margin-bottom: 10px;
}

.recharts-wrapper {
  /* transform: scale(0.75); */
  /* max-height: 300px; */
}
#new_version img,
#new_version svg {
  max-height: 100%;
}

.canvasjs-chart-container {
  display: inline-flex;
  width: 100%;
  min-width: 550px;
  height: 100%;
  position: relative;
}

a.canvasjs-chart-credit {
  display: none;
}

.uploadImageSec {
  position: relative;
}
.uploadImage {
  border: 2px dashed #ddd;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.uploadImage input {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.grey-logged {
  background: #f7f7f7;
  padding: 15px 20px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  color: #5b5b5b;
}

.grey-logged strong {
  color: #111111;
}
.grey-logged:before {
  content: "";
  background: #f85d18;
  display: inline-block;
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}

.timing {
  color: #828282;
  width: 100px;
  border-left: 1px solid #e0e0e0;
  padding-left: 20px;
  margin: -15px 0 -15px 10px;
  display: flex;
  align-items: center;
}
#new_version h5.sm-text {
  font-size: 1rem;
}

.left-columns {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
}

.left-columns p.sm-text {
  color: #828282;
  font-size: 14px !important;
}

.days-section .logged-details {
  position: relative;
}

/* .days-section .logged-details:before {
  content: "";
  position: absolute;
  border-left:1px solid #ddd;
  left: -30px;
  height: 100%;
  width: 1px;
} */

.days-section .logged-details:after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #000;
  border-radius: 50%;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translate(-35px, -50%);
}

.right-columns {
  position: relative;
}
.right-columns:before {
  content: "";
  position: absolute;
  border-left: 1px solid #ddd;
  left: -30px;
  height: calc(100% + 44px);
  width: 1px;
  top: -22px;
}

@media screen and (max-width: 565px) {
  .days-section {
    flex-direction: column;
  }

  .left-columns {
    margin-bottom: 30px;
  }

  .logged-details:before {
    content: "";
    border-left: 0;
    border-top: 1px solid #ddd;
    top: -17px;
    left: 0;
    height: 1px;
    width: 100%;
    display: inline-block;
    position: absolute;
  }

  .days-section .logged-details:after {
    left: 0%;
    top: -22px;
    transform: translate(0, 0%);
  }

  .days-section .logged-details {
    margin-bottom: 20px;
  }
  .right-columns:before {
    content: "";
    position: absolute;
    border-left: 1px solid #ddd;
    left: -20px;
    height: calc(100% + 74px);
    width: 1px;
    top: -70px;
  }
  .days-section .logged-details:last-child {
    margin-bottom: 0;
  }
}
