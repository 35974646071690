
html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    background-color: #EEEEEE;
    color: #3C4858;
    margin: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.5em;
    font-size: 14px;
}
body, h1, h2, h3, h4, h5, h6 {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1.5em;
}
blockquote footer:before, blockquote small:before {
    content: '\2014 \00A0';
}

h1 {
    font-size: 3em;
    line-height: 1.15em;
}

h2 {
    font-size: 2.6em;
}

h3 {
    font-size: 1.825em;
    line-height: 1.4em;
    margin: 20px 0 10px;
}

h4 {
    font-size: 1.3em;
    line-height: 1.4em;
    margin-top: 10px;
    margin-bottom: 10px;
}

h5 {
    font-size: 1.25em;
    line-height: 1.4em;
    margin-bottom: 15px;
}

h6 {
    font-size: 0.8em;
    text-transform: uppercase;
    font-weight: 500;
}

h4, h5, h6 {
    margin-top: 10px;
    margin-bottom: 10px;
}

p {
    margin: 0 0 10px;
}

blockquote p {
    font-style: italic;
}

a {
    color: #9c27b0;
    text-decoration: none;
}

a:hover, a:focus {
    color: #89229b;
    text-decoration: none;
}

* {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    :focus {
        outline: 0;
    }
}

a:focus, a:active,
button:active, button:focus, button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
    outline: 0 !important;
}

legend {
    margin-bottom: 20px;
    font-size: 21px;
    border-bottom: 0;
}

output {
    padding-top: 8px;
    font-size: 14px;
    line-height: 1.42857;
}

label {
    font-size: 14px;
    line-height: 1.42857;
    color: #AAAAAA;
    font-weight: 400;
}

footer {
    padding: 15px 0;
}

footer ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
}

footer ul li {
    display: inline-block;
}

footer ul li a {
    color: inherit;
    padding: 15px;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 3px;
    text-decoration: none;
    position: relative;
    display: block;
}

footer ul li a:hover {
    text-decoration: none;
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
    height: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

@media (max-width: 991px) {
    body,
    html {
        position: relative;
        overflow-x: hidden;
    }

    #bodyClick {
        height: 100%;
        width: 100%;
        position: fixed;
        opacity: 0;
        top: 0;
        left: auto;
        right: 260px;
        content: "";
        z-index: 9999;
        overflow-x: hidden;
    }
}
svg {
    touch-action: none;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
    font-weight: 400;
    line-height: 1;
    color: #777;
}

h4 small, h5 small, h6 small {
    font-size: 75%;
}

code {
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px;
}

code, kbd, pre, samp {
    font-family: Menlo,Monaco,Consolas,"Courier New",monospace;
}
img {
  vertical-align: middle;
  border: 0;
}
.bold{
    font-weight: bold !important;
}
.fw-4{
    font-weight: 400;
}
.pad-top-0{
    padding-top:0px !important
}
.mt-10{
    margin-top:10px !important
}
.m-0{
    margin:0px !important
}
.mt-5{
    margin-top:5px !important
}
.mt-20{
    margin-top:20px !important
}
.fw-5{
    font-weight:500 !important
}
.mb-0{
    margin-bottom:0px !important
}
.mb-3{
    margin-bottom:3px !important
}
.left{
    float:left;
}
.right{
    float:right
}
.center{
    text-align: center !important;
    z-index: 2;
}
.pt-10{
    padding-top:10px !important
}
.p-10{
    padding:10px !important;
}
.pb-15{
    padding-bottom:15px !important
}
.mt-35{
    margin-top:35px !important
}
.mw-32{
    min-width:32% !important
}
.mr-17{
    margin-right: 17px !important;
}
.f-14{
    font-size:14px !important;
}
.f-12{
    font-size:12px !important;
}
// .dark-grey{
//     color:#000000DE !important;
// }
.light-grey{
    color:#827f7e !important
}
.actv-txt{
    color: #1469b2 !important
}
.txt-right{
    text-align: right;
}
.txt-left{
    text-align:left;
}
.align-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}
.green{
    color:#0DAA36 !important
}
.orange{
    color:#FF9100 !important
}
.yellow{
    color:#F4B400 !important
}
.red{
    color:#F84F4F !important
}
.grey{
    color:#B4B4B4 !important
}
.dark-grey{
    color: #464444 !important
}
.p-25{
    padding-top:25px !important
}

/* Customize website's scrollbar like Mac OS*/

/* total width */
.scrollbar::-webkit-scrollbar {
    background-color:#fff;
    width:16px
  }
  
  /* background of the scrollbar except button or resizer */
  .scrollbar::-webkit-scrollbar-track {
    background-color:#fff
  }
  .scrollbar::-webkit-scrollbar-track:hover {
    background-color:#f4f4f4
  }
  
  /* scrollbar itself */
  .scrollbar::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border:5px solid #fff
  }
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background-color:#a0a0a5;
    border:4px solid #f4f4f4
  }
  
  /* set button(top and bottom of the scrollbar) */
  .scrollbar::-webkit-scrollbar-button {display:none}
  
  /* div box */
  .scrollbar {
    // height: 75vh;
    width: 25vw;
    min-width: 150px;
    // background: #fff;
    overflow-y: scroll}
  // .overflow{min-height: 100vh}

.pointer-none{
    pointer-events: none !important;
}
.mr-20{
    margin-right:20px !important
}
.pointer{
    cursor:pointer !important
}
.f-16{
    font-size:16px
}
.mt-30{
    margin-top:30px;
}
.ml-10{
    margin-left:10px;
}
.ml-5{
    margin-left:5px;
}
.primary{
    color:#1469b2;
}
.icon-grey{
    color:#707070
}
.fw-400{
    font-weight:400px !important;
}
.f-15{
    font-size:15px;
}
.word-wrap{
    word-wrap: break-word;
}
.p-200{
    padding: 200px 50px 200px 50px;
}
//TimeRange Picker css

.daterangepickerleft{
    .fromDateTimeContainer{
        .fromDateHourContainer{
            .input-group{
                padding-left:35px !important;
                input{
                    border: 1px solid #767676;
                    border-radius: 2px;
                    text-align: center;
                }
            }
            .multipleContentOnLine{
                select{
                    border-radius:2px;
                }
            }
        }
        .monthYearContainer{
            .multipleContentOnLine{
                select{
                    border-radius:2px; 
                }
            }
        }
    }
}