/*! =========================================================
 *
 Material Dashboard PRO React - v1.3.0 based on Material Dashboard PRO - V1.2.1
*
* =========================================================
*
* Copyright 2016 Creative Tim (https://www.creative-tim.com/product/material-dashboard-pro-react)
 *
 *                       _oo0oo_
 *                      o8888888o
 *                      88" . "88
 *                      (| -_- |)
 *                      0\  =  /0
 *                    ___/`---'\___
 *                  .' \|     |// '.
 *                 / \|||  :  |||// \
 *                / _||||| -:- |||||- \
 *               |   | \\  -  /// |   |
 *               | \_|  ''\---/''  |_/ |
 *               \  .-\__  '-'  ___/-. /
 *             ___'. .'  /--.--\  `. .'___
 *          ."" '<  `.___\_<|>_/___.' >' "".
 *         | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *         \  \ `_.   \_ __\ /__ _/   .-` /  /
 *     =====`-.____`.___ \_____/___.-`___.-'=====
 *                       `=---='
 *
 *     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *               Buddha Bless:  "No Bugs"
 *
 * ========================================================= */

// variables and mixins
@import "material-dashboard-pro-react/variables";
@import "material-dashboard-pro-react/mixins";
@import "material-dashboard-pro-react/shadows";

// plugin css
@import "material-dashboard-pro-react/plugins/plugin-nouislider";
@import "material-dashboard-pro-react/plugins/plugin-perfect-scrollbar";
@import "material-dashboard-pro-react/plugins/plugin-react-datetime";
@import "material-dashboard-pro-react/plugins/plugin-react-bootstrap-sweetalert";
@import "material-dashboard-pro-react/plugins/plugin-react-chartist";
@import "material-dashboard-pro-react/plugins/plugin-react-big-calendar";
@import "material-dashboard-pro-react/plugins/plugin-react-jvectormap";
@import "material-dashboard-pro-react/plugins/plugin-react-table";
@import "material-dashboard-pro-react/plugins/plugin-react-tagsinput";

// Core CSS
@import "material-dashboard-pro-react/misc";
@import "material-dashboard-pro-react/fileupload";

.none {
  display: none !important;
}

.loaderContainer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #f85d18;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1s linear infinite;
  position: absolute;
  left: 48%;
  top: 48%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.side_bar_logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 234px;
    max-width: none;
    // min-width: 234px;
  }
}

.main_error_pop {
  position: fixed;
  width: 100%;
  top: 30px;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  left: 0;
}

.snackbar_cus {
  width: 360px;
  padding: 15px;
  border-radius: 8px;
  background: green;
  color: #fff;
  text-align: center;
}

.snackbar_cus.error_cus {
  background: #f86c6c;
}

.customer-view-dialogBox {
  .customer-profile-picture {
    height: 130px;
    width: 130px;
  }

  .customer-profile-info {
    width: 70%;
    align-self: center;
    display: -webkit-inline-box;

    table {
      width: 100%;

      .td-key {
        text-align: left;
        font-weight: 500;
      }

      .td-value {
        text-align: right;
      }
    }
  }
}

.vendor-request-view-dialogBox {
  table {
    width: 100%;

    .td-key {
      text-align: left;
      font-weight: 500;
    }

    .td-value {
      text-align: left;
    }
  }
}

.dashboard {
  .tab-header {
    z-index: 1;
  }

  .restaurant-section {
    .view-all-restaurants {
      text-align: right;
      margin-bottom: 20px;
    }

    .restaurants-card-items {
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 1.14);
    }
  }

  .orders-section {
    .view-all-orders {
      text-align: right;
      margin-bottom: 20px;
    }

    .orders-card-items {
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 1.14);

      .order-status {
        font-size: 12px;
        padding: 4px;
        border-radius: 14px;
        box-shadow: 0 0px 4px 0 black;
      }

      .order-served {
        background: #47a34b;
      }

      .order-scanned {
        background: #f89613;
      }

      .order-dismised {
        background: #e8413d;
      }
    }
  }

  .summary-section {
    .view-all-summary {
      .summary-card-items {
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 1.14);
      }
    }
  }
}

.update-profile-dialog-box {
  .reset-password {
    text-align: left;
    padding: 7px 24px 20px 24px;
    font-weight: bold;
    margin-top: -13px;

    a {
      color: rgba(0, 0, 0, 0.87);
      cursor: pointer;
    }
  }
}

.manage-content {
  .tab-header {
    z-index: 1;
  }

  .upload-container {
    text-align: center;

    h4 {
      margin-bottom: 20px;
    }

    .view {
      display: inline-block;
      width: 100%;
      text-align: center;

      .thumbnail {
        // float: right;
        cursor: pointer;
      }
    }
  }

  .faq-container {
    .add-new-faq {
      float: right;
    }

    .faq-list {
      display: inline-block;
      margin-top: 20px;
      width: 100%;

      .collaps-header > div {
        display: inline-block;

        .question {
          float: left;
        }

        .action-icons {
          float: right;
          padding-right: 0px;
        }
      }
    }
  }
}

.add-faq-form {
  .add-faq-input-section {
    margin-top: 20px;
    background: #def4f7;
    padding: 21px !important;
    border-radius: 10px;
    box-shadow: 0 16px 38px -32px rgba(0, 0, 0, 0.56),
      0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  }

  .faq-text-editor {
    margin-top: 30px;
  }

  .action-buttons {
    text-align: right;
    margin-top: 20px;
  }
}

.add-offer-model {
  .image-upload-container {
    text-align: center;

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .image-upload {
        margin: 10px;
      }

      .add-more-image-button {
        margin-left: 10px;
      }
    }
  }

  .validity-date-picker {
    width: 55%;
    display: block;
    position: relative;
    margin-top: 15px;

    .date-range-picker-container {
      background: #ffffff;
      z-index: 9;
      position: absolute;
      box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
        0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    }

    .MuiFormControl-fullWidth {
      width: 180px !important;
    }

    button {
      svg .MuiSvgIcon-root {
        width: 17px !important;
      }
    }
  }

  .offer-code-section {
    margin-top: 15px;

    .offer-code-percentage {
      width: 200px;
    }
  }
}

.manage_callback_header {
  display: flex !important;
  align-items: center !important;

  .validity-date-picker {
    margin-right: 15px;

    // .MuiFormControl-fullWidth{
    //   width: 207px !important;
    // }
    label {
      font-size: 14px !important;
    }
  }
}

.manage_appointment_header {
  display: flex !important;
  align-items: center !important;
}

.manage-offers-header {
  margin-right: -5px !important;
  display: inline-block;

  // padding-top:39px;
  // margin-right: -18px;
  .validity-date-picker {
    // width: 204px;
    // margin-right: 2rem;
    display: -webkit-inline-box;
    position: relative;

    .date-range-picker-container {
      background: #ffffff;
      z-index: 9;
      position: absolute;
      box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
        0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    }
  }

  .shadow-sm {
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
      0 3px 1px -2px rgba(153, 153, 153, 0.2),
      0 1px 5px 0 rgba(153, 153, 153, 0.12);
  }

  .filter-by-offer-code {
    width: 200px;
    margin-right: 20px;

    &.reduce_width {
      width: 150px;
    }

    &.width_xs {
      width: 110px;
    }
  }

  .new_Card {
    display: -webkit-inline-box;
  }

  div[class^="CustomDropdown-pooperResponsive"] {
    top: 45px !important;
    width: 100% !important;
    transform: none !important;

    div#menu-list {
      min-width: inherit;
    }
  }
}

.manage-offers-header.manage-partner-header .validity-date-picker {
  .date-range-picker-container {
    left: -74px;
    top: 40px;
  }
}

.orders-header {
  display: inline-block;

  .select-executive {
    width: 200px;
    margin-right: 20px;
  }

  .new_Card {
    display: -webkit-inline-box;
  }
}

.executive-orders {
  .select-executive-message {
    text-align: center;
    padding: 20px;
  }
}

.add-product-form {
  .select-city-specific-price {
    margin-top: 20px;

    .actions {
      display: flex;

      .input-action-container {
        display: block;
        width: 90%;

        .city-specific-price-container {
          width: 100%;
          display: -webkit-box;
          vertical-align: middle;
          margin-top: 5px;
          background: #def4f7;
          padding: 10px;
          border-radius: 5px;

          .input-fields {
            width: 90%;
          }

          .remove-button-container {
            width: 10%;
            text-align: center;
            padding-top: 12px;
          }

          .action-icons {
            text-align: right;
          }
        }
      }

      .add-more-button-container {
        width: 10%;
        text-align: center;
        padding-top: 24px;
      }
    }
  }
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, -50px, 0);
    transform: translate3d(0, -50px, 0);
    visibility: visible;
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.slideInUp > .picky__dropdown {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  animation-duration: 0.35s;
}

// MANAGE CITY MODULE

.manageCityContainer {
  .rt-th,
  .rt-td {
    text-align: center !important;
  }
}

//CUSTOM CITY DROPDOWN
.city-dropDown {
  position: absolute;
  -webkit-box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.8);
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.4);
  left: 0;
  top: 62px;
  width: 100%;
  border-radius: 7px;
  padding: 0.8rem;
  overflow-y: scroll;
}

.city-dropDown .list {
  min-height: 200px;
  max-height: 200px;
  overflow-y: scroll;
  height: 100%;
}

.city-dropDown .item {
  padding-left: 2.65rem;
}

//COMMON
.ml-3 {
  margin-left: 2rem;
}

// Manage Payment
.payment-detail-page {
  .payment-status {
    font-size: 17px;
    padding: 5px;
    border-radius: 14px;
    -webkit-box-shadow: 0 0px 4px 0 black;
    box-shadow: 0 0px 4px 0 black;
    width: 85px;
    display: inline-block;
  }

  .payment-status.pending {
    background-color: #e8413d;
  }

  .payment-status.partial {
    background-color: #f89613;
  }

  .payment-status.paid {
    background-color: #47a34b;
  }
}

.payment-detail-header {
  .new_Card {
    display: -webkit-inline-box;
  }
}

.add-category-form {
  .select-sub-category {
    border: 1px solid #e4e2e2 !important;

    .picky__dropdown {
      display: contents;
    }
  }

  .email-property-header {
    font-weight: bold;
    font-size: 22px;

    small {
      display: block;
      text-transform: capitalize;
      font-size: 15px;
      margin-top: 8px;
    }
  }

  .property-emails {
    margin-top: 10px;
  }
}

.restaurant-details {
  .rest-id {
    label {
      color: #000000;
    }
  }
}

.product-pricing {
  .select-container {
    margin-right: 30px;
    margin-left: 30px;
    display: flex;
  }

  .revert-button {
    display: flow-root;
    margin-bottom: 12px;
  }

  .product-pricing-table {
    .rt-thead {
      background-color: #def4f7;
    }
  }

  .select-city-partner-message {
    text-align: center;
    padding: 20px;
  }
}

.manage-banner {
  .tab-header {
    z-index: 1;
  }
}

.notification-list-box {
  .list-container {
    max-height: 400px;
    overflow: auto;
  }
}

.send-notification-form {
  .recipient-drop-down {
    margin-top: 10px;

    .select-recipient {
      border: 1px solid #e4e2e2 !important;

      .picky__dropdown {
        display: contents;
      }
    }
  }
}

.date-of-payment-by-admin {
  margin-left: 10px !important;
  height: 25px !important;
  border: aliceblue;
  border-bottom: 1px solid #00000078;
  font-size: 14px;
  color: #000000bd;
}

.sync-fusion-editor-data {
  table {
    border-collapse: collapse;

    td,
    thead {
      border: solid 1px black;
    }

    th {
      padding: 5px;
      border: solid 1px black;
    }
  }
}

.view_section {
  > p {
    margin: 20px 0;
    font-size: 16px;
    display: flex;

    > span {
      &:first-child {
        max-width: 40%;
        min-width: 250px;
        color: #675e5e;
      }

      &:last-child {
        width: 60%;
        font-weight: 400;
        color: #3d3d3d;
      }
    }
  }
}

.color_theme {
  button {
    color: #f85d18;
  }
}

.iframe_chatbot {
  height: 1140px;
  overflow: hidden;
}

.iframe_residential {
  height: 1470px;
  overflow: hidden;
}

.iframe_commercial {
  height: 1194px;
  overflow: hidden;
}

.DateRangePicker {
  margin-bottom: 17px !important;
  margin-top: 17px !important;
  padding: 0;
}

.DateRangePicker__PaginationArrow--previous {
  left: 4px !important;
}

.DateRangePicker__PaginationArrow--next {
  right: 6px !important;
}

.DateRangePicker__CalendarSelection--is-pending,
.DateRangePicker__CalendarSelection {
  background-color: #f85d18 !important;
  border: 1px solid #f85d18 !important;
}

// .manage_appointment_header {
//   input[type=text]:focus:not([readonly])+label {
//     color: #c8102e;
//   }

//   input[type=text]:focus:not([readonly]) {
//     border-bottom: 1px solid #c8102e;
//     box-shadow: 0 1px 0 0 #c8102e;
//   }
// }
.MuiSelect-root,
.MuiSelect-select,
.MuiSelect-filled,
.MuiInput-root,
.MuiInput-formControl,
.Mui-focused {
  font-size: 14px !important;
}

.select_full_width {
  width: 100%;
}

.select__multi-value {
  background-color: rgba(200, 16, 46, 0.2) !important;
}

.select__menu {
  z-index: 11111 !important;
  position: relative !important;

  div {
    z-index: 11111 !important;
    position: relative !important;
  }
}

div[class*="MuiListItemText"] {
  font-size: 14px !important;
  font-weight: 400;

  @media (max-width: 1440px) {
    font-size: 13px !important;
  }
}

.slot_table {
  width: 100%;
  overflow: auto;
  padding-bottom: 5px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3) !important;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 2px !important;
    height: 7px !important;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    table-layout: fixed;

    th {
      width: 85px;
      height: 50px;
      border: 1px solid #ccc;
    }

    .projects-header {
      width: 145px;
    }

    td {
      width: 120px;
      height: 50px;
      border: 1px solid #ccc;
      cursor: pointer;
      padding: 0;

      .slotcheckbox {
        width: 100%;
        height: 100%;
        text-align: center;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.blocked {
          background-color: RGB(238 244 245);
        }

        &.active {
          background-color: rgba(200, 16, 46, 0.2);
        }

        &.slot-filled {
          background-color: RGB(238 244 245);
        }
      }
    }
  }
}

.theme_color {
  color: #f85d18 !important;
}

.manage_slot_header {
  display: flex;
  align-items: center;

  .slot_slider {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
  }
}

.custom_popup_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 9999;
  left: 0;

  .custom_popup {
    position: relative;
    background-color: #fff;
    padding: 24px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .custom_btns {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .cancel-button {
      position: absolute;
      top: 0;
      right: 0;
      width: 10px;
      height: 10px;
      padding: 10px;
      border-radius: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
      background-color: #b3b3b3;
      border: 0.5px solid #b3b3b3;
      color: #fff;
      margin: 5px;
    }

    .slot-count-input {
      padding: 8px;
      margin-bottom: 5px;
      border-radius: 5px;
    }

    .popup-header {
      display: block;
      width: max-content;
      font-size: 16px;
      font-weight: bold;
      margin: 10px;
      text-align: center;

      small {
        display: block;
        width: fit-content;
        color: rgba(31, 31, 31, 0.788);
        font-weight: 100;
      }
    }
  }
}

// .custom_date_picker{
//   position: relative;
//   .react-datepicker__input-container{
//     >input{
//       position: absolute;
//       opacity: 0;
//     }
//   }
// }
.update_btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.custom_date_picker .react-datepicker__close-icon::after {
  background-color: rgba(200, 16, 46, 1);
  font-size: 16px;
  height: 20px;
  width: 20px;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__day--keyboard-selected {
  background-color: rgba(200, 16, 46, 1);
  font-weight: 500;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__day--keyboard-selected:hover {
  background-color: rgba(200, 16, 46, 1);
  font-weight: 500;
}

.query-selection-grid {
  position: absolute;
  margin-top: 10px;
  width: 100%;
  z-index: 9999;
}

.GridItem-grid-388.query_selection_grid,
.GridItem-grid-484.query_selection_grid,
.GridItem-grid-495.query_selection_grid {
  padding: 0px !important;
}

.query_selection_grid {
  margin-bottom: 10px !important;
}

.audience-query-card {
  overflow: visible !important;
}

.query-list {
  border-right: 0.5px solid rgba(0, 0, 0, 0.3) !important;
}

.query-options-list {
  max-height: 200px !important;
  overflow-y: auto !important;
}

.query-opertor {
  margin-top: 5px;

  .query-opertor-button {
    margin-right: 10px !important;
  }
}

.audience-grid {
  margin-bottom: 10px !important;
}

.selected-query-operator {
  width: min-content;
  font-size: 15px;
  font-weight: bold;
  color: rgb(139, 139, 139);

  &::after,
  &::before {
    content: "";
    width: 0.5px;
    height: 15px;
    display: block;
    border-left: 2.5px dotted rgb(139, 139, 139);
    border-spacing: 5px;
    margin: 0 auto;
  }
}

.manage_slot_header_text {
  width: max-content !important;
  float: left !important;
  display: flex !important;
  justify-content: center !important;
}

.slot_header_small {
  font-size: 11px;
  margin-left: 10px;
  margin-top: 9px;
}

.inline_slot_slider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  float: right;
  margin-top: 5px;
}

.sweet-alert-button {
  color: #ffffff;
  border: none;
  margin: 0.3125rem 1px;
  cursor: pointer;
  padding: 12px 30px;
  position: relative;
  min-width: auto;
  font-size: 12px;
  min-height: auto;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;
  font-weight: 400;
  will-change: box-shadow, transform;
  line-height: 1.42857143;
  white-space: nowrap;
  touch-action: manipulation;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 0;
  vertical-align: middle;
  background-color: #f85d18;
}

.sweet-alert {
  h2 {
    font-size: 1.6em;
  }
}

.action-center-icon .rt-resizable-header-content {
  text-align: center;
}

.comment-modal-root {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #2b2b2b3b;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;

  .comment-modal-inner {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    position: relative;
    min-width: auto;
    font-size: 12px;
    min-height: auto;
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
      0 3px 1px -2px rgba(153, 153, 153, 0.2),
      0 1px 5px 0 rgba(153, 153, 153, 0.12);
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
      background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    text-align: center;
    font-weight: 400;
    will-change: box-shadow, transform;
    line-height: 1.42857143;
    white-space: nowrap;
    touch-action: manipulation;
    border-radius: 3px;
    text-transform: uppercase;
    letter-spacing: 0;
    vertical-align: middle;
    background-color: #f85d18;
  }

  .sweet-alert {
    h2 {
      font-size: 1.6em;
    }
  }

  .action-center-icon .rt-resizable-header-content {
    text-align: center;
  }

  .comment-modal-root {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #2b2b2b3b;
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;

    .comment-modal-inner {
      background-color: #fff;
      padding: 10px;
      border-radius: 5px;
      position: relative;
      width: 500px;

      .modal-header {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0px;
      }

      .modal-close-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: red;
        border-radius: 100%;
        color: #fff;
        cursor: pointer;
        width: 15px;
        height: 15px;
        font-size: 15px;
        position: absolute;
        top: 5px;
        right: 5px;
      }

      .modal-body {
        padding: 5px;
      }
    }
  }

  .download-csv-button {
    background-color: #f85d18 !important;
  }

  .manage-property-header-wrap {
    display: flex;
    flex-direction: row;
    align-items: cenetr;
    justify-content: center;
    margin-right: 20px;

    h5 {
      font-weight: bold;
      margin-right: 5px;
    }

    .modal-close-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: red;
      border-radius: 100%;
      color: #fff;
      cursor: pointer;
      width: 15px;
      height: 15px;
      font-size: 15px;
      position: absolute;
      top: 5px;
      right: 5px;
    }

    .modal-body {
      padding: 5px;
    }
  }
}

.download-csv-button {
  background-color: #f85d18 !important;
}

.manage-property-header-wrap {
  display: flex;
  flex-direction: row;
  align-items: cenetr;
  justify-content: center;
  margin-right: 20px;

  h5 {
    font-weight: bold;
    margin-right: 5px;
  }
}

.iframe_wrap {
  height: 70vh;
  overflow: hidden;

  iframe {
    border: 0;
    width: 100%;
    height: 74vh;
    top: 0;
    left: 0;
  }
}

.swipable-metrics .MuiTypography-root {
  padding: 0px !important;
}

.analytics-wrap {
  margin-bottom: 0px !important;
  height: 80vh;
}

.analytics-button-wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
}

.iframe-viewer-wrap {
  height: 130vh;
  overflow: hidden;
  background-image: url("../img/Eclipse.gif");
  background-position-x: center;
  background-position-y: 250px;
  background-repeat: no-repeat;
  margin: 25px;

  iframe {
    border: 0;
    width: 100%;
    overflow: hidden;
    height: 690vh;
  }

  &.survey_analytics_wrap {
    height: 2100px;
    overflow: hidden;

    @media (max-width: 1680px) {
      height: 2100px;
    }

    @media (max-width: 1600px) {
      height: 2100px;
    }

    @media (max-width: 1400px) {
      height: 2100px;
    }

    @media (max-width: 1024px) {
      height: 3400px;
    }

    .survey_analytics {
      height: 2100px;

      @media (max-width: 1024px) {
        height: 3800px;
      }
    }
  }

  &.fraud_metrics_wrap {
    height: 400vh;

    .fraud_metrics {
      height: 650vh;
    }
  }

  &.demography_analytics_warp {
    height: 637vh;

    .demography_analytics {
      height: 650vh;
    }
  }
}

@media only screen and (max-height: 230px) {
  #{if(&, '&.survey_analytics_wrap','.survey_analytics_wrap')} {
    height: 150vh;

    .survey_analytics {
      height: 650vh;
    }
  }
}

@media only screen and (min-width: 1040px) and (max-width: 1300px) {
  #{if(&, '&.survey_analytics_wrap','.survey_analytics_wrap')} {
    height: 450vh;

    .survey_analytics {
      height: 750vh;
    }
  }
}

//  @media only screen and (max-height: 900px){
//   .iframe-viewer-wrap{
//     height:90vh;
//     iframe{
//       height: 185vh;
//     }
//     &.fraud_metrics_wrap{
//       height:200vh
//     }
//     // .fraud_metrics{
//     //   height: 150vh;
//     // }
//     .demography_analytics_warp{
//       height:450vh;
//     }
//   }
//  }

//  @media only screen and (max-height: 620px){
//   .iframe-viewer-wrap{
//     height: 120vh;
//     iframe{
//       height: 180vh;
//     }
//     &.fraud_metrics_wrap{
//       height:160vh
//     }
//     &.survey_analytics_wrap{
//       height: 165vh;
//       .survey_analytics{
//         height: 650vh;
//       }
//     }
//     // .fraud_metrics{
//     //   height: 170vh;
//     // }
//     .demography_analytics_warp{
//       height:300vh;
//     }
//   }
//  }

.Dashboard-content-284 {
  margin-top: 20px !important;
}

//  .Dashboard-content-3, .jss3{
//    margin-top: 35px !important;
//    padding: 15px !important;
//  }

.dashboard-footer {
  padding: 0px !important;
}

.add-user-wrap {
  margin-top: 30px;
}

.module-details-table {
  margin: 0 50px;
  width: calc(100% - 100px);
  float: left;
}

.module-details-table-header {
  p {
    font-size: 20px;
  }
}

.appointment-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.align-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 5px;
  margin-top: 10px;
}

.appointment_export_block {
  display: flex;
  margin-right: -3px;

  div {
    div {
      width: 148px !important;

      div {
        ul {
          li {
            padding: 7px !important;
            margin: 0 0;
          }
        }
      }
    }
  }

  .create-appointment-button,
  .download-csv-button {
    cursor: pointer;
    background-color: #f85d18 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    color: #fff;
    padding: 8px;
    border-radius: 5px;
    margin-right: 8px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    svg {
      margin: 0px;
    }
  }
}

.module_table_header small {
  color: #949494de;
}

.dashboard-header {
  width: 93%;
  justify-content: flex-end;
  display: flex;
}

.main-transcript-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.transcript-table {
  width: 60%;
}

.transcript-window {
  width: 35%;
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.1);
  height: 68vh;
  overflow: hidden;
  position: relative;
}

.conversation_wrapper {
  height: 80vh;
  overflow: auto;
}

.navbar-link,
a[class^="Sidebar-collapseItemLink"],
.main-block-header {
  display: flex !important;
}

.surveys-select {
  color: #f85d18;
  width: 94%;
  border-right: 3px solid #f85d18;
}

.slot-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.main-block-card-icon {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_header {
  background-color: #f85d18;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 50px;
}

.transcript-session-table {
  .-pageInfo {
    width: 30px;
  }

  .rt-table {
    height: 63vh;
    width: 100%;

    .rt-tbody {
      flex: 1 0 auto !important;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .iframe_chatbot {
    height: 1725px;
    overflow: hidden;

    iframe {
      height: 2312px !important;
    }
  }

  .iframe_residential {
    height: 2268px;
    overflow: hidden;

    iframe {
      height: 2312px !important;
    }
  }

  .iframe_commercial {
    height: 1794px;
    overflow: hidden;

    iframe {
      height: 2312px !important;
    }
  }
}

.view-session-button {
  opacity: 0.5;

  &.active-session {
    opacity: 1;
  }
}

//Number Input css
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.logo-text {
  padding: 3px 3px 3px 31px;
  font-weight: bold;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #f85d18 !important;

  &:hover {
    background-color: RGB(238 244 245) !important;
  }
}

.cus-toggle {
  .MuiSwitch-colorSecondary.Mui-checked {
    color: #f85d18 !important;
  }
}

@media (min-width: 600px) {
  .MuiToolbar-gutters {
    padding-left: 15px !important;
  }
}

.ReactTable .pagination-bottom {
  margin-top: 15px;
}

.text-center {
  text-align: center !important;
}

.otp-field {
  width: 15% !important;
  margin-right: 5px !important;

  input {
    text-align: center !important;
  }
}

.fileinput .thumbnail {
  box-shadow: none !important;
}

.fssai-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 125px;

  .accepted-file-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    span {
      padding: 5px 0px;
      font-weight: bold;
      font-size: 20px;
    }
  }

  .file-error-block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.file-icon {
  border: 1px solid rgba(0, 0, 0, 0.288);
  border-radius: 5px;
  padding: 20px;
  position: relative;

  #delete-icon {
    content: "✕";
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 100px;
    top: -5px;
    right: -5px;
    background-color: rgb(243, 61, 61);
    height: 20px;
    width: 20px;
  }
}

.file-dropzone {
  border: 2px dashed #949494;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  cursor: pointer;

  div {
    padding-top: 5px;
    align-items: center;
    text-align: center;
    width: auto;
  }
}

.button-block {
  // margin-top: 10px;
  // display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-evenly;

  > p {
    margin-top: 0px;
    padding-top: 10px;
  }
}

.browse-container {
  padding-top: 27px !important;
}

.thumb {
  display: inline-flex;
  border-radius: 2;
  border: 1px solid #eaeaea;
  margin-bottom: 8;
  margin-right: 8;
  width: 100;
  height: 100;
  padding: 4;
  box-sizing: border-box;
}

.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

img {
  display: block;
  width: auto;
  height: 100%;
}

.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16;
}

.info_section {
  > div {
    margin: 20px 0;
    font-size: 14px;
    display: flex;

    > span {
      &:first-child {
        max-width: 40%;
        min-width: 500px;
        color: #757575;
        text-align: left;
      }

      &:last-child {
        width: 60%;
        font-weight: 400;
        color: #3d3d3d;
        text-align: left;
      }
    }
  }
}

.log_section {
  > p {
    margin: 20px 0;
    font-size: 14px;
    display: flex;

    > span {
      &:first-child {
        // max-width: 30%;
        min-width: 280px;
        color: #757575;
        text-align: left;
      }

      &:last-child {
        width: 70%;
        font-weight: 400;
        color: #3d3d3d;
        text-align: left;
        word-break: break-word;
      }
    }
  }
}

.sub-list {
  padding-left: 30px;
  font-weight: normal;
}

.subview-header {
  width: 90%;
  display: inline-block;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

// ================ Page Loader   ================

.loader-for-page-div {
  margin-top: 50%;
  transform: translateY(-50%);
}

.loader-for-page {
  position: relative;
  width: 65px;
  height: 40px;
  margin: auto;
  margin-bottom: 45px;
}

.loader-for-page span {
  position: absolute;
  display: block;
  bottom: 0;
  width: 9px;
  height: 5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-animation: loader-for-page 2s infinite ease-in-out;
  animation: loader-for-page 2s infinite ease-in-out;
}

.loader-for-page span:nth-child(2) {
  left: 11px;
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}

.loader-for-page span:nth-child(3) {
  left: 22px;
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}

.loader-for-page span:nth-child(4) {
  left: 33px;
  -webkit-animation-delay: 600ms;
  animation-delay: 600ms;
}

.loader-for-page span:nth-child(5) {
  left: 44px;
  -webkit-animation-delay: 800ms;
  animation-delay: 800ms;
}

.loader-for-page span:nth-child(6) {
  left: 55px;
  -webkit-animation-delay: 1000ms;
  animation-delay: 1000ms;
}

@-webkit-keyframes loader-for-page {
  0% {
    height: 5px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background: rgba(0, 0, 0, 0.1);
  }

  25% {
    height: 30px;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
    background: #3498db;
  }

  50%,
  100% {
    height: 5px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background: rgba(0, 0, 0, 0.1);
  }
}

@keyframes loader-for-page {
  0% {
    height: 5px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background: rgba(0, 0, 0, 0);
  }

  25% {
    height: 30px;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
    background: #f85d18;
  }

  50%,
  100% {
    height: 5px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background: rgba(0, 0, 0, 0);
  }
}

.surveyPageLoader {
  position: fixed;
  background: rgba(128, 128, 128, 0);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ================================= */

.browse-txt {
  color: #f85d18;
  cursor: pointer;
  font-weight: 500;
}

.browse-text {
  color: #f85d18;
  cursor: pointer;
  font-weight: 500;
  margin-top: 10px;
}

.dropzone-container {
  padding: 20px;
  background-color: #f6f6f6;
  border-radius: 10px;
}

.dropzone-label {
  font-size: 14px !important;
  font-weight: bold !important;
  padding-bottom: 10px !important;
  color: #646464 !important;
}

.note-des {
  color: #757575;
}

.note-title {
  color: #646464;
}

.editor-label {
  font-size: 12px !important;
  margin-top: 30px !important;
}

.lang-label {
  margin: 0 0 10px;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 40px !important;
}

.accordion-btn {
  height: 36px !important;
  font-size: 14px !important;
  text-transform: none !important;
  box-shadow: none !important;
  width: 89px !important;
}

.acc-actions {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.acc-root {
  box-shadow: none !important;
}

// custom sidebar survey options //
.sel-btn {
  box-shadow: none !important;
  width: 230px !important;

  span {
    display: inline !important;
    justify-content: normal !important;
    text-align: justify !important;
    font-size: 13px !important;

    b {
      float: right !important;
      margin-top: 9px !important;
    }
  }
}

.sidePopper {
  width: 223px;
  margin-top: 10px;
}

.survey_list {
  border-left: 2px solid #ccc !important;
  margin-left: 38px !important;
  margin-top: 5px !important;
}

.selected-route {
  border-radius: 3px;
  border-right: 3px solid #f85d18 !important;
}

.sidebar_menu_profile {
  .MuiListItem-gutters {
    padding-left: 0px !important;
  }

  .Sidebar-itemLink-27 {
    width: 90% !important;
    cursor: pointer;
  }
}

.sur-icon {
  height: 24px;
}

.wd20 {
  width: 20px;
  height: 20px;
}

//===================//

.lng-txt {
  padding-right: 7px;
}

.lng-sel {
  margin-top: -5px !important;

  .MuiInput-underline &:before &:hover {
    border-bottom: none !important;
  }
}

.lng-sel-drop {
  .MuiInput-underline:before {
    border-bottom: none !important;
  }

  select {
    color: #f85d18 !important;
  }

  svg {
    color: #f85d18 !important;
  }
}

.cus-pad-crd {
  padding: 0px 28px 10px 28px !important;
}

.dropbox-inst {
  color: #656565 !important;
}

.quest-icon {
  height: 20px;
}

.pub-txt {
  margin-right: -30px !important;
}

// button.MuiButtonBase-root.MuiButton-root.MuiButton-text.RegularButton-button-98.RegularButton-rose-105.header-dropdown {
//   width: 140px;
// }
.single-letter-block {
  width: 60px;
}

.cus-picker-input {
  background-color: #fff !important;
  padding: 5px !important;
  width: 200px;
  border-radius: 3px !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;

  .MuiInput-underline:before {
    border-bottom: none !important;
  }

  .Mui-focused:before {
    border-bottom: none !important;
  }

  .MuiInputAdornment-positionStart {
    margin-right: 0px;
    margin-left: -10px;
  }

  .MuiInputBase-input {
    margin-left: -8px;
    font-size: 12px;
  }
}

.responses-header {
  margin-right: 4px !important;

  form.MuiPaper-root {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3) !important;
  }

  .validity-date-picker {
    display: -webkit-inline-box;
    position: relative;
    margin-right: 8px;
  }
}

.pub-container {
  margin-top: -14px;
  padding-left: 137px;
}

.app-head {
  font-size: 17px !important;
  float: right;
  padding: 10px 0px 0px 5px !important;
}

.head-width {
  width: 125% !important;
}

.fraud-dropdown {
  button {
    width: 110px !important;
  }

  div {
    div {
      min-width: 114px !important;
    }
  }

  li {
    padding: 0px !important;
  }
}

.action-dropdown {
  button {
    width: 78px !important;
  }

  div {
    div {
      min-width: 80px !important;
    }
  }

  li {
    padding: 4px !important;
  }
}

.data-dropdown {
  button {
    width: 153px !important;
  }

  div {
    div {
      width: 155px !important;
    }
  }

  li {
    padding: 1px !important;
  }
}

.inc-dropdown {
  button {
    width: 137px !important;
  }

  div {
    div {
      width: 145px !important;
    }
  }

  li {
    padding: 0px !important;
  }
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.fraud-select {
  &::before {
    border-bottom: none !important;
  }

  select {
    font-weight: bold !important;
  }
}

.disable-editor {
  div {
    .cke_inner {
      pointer-events: none;
    }
  }
}

.incentive-dropdown {
  button {
    width: 126px !important;
  }

  div {
    div {
      min-width: 120px !important;
    }
  }

  li {
    padding: 4px !important;
  }
}

.lock-icon {
  height: 20px !important;
  margin-left: 10px;
}

.empty-div {
  width: 32px;
}

@media only screen and (max-width: 1280px) {
  .mt-xs {
    margin-top: 20px !important;
  }

  .pub-container {
    margin-top: -14px;
    padding-left: 94px;
  }

  .mw-32 {
    min-width: 47.5% !important;
  }
}

@media only screen and (max-width: 1100px) {
  .mw-32 {
    min-width: 100% !important;
  }

  .mt-sm {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 1230px) {
  .pub-container {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 1170px) {
  .head-width {
    width: 136% !important;
  }
}

#dashboardWrap {
  #dashboardInnerWrap {
    padding: 0;

    .MuiPaper-root {
      background-color: transparent;
      box-shadow: none;
      margin-top: 0;

      .survey-title-sec {
        display: flex;
        justify-content: flex-end;
        gap: 5px;
      }

      .self_surve_buttons {
        padding: 0 20px;
      }

      .main-section {
        .MuiButtonBase-root.summary-sec {
          background-color: #fff;

          .MuiAccordionSummary-expandIcon {
            font-size: 14px;
            line-height: 16px;
            color: #ffffff;
            position: absolute;
            top: -44px;
            right: 25px;
          }

          .MuiAccordionSummary-expandIcon.Mui-expanded {
            transform: none;
          }

          // border-left: 5px solid #000000;
          // border-radius: 5px 0px 5px 5px;
          // border-top: 5px solid #f85d18;
          // border-width: 0px 1px 1px 1px;
          // border-style: solid;
          // border-color: #f85d18;
          // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          // border-radius: 0px 0px 8px 8px;

          .MuiAccordionSummary-content {
            margin: 30px 10px;
          }

          form.MuiBox-root.form-box {
            // width: 100%;
            display: flex;
            justify-content: space-between;

            .MuiIconButton-sizeSmall.MuiIconButton-edgeEnd {
              .MuiIconButton-label {
                justify-content: end;
              }
            }

            .notePlaceholder {
              // flex-basis: 50%;

              .MuiTextField-root {
                width: 35%;
              }
            }
          }

          .hand-icons {
            position: absolute;
            top: -50px;
            right: 68px;
            // border-right: 1px solid #fff;
            padding-right: 5px;
            line-height: 40px;
            color: #fff;
            height: 40px;
            margin: 5px 0;
            z-index: 9;

            button {
              margin-top: -2px;
            }
          }

          // span.MuiIconButton-label {
          //   &:hover {
          //     filter: drop-shadow(0px 0px 10px #9b9a9a) drop-shadow(0px 0px 10px #9b9a9a) drop-shadow(0px 0px 10px #9b9a9a);
          //   }
          // }
        }

        .MuiButtonBase-root.summary-sec.active-sec {
          border-top: 0px;
        }

        .MuiButtonBase-root.summary-sec.Mui-expanded {
          border-left: 0px;
        }

        .summary-sec.Mui-expanded {
          .MuiPaper-root.MuiAccordion-root.acc-root.Mui-expanded {
            .MuiAccordionDetails-root.accord-detail {
              border-left: 5px solid #000000;
              border-top: 1px solid #d8d8d8;
              border-bottom: 1px solid #d8d8d8;
              border-right: 1px solid #d8d8d8;
              border-radius: 5px;
              padding: 0;
            }
          }
        }

        .question-section-detail {
          background: #fff;
          // border-width: 0px 1px 1px 1px;
          // border-style: solid;
          // border-color: #f85d18;
          // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 0px 0px 8px 8px;

          // margin-top: 10px;
          .acc-root {
            .MuiCollapse-root {
              // padding-left: 5%;
              padding-left: 60px;
              background: #fff;
              margin-left: 10px;
              background: #fff;
              width: calc(100% - 10px);
            }

            .MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded {
              background-color: #fff !important;

              // padding: 30px !important;
              // border-left: 5px solid orange;
              .MuiCardHeader-content {
                padding: 30px;
              }
            }

            .MuiButtonBase-root.MuiAccordionSummary-root.acc-summary {
              // background: #fff;
              border: 1px 1px 0 solid #f85d18;
              border-left: 32px solid #f85d18;
              // margin: 10px 0 0;
              border-radius: 5px 5px 0 0;

              span.MuiIconButton-label {
                :hover {
                  filter: drop-shadow(1px 1px 10px black);
                  -webkit-filter: drop-shadow(1px 1px 10px black);
                }
              }

              .grip {
                position: absolute;
                left: -22px;
                height: 16px;
                top: 18px;
              }
            }

            .MuiAccordionSummary-content {
              .MuiTypography-root {
                color: #000000;
                opacity: 0.9;
              }
            }

            &::before {
              display: none;
            }

            .accord-detail {
              button.change-validbtn {
                background: #fff;
                color: #f85d18;
                border: 1px solid #f85d18;
                font-weight: 600;

                .chngeval-img {
                  padding-right: 10px;
                }
              }

              .change-validpopup {
                margin-top: 10px;
                align-self: end;
              }

              .ques-sec {
                .title-input {
                  .title-input__input {
                    font-size: 15px !important;
                    border-top: 0;
                    border-right: 0;
                    border-left: 0;
                    border-bottom: 1.5px solid #b4b3b3;
                  }

                  .title-input__suggestions {
                    left: 0px !important;
                    top: 10px !important;
                    max-height: 200px;
                    overflow: auto;
                    width: -webkit-fill-available;

                    span.title-input__suggestions__item__display {
                      font-size: 13px;
                      padding: 6px 10px;
                    }
                  }
                }
              }

              .ques-open {
                > .MuiPaper-root.MuiCard-root {
                  // border-left: 5px solid #000000;
                  border: 0 1px 1px 1px solid #f85d18;
                  border-left: 32px solid #f85d18;
                  border-radius: 0 0 5px 5px;
                }

                .ques-container {
                  display: inherit;
                  background: rgba(248, 93, 24, 0.06);
                  border: 0 1px 1px 1px solid #f85d18;
                  border-radius: 0 0 5px 5px;

                  .add-images-sec {
                    flex-basis: 370px;

                    .multi-image {
                      display: flex;
                      flex-wrap: wrap;
                      column-gap: 20px;
                      row-gap: 17px;
                      margin-top: 10px;
                      position: relative;
                      z-index: 40;

                      .fssai-wrap {
                        position: relative;

                        .cancel-img {
                          position: absolute;
                          top: -11px;
                          right: -10px;
                          cursor: pointer;
                          z-index: 5;
                          background-color: white;
                        }
                      }

                      div {
                        .thumbnail {
                          height: 100px;
                          width: 100px;
                          margin: 0;
                          padding: 0;
                          display: flex;

                          > img {
                            margin: 0;
                          }

                          .browse-container {
                            padding: 0 !important;

                            .browse-txt {
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            }

                            .browse-text {
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            }
                          }
                        }
                      }

                      .fileinput {
                        margin: 0;
                      }
                    }
                  }
                }
              }
            }
          }

          span.MuiButtonBase-root.MuiButton-root.MuiButton-text.add-quest {
            float: left;
            margin: 10px 0;
            background: #f85d18;
            color: #fff;
            padding: 6px 10px 4px;
            margin-top: 25px;

            .MuiButton-startIcon.MuiButton-iconSizeSmall {
              margin-top: -2px;
            }
          }

          .MuiCollapse-root {
            .MuiAccordionDetails-root.accord-detail {
              // margin-top: 20px;
              padding: 0;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
              border-bottom: 1px solid #f85d18;
              border-right: 1px solid #f85d18;
              border-left: 1px solid #f85d18;
              margin-top: -4px;
              border-radius: 0px 0 5px 5px;

              // .MuiCardHeader-root {
              //   border-left: 5px solid #000000;
              // }
              .jump-to {
                position: absolute;
                right: 2%;
                bottom: 10%;

                .jumpto-select {
                  border: 1px solid #f85d18;
                  color: #f85d18;
                  border-radius: 5px;
                  background-image: url("../img/jump-to.svg");
                  padding: 0.6em 0.5em 0.6em 2.5em;
                  background-repeat: no-repeat;
                  background-size: 1.2rem;
                  background-position: 0.7em center;
                  font-size: 15px;
                  font-weight: 500;
                }
              }

              .ques-sec {
                width: 815px;

                .select-dropdown {
                  margin: 10px 0;

                  > div.MuiInputBase-root {
                    width: 100%;
                  }
                }
              }
            }
          }

          .sub-question {
            display: flex;
            justify-content: start;
            align-items: center;
            background: #ffffff;

            .MuiAccordionSummary-expandIcon {
              // border-left: 1px solid;
              border-radius: 0;
            }

            .question-num {
              flex-basis: 60px;
              font-size: 20px;
              color: #000;
              font-weight: 700;
              margin-left: 10px;
            }

            .question-area {
              flex-basis: calc(100% - 60px);
              // border-left: 5px solid orange;
              box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
              background: rgba(248, 93, 24, 0.06);
              border: 1px solid #f85d18;
              border-radius: 5px;

              .MuiPaper-root.MuiCard-root.MuiPaper-elevation1 {
                box-shadow: none;
              }

              // .MuiAccordionSummary-content{
              //   border-right: 1px solid #000;
              // }

              .MuiAccordionSummary-content.Mui-expanded {
                margin: 12px 0;
              }
            }
          }

          .MuiPaper-root.MuiAccordion-root.acc-root {
            .MuiGrid-root.MuiGrid-container {
              justify-content: space-between;

              .ques-sec {
                .MuiFormControl-root.MuiTextField-root {
                  margin: 10px 0px;
                }
              }

              .ques-desc {
                label {
                  font-size: small;
                }

                input {
                  font-size: smaller;
                }
              }

              .MuiFormControl-root.jump-inline {
                // margin-top: 20px;
                margin-left: 30px;
                margin-right: 10px;
              }

              .options-area {
                // display: flex;
                // align-items: center;
                box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
                width: calc(100% - 12px);
                min-height: 125px;
                float: left;

                // .option-desc {
                //   display: grid;
                //   width: 67%;

                //   .optiondesc {

                //     // margin-top: 10px;
                //     label {
                //       font-size: small;
                //     }

                //     input {
                //       font-size: smaller;
                //     }
                //   }

                //   .cancel-jump {
                //     margin-top: 20px;
                //   }
                // }

                .opt-no {
                  height: 125px;
                  width: 40px;
                  background: #000000;
                  border-top-left-radius: 5px;
                  border-bottom-left-radius: 5px;
                  font-size: 26px;
                  cursor: grab;
                  color: #fff;
                  text-align: center;
                  margin-left: -1px;
                  float: left;
                  height: 226px;

                  .grip {
                    height: 16px;
                    margin: 12px 15px;
                  }

                  span {
                    line-height: 120px;
                  }
                }
              }
            }

            .MuiSelect-select.MuiSelect-select {
              display: inline-flex;
              align-items: center;

              .drop-icon {
                padding-right: 10px;
              }
            }
          }
        }

        .self_surve_buttons {
          span.MuiButtonBase-root.MuiButton-root.MuiButton-text.add-sec {
            float: right;
            margin-bottom: 20px;
          }
        }
      }

      .main-section {
        margin: 0px 20px 20px 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 8px 8px;

        .MuiAccordion-root {
          &::before {
            display: none;
          }
        }

        .section-number {
          background: #f85d18;
          padding: 16px 14px 13px;
          width: 100%;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 8px 8px 0px 0px;

          span {
            color: #fff;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }

      .survey-title-incentive {
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        padding: 40px 40px 40px 25px;
        margin: 0 20px 30px 20px;
        border-radius: 5px;
        // border: 1px solid rgb(20, 105, 178);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        .incentiveLabel {
          width: 800px;
        }
      }

      .custom-upload {
        margin-bottom: 21px;
        margin-right: 16px;

        .MuiCardActions-root {
          justify-content: flex-end;

          button.custom-btn {
            border-radius: 5px;
            background-color: #ffffff;
            padding: 5px 15px;

            .MuiButton-label {
              img {
                padding-right: 8px;
              }
            }

            span {
              &:nth-child(1) {
                color: #707070;
                opacity: 0.4;
              }
            }

            &.active-btn {
              border: 1px solid #3268ad;

              span {
                &:nth-child(1) {
                  color: #f85d18;
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      .date-range-picker {
        .fromDateTimeContainer {
          text-align: center;
          border: none;

          .input-group {
            input {
              border: 1px solid #d5d5d5;
              border-radius: 5px;
            }
          }

          .multipleContentOnLine {
            select {
              padding: 3px;
              border-radius: 5px;
              border: 1px solid #d5d5d5;
            }
          }

          .monthYearContainer {
            margin: 0 0 20px 0;
          }
        }
      }
    }

    .MuiTabs-flexContainer {
      border-bottom: 1px solid #d3d3d3;
    }

    .jump-inline {
      .MuiInputBase-root.MuiOutlinedInput-root.lists-items {
        > div#demo-simple-select-required {
          padding: 5px 20px;
        }
      }
    }

    .MuiPaper-root.expand-container {
      background: #efefef !important;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);

      .expand-heading {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #555555;
        padding-left: 10px;
      }

      .expand-details {
        width: 100%;

        .expand-col1 {
          width: calc(25% - 20px);
          background: #ffffff;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          padding: 20px;
          margin: 10px;
          float: left;

          .config-heading {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #555555;
            margin-bottom: 20px;

            span {
              text-align: right;
              float: right;
            }
          }

          .config-content {
            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              color: #777777;
            }
          }
        }

        .expand-col2 {
          width: 11.1%;
          background: #ffffff;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          margin: 10px;
          float: left;

          .config-heading {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #555555;
            padding: 20px 20px 0 20px;
          }

          .config-content {
            .pdlr20 {
              padding: 20px;
            }

            .col50 {
              width: 50%;
              float: left;
              height: 30px;
            }
          }
        }
      }

      .appconfig {
        width: 100%;

        .config-heading {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #555555;
          margin-bottom: 20px;
        }

        .fileInput {
          width: calc(25% - 20px);
          background: #e6e6e6;
          border: 1px dashed #000000;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          padding: 20px;
          margin: 10px;
          float: left;
          height: 300px;

          [type="file"] {
            height: 0;
            overflow: hidden;
            width: 0;
          }

          [type="file"] + label {
            background: #e6e6e6;
            color: #777;
            cursor: pointer;
            display: inline-block;
            outline: none;
            padding: 50px 30px;
            position: relative;
            vertical-align: middle;
            text-align: center;
            width: 100%;

            img {
              width: 100%;
              max-height: 68px;
            }

            &:hover {
              background-color: #e6e6e6;
            }
          }

          p {
            font-weight: 400;
            font-size: 16px !important;
            line-height: 19px;
            color: #777777;
          }
        }

        .logo-setting {
          width: calc(50% - 20px);
          background: #fff;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          padding: 20px;
          margin: 10px;
          float: left;
          height: 300px;

          .delete img {
            display: inline;
          }

          .logoImage {
            max-height: 205px;
            overflow: hidden;
            text-align: center;

            img {
              width: 100%;
              max-height: 205px;
            }
          }
        }
      }
    }

    @media (max-width: 1680px) {
      .MuiPaper-root.expand-container {
        .expand-details {
          width: 100%;

          .expand-col1 {
            width: calc(33.33% - 20px);
          }
        }
      }
    }

    @media (max-width: 1600px) {
      .MuiPaper-root.expand-container {
        .expand-heading {
          font-size: 18px;
        }

        .expand-details {
          width: 100%;

          .expand-col1 {
            width: calc(33.33% - 20px);

            .config-heading {
              font-size: 14px;
            }

            .config-content {
              p {
                font-size: 13px !important;
              }
            }
          }

          .expand-col2 {
            width: 20%;

            .config-heading {
              font-size: 14px;
            }
          }
        }

        .appconfig {
          .config-heading {
            font-size: 14px;
          }

          .fileInput {
            width: calc(33.33% - 20px);

            p {
              font-size: 13px !important;
            }
          }

          .logo-setting {
            width: calc(66.67% - 20px);
          }
        }
      }
    }

    @media (max-width: 1400px) {
      .MuiPaper-root.expand-container {
        .expand-details {
          .expand-col1 {
            .config-content {
              p {
                font-size: 12px !important;
              }
            }
          }
        }
      }
    }

    @media (max-width: 1300px) {
      .MuiPaper-root.expand-container {
        .expand-details {
          .expand-col1 {
            height: 185px;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.MuiList-root.MuiMenu-list {
  .MuiButtonBase-root.MuiListItem-root,
  li.MuiButtonBase-root {
    color: #3b3b3b;
    margin: 10px 0;

    .drop-icon {
      padding-right: 10px;
    }
  }

  .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.dropdown-end,
  .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.num-end {
    border-bottom: 1px solid #d4d4d4;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.radio-sel {
    padding-left: 13px;

    img.drop-icon {
      padding-right: 4px;
    }
  }
}

.MuiBox-root {
  place-items: center;

  .minmax {
    display: flex;
    justify-content: center;

    .MuiFormControl-root.MuiTextField-root {
      flex-basis: 20%;
    }
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.cancel-valid {
    background-color: #fff;
    color: #f85d18;
    border: 1px solid #f85d18;
  }

  .required,
  .editable {
    margin: 17px 0;

    .MuiSwitch-switchBase {
      color: #e83f3f;
    }

    .MuiSwitch-track {
      background: #b07171;
    }
  }

  .editable,
  .required {
    .MuiSwitch-colorSecondary.Mui-checked {
      .MuiIconButton-label {
        color: #f85d18;
      }

      .MuiSwitch-track {
        background-color: #b2cce2;
      }
    }
  }
}

.MuiDrawer-root.MuiDrawer-docked {
  .overflow {
    #new_version .ms-md-6 .center {
      position: relative;
    }
  }
}

// For New sidebar
// #new_version {
//   .sidebar-overlay {
//     background: rgba(105, 105, 105, 0.1);
//     height: 100vh;
//     position: fixed;
//     left: 261px;
//     width: 100%;
//     z-index: 100000;
//     transition: all 0.5s ease-out;
//     -webkit-transition: all 0.5s ease-out;
//     visibility: hidden;
//     top: 0;

//     &.active {
//       animation: scale-display 0.5s;
//       animation-fill-mode: forwards;
//       visibility: visible;
//     }

//     &.out {
//       animation: scale-display--reversed 0.5s;
//       animation-fill-mode: forwards;
//     }

//     .survey-sidebar {
//       width: 280px;
//       background: #fff;
//       overflow: auto;
//       height: inherit;

//       transition: all 0.3s;

//       .sidebar-content {
//         margin: 15px 25px;

//         .existing-surveys {
//           text-align: center;

//           >p {
//             font-size: 15px;
//             margin-top: 15px;
//             margin-bottom: 15px;
//           }

//           .divider {
//             height: 1px;
//             background-color: #646464;
//           }

//           .survey-item {
//             display: -ms-flexbox;
//             display: flex;
//             -ms-flex-pack: justify;
//             justify-content: space-between;
//             align-items: center;
//             margin: 10px 2px;
//             padding: 5px;
//             flex-wrap: wrap;

//             >p {
//               cursor: pointer;
//               font-size: 14px;
//               width: 80%;
//               text-align: left;
//               text-overflow: ellipsis;
//               overflow: hidden;
//               white-space: nowrap;
//               margin: 0px;
//               padding: 4px;
//             }

//             .survey-item-icons {
//               display: flex;
//               gap: 7px;
//               align-items: center;

//               >img {
//                 cursor: pointer;
//                 height: 20px;
//                 width: 20px;
//               }

//               span {
//                 border-left: 2px solid #8b8b8b;
//                 height: 20px;
//               }
//             }
//           }

//           .survey-item:hover {
//             background-color: #f0f0f0;
//             border-radius: 3px;
//           }

//           .survey-item.selected {
//             display: -ms-flexbox;
//             display: flex;
//             -ms-flex-pack: justify;
//             justify-content: space-between;
//             align-items: center;
//             margin: 10px 2px;
//             background-color: #f0f0f0;
//             border: 1px solid #f85d18;
//             padding: 5px;
//             border-radius: 3px;

//             >p {
//               cursor: pointer;
//               color: #f85d18;
//               font-size: 14px;
//               width: 80%;
//               text-align: left;
//               text-overflow: ellipsis;
//               overflow: hidden;
//               white-space: nowrap;
//             }

//             .survey-item-icons {
//               display: flex;
//               gap: 7px;
//               align-items: center;

//               >img {
//                 cursor: pointer;
//                 height: 20px;
//                 width: 20px;
//               }

//               span {
//                 border-left: 2px solid #8b8b8b;
//                 height: 20px;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

// For Old Sidebar
.sidebar-overlay {
  background: rgba(105, 105, 105, 0.1);
  height: 100vh;
  position: fixed;
  left: 261px;
  width: 100%;
  z-index: 100000;
  transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  visibility: hidden;
  top: 0;

  &.active {
    animation: scale-display 0.5s;
    animation-fill-mode: forwards;
    visibility: visible;
  }

  &.out {
    animation: scale-display--reversed 0.5s;
    animation-fill-mode: forwards;
  }

  .survey-sidebar {
    width: 280px;
    background: #fff;
    overflow: auto;
    height: inherit;

    transition: all 0.3s;

    .sidebar-content {
      margin: 15px 25px;

      .existing-surveys {
        text-align: center;

        > p {
          font-size: 15px;
          margin-top: 15px;
          margin-bottom: 15px;
        }

        .divider {
          height: 1px;
          background-color: #646464;
        }

        .survey-item {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: justify;
          justify-content: space-between;
          align-items: center;
          margin: 10px 2px;
          padding: 5px;
          flex-wrap: wrap;

          > p {
            cursor: pointer;
            font-size: 14px;
            width: 80%;
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin: 0px;
            padding: 4px;
          }

          .survey-item-icons {
            display: flex;
            gap: 7px;
            align-items: center;

            > img {
              cursor: pointer;
              height: 20px;
              width: 20px;
            }

            span {
              border-left: 2px solid #8b8b8b;
              height: 20px;
            }
          }
        }

        .survey-item:hover {
          background-color: #f0f0f0;
          border-radius: 3px;
        }

        .survey-item.selected {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: justify;
          justify-content: space-between;
          align-items: center;
          margin: 10px 2px;
          background-color: #f0f0f0;
          border: 1px solid #f85d18;
          padding: 5px;
          border-radius: 3px;

          > p {
            cursor: pointer;
            color: #f85d18;
            font-size: 14px;
            width: 80%;
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .survey-item-icons {
            display: flex;
            gap: 7px;
            align-items: center;

            > img {
              cursor: pointer;
              height: 20px;
              width: 20px;
            }

            span {
              border-left: 2px solid #8b8b8b;
              height: 20px;
            }
          }
        }
      }
    }
  }
}

@keyframes scale-display {
  0% {
    opacity: 0;
    left: 0;
  }

  100% {
    opacity: 1;
    left: 261;
  }
}

@keyframes scale-display--reversed {
  0% {
    opacity: 1;
    left: 261;
  }

  99% {
    opacity: 0;
    left: 0;
  }

  100% {
    opacity: 0;
    left: 0;
  }
}

.MuiPaper-root.MuiAppBar-root {
  .MuiToolbar-root {
    .dashboard-header {
      .new_Card {
        position: absolute;
        left: 25px;
        top: 82px;

        // right: 47px;
        // top: 102px;
      }
    }
  }
}

#dashboardWrap {
  .ReactTable {
    .rt-noData {
      background: none;

      &::before {
        content: url("../img/no-response-found.svg");
        // background: url("../img/no-response-found.svg");
      }
    }
  }

  .breadcrumb {
    margin-left: 25px;
    margin-top: 25px;
    display: inline-block;

    > span {
      font-size: 16px;
      font-weight: 400;

      &:nth-child(1) {
        color: #f85d18;
        cursor: pointer;
      }
    }
  }
}

.setting-btn {
  display: flex;
  align-items: center;
  gap: 4px;

  > p {
    font-size: initial;
    color: #f85d18;
    margin: 0px;
    font-weight: 500;
  }
}

.edit-wrapper {
  background-color: rgb(209, 209, 209);
  pointer-events: none;
  opacity: 0.7;
}

.publish-status {
  background-color: #acc7df;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin: 0px;
}

#new_version h1.publish-status {
  margin: 0px;
}

.published {
  background-color: green;
}

.paused {
  background-color: rgb(255, 213, 0);
}

.archieved {
  background-color: red;
}

.clear {
  clear: both;
}

.mrb20 {
  margin-bottom: 20px;
}

.mrb15 {
  margin-bottom: 15px;
}

.settingDialog,
#new_version .settingDialog {
  .blue-btn,
  .blue-btn:hover {
    border-radius: 4px;
    font-size: 14px;
    font-family: $mdb-font-family;
    font-weight: bold;
    margin: auto;
    width: 150px;
  }

  .MuiPaper-root {
    background: transparent !important;
    box-shadow: none !important;
  }

  .settingPreview {
    width: 290px;
    margin: 0 30px;
    background: #fff;
    float: left;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    // height: 630px;
    height: auto;

    .loginScreen {
      margin: 150px 20px;
      text-align: center;
      width: calc(100% - 40px);

      .surveyLogo {
        width: auto;
        height: 75px;
        margin: 0 auto;
        margin-bottom: 30px;
      }

      h4 {
        font-size: 16px;
        font-family: Lato-Bold, sans-serif;
        margin: 0 0 10px;
        color: #3d3d3d;
        font-weight: 600;
      }

      p {
        font-size: 11px;
        margin-bottom: 30px;
        color: #3d3d3d;
      }

      button.btnRounded {
        cursor: pointer;
        width: 100%;
        border: 1px solid #d6d6d6;
        border-radius: 50px;
        margin-bottom: 20px;
        padding: 15px 10px;
        font-size: 14px;
        transition: all 0.2s;
        outline: none;
        height: 40px;
        justify-content: center;

        img {
          position: relative;
          left: -15px;
          height: 16px;
          margin: 0;
        }
      }

      button.loginWihtOtp.btnRounded {
        background: #0099a7;
        border-color: #0099a7;
        color: #fff;
        display: flex;
        align-items: center;
        text-transform: unset;
      }

      button.btnRounded.fbBtn {
        background: #4460b0;
        border-color: #4460b0;
        color: #fff;
        display: flex;
        align-items: center;
        text-transform: unset;
        justify-content: center;
      }

      button.btnRounded.gogBtn {
        display: flex;
        align-items: center;
        border-color: #f14336;
        text-transform: unset;
        background: #f14336;
        color: #fff;
      }
    }

    .head-center {
      display: flex;
      justify-content: space-between;
      box-shadow: 0px 6px 5px -5px #00000029;
      border-bottom: 1px solid #0000000f;
      height: 55px;
      width: 100%;

      .surveyLogo {
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 10px auto;

        img {
          width: 90px;
        }
      }

      .helpHead {
        display: flex;
        align-items: center;
        line-height: 55px;

        .ratings {
          display: flex;
          align-items: center;

          img {
            margin-right: 5px;
            width: 20px;
          }

          span {
            font-family: Lato-Bold;
            color: #2c4b9d;
            font-size: 16px;
          }
        }

        .menu-dots {
          img {
            width: 28px;
            height: 28px;
          }
        }
      }
    }

    .postalInput {
      margin: 96px 20px 45px;
      width: calc(100% - 40px);

      h3.surveyQues {
        font-size: 16px;
        margin: 20px 0px;
        text-align: left;
        font-weight: 600;
      }

      .main {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 15px;
        cursor: pointer;
        font-size: 16px;
        user-select: none;
        color: #3d3d3d;
        font-weight: 600;
        pointer-events: none;
        font-family: $mdb-font-family;
      }

      input[type="checkbox"] {
        visibility: hidden;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: #fff;
        border: 2px solid #828080;
        border-radius: 5px;
      }

      .main:hover input ~ .checkmark,
      .main input:active ~ .checkmark,
      .main input:checked ~ .checkmark {
        background-color: #f85d18;
        border-color: #f85d18;
      }

      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      .main input:checked ~ .checkmark:after {
        display: block;
      }

      .main .checkmark:after {
        left: 4px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      .subRadioText {
        color: #00000080;
        font-size: 14px;
        display: inline-block;
      }

      button.btnRounded {
        cursor: pointer;
        width: 100%;
        border: 1px solid #d6d6d6;
        border-radius: 50px;
        margin-bottom: 20px;
        margin-top: 15px;
        font-size: 14px;
        transition: all 0.2s;
        outline: none;
        height: 40px;
        justify-content: center;
        margin-top: 15px;
      }

      .btn-prime {
        background: #f85d18;
        color: white;
        border-color: #f85d18;
      }
    }

    .footerProgressBarCont {
      background: #f8f8f8;
      width: 100%;
      box-shadow: 0px -6px 5px -5px #00000029;
      border-top: 1px solid #0000000f;
      display: flex;
      align-items: center;
      padding: 20px 0px;
      z-index: 4;
      margin: auto;

      .progressBar {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 55%;
        margin: 0 auto;

        .meterMain {
          overflow: hidden;
          height: 10px;
          position: relative;
          background: #e5e5e5;
          border-radius: 25px;
          box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
          width: 100%;
          margin: 0 5px;

          span {
            display: block;
            height: 100%;
            background-color: #f85d18;
            border-radius: 25px;
            box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
              inset 0 -2px 6px rgba(0, 0, 0, 0.4);
            position: relative;
            overflow: hidden;
            transition: width 0.4s ease;
          }
        }
      }
    }

    .shareSurvey {
      background: #f85d18;
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100% - 55px);
      position: relative;
      overflow: auto;
      width: 100%;
      overflow: hidden;

      .midContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 50px;
        margin-top: -95px;

        p {
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          text-align: center;
          width: 80%;
        }

        .skip-share {
          display: block;
          text-align: center;
          margin-top: 30px;
          width: auto;

          .skip {
            font-size: 16px;
            color: #fff;
            cursor: pointer;
          }

          .share-btn {
            cursor: pointer;
            display: block;
            border: 1px solid #d6d6d6;
            background: white;
            color: #494949;
            border-radius: 50px;
            padding: 10px 40px;
            font-size: 16px;
            transition: 200ms all;
            outline: none;
            text-transform: capitalize;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

#new_version .createSurvey {
  .langSelection {
    .langLabel {
      display: flex;
      flex-direction: column;

      .lang-sec {
        line-height: 1.5;
      }
    }
  }
}

.createSurvey {
  width: 100%;
  float: left;

  .langSelection {
    width: calc(100% - 45px);
    margin: 0px 25px 30px 20px;
    float: left;

    .MuiFormControl-root {
      width: 100%;
    }

    .multiLanguage {
      width: 120px;
      // float: left;
      margin: 0 10px 0 0;
      padding-left: 5px;

      .MuiInput-underline:before,
      .MuiInput-underline:after {
        border-bottom: 0 !important;
      }
    }

    .addLangbutton {
      cursor: pointer;
      background-color: transparent;
    }

    .langLabel {
      display: inline-flex;
      border-bottom: 2px solid transparent;
      line-height: 32px;
      color: #000;
      padding: 5px 5px;
      margin-right: 10px;
      width: 220px;
    }

    .langLabel.active {
      border-bottom: 2px solid #f85d18;
    }

    .langLabel:hover {
      border-bottom: 2px solid #f85d18;
      background: #e2e2e2;
    }

    .surveyEnds,
    .quota {
      width: 15%;
      float: left;
      margin: 0 10px;
    }

    .clear {
      clear: both;
      width: 100%;
    }

    .chipTags {
      width: 100%;
      margin: 15px 0;

      li {
        list-style-type: none;
        float: left;
        margin: 0 7px;
      }
    }
  }
}

#new_version {
  .languageAll {
    width: calc(100% - 0px);
    // border-bottom: 1px solid rgb(119, 119, 119);
    display: flow-root;

    p {
      font-size: 12px !important;
      line-height: 14px !important;
      color: rgb(85, 85, 85);
      margin: 0 10px 0 0;
    }

    .lang-sec {
      img {
        margin: 0;
      }
    }
  }
}

.borderLang {
  width: calc(100% - 10px);
  border-top: 1px solid rgb(119, 119, 119);
  height: 1px;
}

.acc-root.Mui-expanded {
  .sub-question .question-area {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
    background: rgba(248, 93, 24, 0.06);
    border-top: 1px solid #f85d18;
    border-right: 1px solid #f85d18;
    border-left: 1px solid #f85d18;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.main-section .acc-roots {
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #f85d18;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 8px 8px;
}

.text-fields {
  width: 800px;
  float: left;
}

.text-fields p {
  font-size: 14px;
  line-height: 17px;
  color: #000000;

  sup {
    color: #f14336;
  }
}

.notePlaceholder {
  background: #f7f7f7;
  border: 1px dashed #888888;
  padding: 15px;
  font-size: 13px;
  line-height: 18px;
  color: #777777;
}

.uniqueId {
  width: 200px;
  float: left;
  margin-left: 40px;
  background: #eeeeee;
  padding: 10px 15px;
  border-radius: 6px;

  p {
    margin-bottom: 0;
    color: #555;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  img {
    width: 14px;
    height: 14px;
    margin-left: 5px;
    display: inline-block;
    margin-top: -4px;
  }
}

#standard-full-width input[type="text"] {
  padding-right: 95px;

  ::placeholder {
    font-size: 16px;
    color: #888888;
  }
}

.langForm {
  position: absolute;
  float: left;
  left: 41%;
  font-size: 16px;
  color: #ccc;
}

.MuiButton-root.add-sec {
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 21px !important;
  color: #555555 !important;
}

.MuiButton-root.add-sec:hover {
  background-color: transparent !important;
  opacity: 1 !important;
}

.uniqueIdentifier {
  font-size: 14px;
  position: absolute;
  margin-top: 30px;
  margin-left: -240px;
  color: #aaaaaa;
}

.option-desc {
  background: #ffffff;
  // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.45);
  border-radius: 5px;
  margin: 15px;
  // padding: 15px;
  display: block;
  width: calc(100% - 40px);
  float: left;

  .titleInput {
    width: 30%;
    float: left;
  }

  .descriptionInput {
    width: calc(70% - 40px);
    float: left;
    margin-left: 15px;
  }

  .langLabel {
    font-size: 14px;
    line-height: 22px;
    color: #606060;
    width: fit-content;
    margin-bottom: 15px;
    font-weight: 700;
    display: flex;
  }

  .optionLabel {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #666666;
    margin-bottom: 10px;
    width: 100%;
    display: block;
  }

  input.optionInput {
    background: #efefef;
    border-radius: 5px;
    border: 0px;
    font-size: 16px;
    line-height: 36px;
    padding: 0 10px;
    margin-bottom: 15px;
    width: 100%;
  }

  textarea.optionInput {
    resize: none;
    background: #efefef;
    border-radius: 5px;
    border: 0px;
    font-size: 16px;
    line-height: 36px;
    padding: 0 10px;
    width: 100%;
  }

  .deleteOption {
    cursor: pointer;
    width: 85px;
    padding: 0px 10px;
    color: rgb(183, 96, 89);
    border: 1px solid rgb(183, 96, 89);
    float: right;
    line-height: 28px;
    border-radius: 5px;
    font-size: 13px;
    margin-right: 15px;
    margin-top: -40px;
  }
}

.jumpDelete {
  padding-left: 20px;
  display: flex;
  flex-direction: row-reverse;
  float: left;
}

.jumpItem {
  float: left;
  font-size: 14px;

  span {
    margin-right: 10px;
  }
}

#dashboardWrap #dashboardInnerWrap .MuiPaper-root.acc-root {
  margin: 15px 0;
}

#dashboardWrap #dashboardInnerWrap .MuiPaper-root.acc-root.Mui-expanded {
  margin: 0;
}

.delSectionBorder {
  border-right: 1px solid #f85d18;
  height: 38px;
}

.delBorder {
  border-right: 1px solid #f85d18;
  height: 30px;
}

.deleteQuestion {
  cursor: pointer;
  width: 85px;
  padding: 0px 10px;
  color: rgb(183, 96, 89);
  border: 1px solid rgb(183, 96, 89);
  float: right;
  line-height: 28px;
  border-radius: 5px;
  font-size: 13px;
  margin-right: 15px;

  img {
    width: 12px;
    height: 14px;
    float: right;
    margin-left: 5px;
    margin-top: 6px;
  }
}

.deleteSection {
  cursor: pointer;
  width: 85px;
  padding: 0px 10px;
  color: #fff;
  background: #f85d18;
  border: 1px solid #f85d18;
  float: right;
  line-height: 28px;
  border-radius: 5px;
  font-size: 13px;
  margin-top: -5px;

  span {
    position: relative;
    top: 1px;
  }

  img {
    width: 12px;
    height: 14px;
    float: right;
    margin-left: 5px;
    margin-top: 6px;
  }
}

.survey-title-sec {
  display: block;
  float: right;
  margin-top: -150px;
  background: #e6e6e6;
  border-radius: 5px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  margin-right: 20px;
}

.editSurvey .survey-title-sec {
  margin-top: -208px !important;
}

.survey-title-upload {
  display: block;
  float: right;
  margin-top: -73px;
  background: #e6e6e6;
  border-radius: 5px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  margin-right: 20px;
}

.publishDate {
  float: right;
  margin-top: -135px;
  margin-right: 290px;
}

.editSurvey .publishDate {
  margin-top: -194px !important;
}

.publishDateUpload {
  text-align: right;
  margin: -30px 290px 0 0;
  position: relative;
  top: -25px;
}

// Setting Dropdown
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownShow {
  display: block;
  position: absolute;
  background-color: #ffffff;
  min-width: 75px;
  overflow: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
  margin-left: -30px;
}

.dropdownHide {
  display: none;
}

.dropdownShow a {
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #000000;
  padding: 0 5px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #e6e6e6;
}

//Lang Dropdown
.addlangDropdown {
  position: relative;
  // display: inline-flex;
  display: inline-block;
  width: auto;
  align-items: end;

  &.border-bottom {
    border-bottom: 1px solid #777 !important;
    padding-bottom: 1px;
  }
}

#new_version {
  //Lang Dropdown
  .addlangDropdown {
    position: relative;
    display: flex;
    width: auto;
    align-items: end;

    &.border-bottom {
      border-bottom: 1px solid #777 !important;
    }
  }
}

// Tabs
#new_version {
  .nav-tabs {
    border: 0;
  }
}

.addlangDropdownShow {
  display: block;
  position: absolute;
  background-color: #ffffff;
  min-width: 220px;
  overflow: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
  // margin-left: -37px;
  margin-top: 4px;
}

.addlangDropdown .dropdown-menu {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  margin-top: 4px;
  padding: 5px;
  border-radius: 0;
  width: 240px;
}

#new_version .searchBar.form-floating > .form-control {
  height: calc(2.5rem + 2px);
  padding: 0rem 2.2rem 0 0.2rem;
}

#new_version {
  .searchBar.form-floating {
    > label {
      padding: 0.5rem 0.75rem;
      line-height: 2.3;
    }

    .form-control:focus ~ label {
      transform: scale(0.85) translateY(-1.2rem) translateX(0.15rem);
    }
  }
}

#new_version {
  .addlangDropdown .dropdown-menu {
    border: 0;
    border-radius: 0;
    margin-top: 3px !important;
  }
}

.editSurvey .addlangDropdownShow .MuiTextField-root {
  width: calc(100% - 20px) !important;
  margin: 0px 10px 10px !important;
  display: inline-flex !important;
  height: auto !important;
}

.addlangDropdownHide {
  display: none;
}

.addlangDropdownShow a,
.languageAll .dropdown-menu a {
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #000000;
  padding: 0 10px;
  text-decoration: none;
  display: block;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.searchBtn {
  position: absolute;
  right: 20px;
  top: 12px;
  padding: 0;
  width: auto;
  height: auto;
  background: none;
}

.addlangDropdown .dropdown-menu a {
  color: #000000 !important;
}

#new_version .searchBar {
  padding: 0 10px;

  .form-control {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #ddd;
  }
}

.addlangDropdown a:hover {
  background-color: #e6e6e6;
}

.langDropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

#new_version {
  .langDropdown {
    width: auto;
    margin: 0 0 0 5px;
    display: flex;
    justify-content: space-between;
    flex: 1;

    span {
      color: var(--rs-text-link);
      display: flex;
      align-items: center;

      img.w-12 {
        width: 12px;
      }

      img.h-18 {
        max-height: 18px;
      }
    }
  }
}

.redColor {
  fill: #b76059;
}

.langDropdownShow {
  display: block;
  position: absolute;
  background-color: #ffffff;
  min-width: 220px;
  overflow: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
  margin-left: -37px;
  margin-top: 4px;
}

.langDropdownHide {
  display: none;
}

.langDropdownShow a {
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #000000;
  padding: 0 10px;
  text-decoration: none;
  display: block;
}

.langDropdown a:hover {
  background-color: #e6e6e6;
}

// Add Options dropdown
.optionDropdown {
  position: initial;
  display: inline-block;
}

.optionDropdownShow {
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 130px;
  overflow: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.optionDropdownHide {
  display: none;
}

.optionDropdownShow a {
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #000000;
  padding: 0 5px;
  text-decoration: none;
  display: block;
}

.optionDropdown a:hover {
  background-color: #e6e6e6;
}

.publishDropdown {
  position: initial;
  float: right;
  margin-left: 15px;
}

.publishDropdownShow {
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 130px;
  overflow: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
  padding: 5px 10px;
  margin-top: -210px;
  right: 50px;

  p {
    font-size: 12px;

    img {
      display: inline;
      margin-left: 8px;
    }
  }

  p.text {
    font-size: 12px;
    color: #333;
    font-weight: 600;
    margin-bottom: 0;
  }

  p.error {
    font-size: 12px;
    color: #777;
  }

  p.resolveMsg {
    font-size: 12px;
    color: #333;
    margin-top: 10px;
  }
}

.publishDropdownHide {
  display: none;
}

.publishDropdownShow a {
  background: #f85d18;
  border-radius: 2px;
  line-height: 32px;
  font-size: 14px;
  width: 62px;
  color: #fff;
  padding: 0 5px;
  text-decoration: none;
  display: block;
  text-align: center;
  float: right;
}

.publishDropdown a:hover {
  background-color: #e6e6e6;
}

.surveyHeading {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #555555;
  height: 28px;
  // width: calc(100% - 550px);
  width: 100%;
  align-items: center;
  font-family: $mdb-font-family;

  .title {
    // -webkit-line-clamp: 1;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // -webkit-box-orient: vertical;
    // max-width: calc(100% - 315px);
    // float: left;
    max-width: calc(100% - 120px);

    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      margin-left: 0;
    }
  }

  span {
    font-size: 14px;
    line-height: 20px;
  }
}

.blue {
  color: #f85d18;
}

.createSurveyForm,
.editSurvey {
  width: 100%;
  float: left;
  background: #fff;
  border-radius: 5px;
}

.tabSurvey {
  width: 100%;
  float: left;
  background: #f7f7f7;
  min-height: calc(100vh - 146px);
}

.createSurveyForm .tabSurvey {
  padding: 30px 10px 0;
}

.editSurvey .tabSurvey {
  padding: 30px 30px 0;
}

.editSurveyUpload .tabSurvey {
  padding: 90px 30px 0;
}

.cstmTab,
.nav.nav-tabs {
  width: calc(100% - 100px);
  float: left;
  padding-top: 25px;
  margin: 0 30px;

  .tabTitle,
  .nav-link {
    // line-height: 40px;
    padding: 12px 32px 10px;
    color: #555;
    float: left;
    font-weight: 400;
    font-size: 1em;
    background-color: #ffffff;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
    }
  }

  .active {
    background: #f7f7f7;
  }
}

#new_version {
  .nav.nav-tabs {
    width: calc(100% - 100px);
    float: left;
    margin: 0;
    border: 0;
    padding-top: 0;

    .nav-link {
      // line-height: 40px;
      padding: 12px 32px 10px;
      color: #555;
      float: left;
      font-weight: 400;
      font-size: 1em;
      background-color: #ffffff;
      cursor: pointer;
      border-radius: 0;
      border: 0;
    }

    .active {
      background: #f7f7f7 !important;
    }
  }
}

.editSurveyUpload .cstmTab {
  width: 100%;
  float: left;
  padding: 25px 50px 0;
  margin: 0;
  background-color: #fff;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #1554f6 !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #1554f6 !important;
}

.surveyResponse,
.auditLogs,
.userMgmt {
  overflow: hidden;
}

.settingsContainer {
  padding: 40px 20px 20px !important;
}

.respondentInfo,
.logDetails {
  margin: 70px 50px 25px;
}

.viewUser {
  margin: 70px 50px 0;
}

.userModule {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: rgb(255, 255, 255);
  margin: 50px 50px 0;
  border-radius: 5px 5px 0 0;
}

#new_version #settings-content .billingContainer .datedropdown {
  position: relative;
  display: inline-block;
  width: 300px;
  background: rgba(31, 18, 30, 0.05);
  border: 1px solid rgba(31, 18, 30, 0.05);
  border-radius: 4px;
  height: 40px;
  padding: 0 12px;
  line-height: 40px;
  font-size: 15px;
  margin-top: -36px;
  cursor: pointer;
}

#new_version #settings-content .billingContainer .datedropdownShow {
  display: block;
  position: absolute;
  background-color: #ffffff;
  width: 295px;
  overflow: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
  margin-left: -10px;
}

#new_version #settings-content .billingContainer .datedropdownHide {
  display: none;
}

#new_version #settings-content .billingContainer .datedropdownShow a {
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 40px !important;
  color: rgba(0, 0, 0, 0.7) !important;
  padding: 0 10px !important;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

#new_version #settings-content .billingContainer .datedropdown a:hover {
  background-color: #e6e6e6;
}

@media (max-width: 1400px) {
  .surveyHeading {
    font-size: 14px;

    .title {
      max-width: calc(100% - 250px);
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      -webkit-box-orient: vertical;

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
      }
    }

    span {
      font-size: 11px;
    }
  }

  .text-fields {
    width: 710px;
  }

  .MuiAccordionDetails-root {
    padding: 8px 25px 16px;
  }

  .createSurvey .langSelection {
    padding: 0 0px 30px;
  }

  .languageAll {
    margin-left: 0;
  }
}

@media (max-width: 1360px) {
  .surveyHeading {
    width: 100%;

    .smaller .blue-select {
      font-size: 11px !important;
    }

    .title {
      max-width: calc(100% - 250px);
      overflow: hidden;

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        white-space: normal;
        line-height: normal;
      }
    }

    span {
      font-size: 11px;
    }
  }

  .text-fields {
    width: 500px;
  }

  .MuiAccordionDetails-root {
    padding: 8px 25px 16px;
  }

  .createSurvey .langSelection {
    padding: 0 0px 30px;
  }

  // .editSurvey .languageAll,
  .editSurveyUpload .languageAll {
    margin-top: 70px;
  }

  #new_version .addSection-btn {
    font-size: 16px;
  }

  #new_version .addSection-btn svg {
    font-size: 26px;
  }
}

.cred-container {
  display: flex;
  justify-content: space-between;
}

.sendgrid {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
}

.twillio {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  margin-right: 30px;
}

.userBtnSection {
  margin: 0px 50px 25px;
  width: calc(100% - 100px);
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 5px 5px;
}

@media (max-width: 1600px) {
  #new_version .main-tab-container > .nav.nav-tabs {
    gap: 10px 12px !important;
    max-width: calc(100% - 100px);
  }
}
@media (max-width: 1580px) {
  #dashboardWrap
    #dashboardInnerWrap
    .MuiPaper-root
    .main-section
    .question-section-detail
    .acc-root
    .MuiCollapse-root {
    padding-left: 59px;
  }
}

@media (max-width: 1200px) {
  .incentiveLabel {
    width: 200% !important;
  }
}

@media (max-width: 1160px) {
  .text-fields {
    width: 350px;
  }
}

@media (max-width: 1180px) {
  .settingDialog .settingPreview {
    width: calc(33.33% - 20px);
    margin: 0 10px;
  }
}

@media (max-width: 1100px) {
  .userBtnSection,
  .module-details-table,
  .surveyResponse,
  .auditLogs,
  .userMgmt,
  .settingsContainer,
  .cstmTab,
  .respondentInfo,
  .logDetails,
  .viewUser,
  .userModule {
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
  }

  .editSurvey .tabSurvey,
  .createSurveyForm .tabSurvey,
  .editSurveyUpload .tabSurvey {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 680px) {
  #dashboardWrap
    #dashboardInnerWrap
    .MuiPaper-root
    .main-section
    .MuiButtonBase-root.summary-sec
    form.MuiBox-root.form-box {
    flex-direction: column;
    align-items: flex-start;
  }

  .text-fields {
    width: 200%;
  }

  .uniqueId {
    margin-left: 0;
  }
}

// .main-section{
//   .MuiInputBase-root{
//     background: #efefef;
//     padding-left: 10px;
//   }
//   .MuiInput-underline:before,
//   .MuiInput-underline:hover:not(.Mui-disabled):before{
//     border-bottom: 2px solid #efefef;
//   }
// }
.question-options {
  margin-left: -16px;
  width: 831px;
  display: block;
}

.ques-row {
  width: calc(50% - 20px);
  float: left;
  padding-right: 20px;
}

.option-row {
  width: 50%;
  float: left;
}

.addRow {
  width: calc(100% - 42px);
  margin: 0 10px 10px;
  float: left;

  .MuiTextField-root {
    width: 100%;
  }
}

.addRowButton {
  border-radius: 15px;
  background-color: #f85d18;
  float: left;
  margin-top: 20px;
  padding: 5px;
}

.delRowButton {
  border-radius: 15px;
  background-color: #b76059;
  float: left;
  margin-top: 20px;
  padding: 9px 5px;
}

.versionDropdown {
  position: relative;
  display: inline-block;
}

.versionDropdownShow {
  display: block;
  position: absolute;
  background-color: #ffffff !important;
  min-width: 220px;
  overflow: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
  margin-left: 5px;
}

.versionDropdownHide {
  display: none;
}

.versionDropdownShow a {
  font-weight: 500;
  font-size: 13px;
  line-height: 32px;
  color: #000000;
  padding: 0 5px;
  text-decoration: none;
  display: block;
}

.versionDropdown a:hover {
  background-color: #e6e6e6;
}

@media (max-width: 1330px) {
  .question-options {
    width: calc(100% + 16px);
  }
}

.editSurvey .surveyHeading,
.editSurveyUpload .surveyHeading {
  height: 56px;
}

.createSurvey .title {
  // max-width: calc(100% - 400px);
  max-width: calc(100% - 90px);
  overflow: hidden;
}

.addQuota {
  display: inline-flex;
  gap: 20px;
  align-items: center;

  button.closeIcon {
    display: flex;
    width: 25px;
    height: 21px;
    justify-content: center;
    align-items: center;
    border-radius: 50% !important;
    background: #f85d18 !important;

    svg {
      font-size: 16px;
      color: #fff;
      line-height: normal;
    }
  }
}

.versionQuota {
  display: block;
  width: auto;
  margin-left: -5px;
  margin-top: 6px;

  .revertVersion {
    width: 240px;

    @media (max-width: 1366px) {
      width: 200px;
    }
  }

  .MuiInputBase-input {
    font-size: 11px;
  }

  img {
    width: 22px;
    height: 22px;
    display: inline-block;
    margin-top: 5px;

    @media (max-width: 1366px) {
      width: 18px;
      height: 18px;
    }
  }
}

.MuiSnackbar-root {
  .MuiSnackbarContent-root {
    color: #fff !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3), 0px 12px 24px rgba(0, 0, 0, 0.2) !important;
    background-color: #ec554a !important;
    padding: 0 15px 0 0 !important;
    line-height: 20px;
    border-radius: 8px;
    font-size: 15px !important;

    .MuiSnackbarContent-message {
      font-weight: 500 !important;
      max-width: 100%;

      .MuiSvgIcon-root {
        float: left;
        margin-left: 8px !important;
        margin-right: 6px !important;
        width: 20px !important;
        height: 20px !important;
      }
    }

    @media (max-width: 1440px) {
      font-size: 14px !important;
    }
  }
}

@media (min-width: 1400px) {
  .editSurvey .survey-title-sec {
    margin-top: -180px !important;
  }

  .editSurvey .publishDate {
    margin-top: -166px !important;
  }
}

@media (min-width: 1660px) {
  .versionQuota {
    display: inline-block;
    margin-left: auto;
    margin-top: auto;
  }

  .surveyHeading {
    // height: 28px;
    height: auto;
  }
}

.chartSelect {
  background: rgba(31, 18, 30, 0.05);
  border: 1px solid rgba(31, 18, 30, 0.05);
  border-radius: 4px;
  height: 40px;
  padding: 0 10px;
  width: 300px !important;
}

.chartSelect .MuiSelect-select:focus {
  background-color: transparent !important;
}

#menu-list {
  ul {
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: -4px;
  }
}

.loader-container {
  width: 30px;
  height: 30px;
  position: relative;
}

.loader-container.animation-2 {
  transform: rotate(10deg);
}

.loader-container.animation-2 .shape {
  border-radius: 5px;
}

.loader-container.animation-2 {
  -webkit-animation: rotation 1s infinite;
  animation: rotation 1s infinite;
}

.loader-container .shape {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 1px;
}

.loader-container .shape.shape1 {
  left: 0;
  background-color: #5c6bc0;
}

.loader-container .shape.shape2 {
  right: 0;
  background-color: #8bc34a;
}

.loader-container .shape.shape3 {
  bottom: 0;
  background-color: #ffb74d;
}

.loader-container .shape.shape4 {
  bottom: 0;
  right: 0;
  background-color: #f44336;
}

@-webkit-keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animation-2 .shape1 {
  -webkit-animation: animation2shape1 0.5s ease 0s infinite alternate;
  animation: animation2shape1 0.5s ease 0s infinite alternate;
}

@-webkit-keyframes animation2shape1 {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(20px, 20px);
  }
}

@keyframes animation2shape1 {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(20px, 20px);
  }
}

.animation-2 .shape2 {
  -webkit-animation: animation2shape2 0.5s ease 0s infinite alternate;
  animation: animation2shape2 0.5s ease 0s infinite alternate;
}

@-webkit-keyframes animation2shape2 {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(-20px, 20px);
  }
}

@keyframes animation2shape2 {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(-20px, 20px);
  }
}

.animation-2 .shape3 {
  -webkit-animation: animation2shape3 0.5s ease 0s infinite alternate;
  animation: animation2shape3 0.5s ease 0s infinite alternate;
}

@-webkit-keyframes animation2shape3 {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(20px, -20px);
  }
}

@keyframes animation2shape3 {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(20px, -20px);
  }
}

.animation-2 .shape4 {
  -webkit-animation: animation2shape4 0.5s ease 0s infinite alternate;
  animation: animation2shape4 0.5s ease 0s infinite alternate;
}

@-webkit-keyframes animation2shape4 {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(-20px, -20px);
  }
}

@keyframes animation2shape4 {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(-20px, -20px);
  }
}

@media screen and (max-width: 600px) {
  .content {
    align-content: flex-start;
  }

  .content .column {
    width: calc(50% - 30px);
  }

  .log_section p {
    flex-direction: column;
  }
}

@media screen and (max-width: 400px) {
  .content {
    align-content: flex-start;
  }

  .content .column {
    width: calc(100% - 30px);
  }
}

// changes on 23-1-23
// Calander Settings
// .rdrCalendarWrapper.rdrDateRangeWrapper { display: none;}
// .rdrDefinedRangesWrapper { display: none;}
// #new_version .admin-content-box #searchFilter .filtersubDropdownShow.ml-custom { margin-left: -410px;}
// .rdrInputRange { gap: 10px;}

.survey-list {
  list-style: none;
  padding: 0;

  a {
    color: #f85d18;
    text-decoration: underline;
    font-size: 12px;
  }

  li {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    font-weight: 400;
    padding: 7px 10px;
    border-radius: 5px;
    border: 1px solid #fff;

    &:hover {
      background: rgba(248, 93, 24, 0.06);
      border: 1px solid #e8f0f8;
    }

    p {
      margin: 0;
      color: rgba(0, 0, 0, 0.54);
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 70%;
    }

    .survey-response {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin: 0 0 6px;
    }
  }
}

@mixin tags {
  display: flex;
  padding: 3px 15px;
  width: auto;
  border-radius: 20px;
  margin: 0;
  font-weight: 500;
  font-size: 12px;
}

.tag {
  @include tags;
}

.yellow-tag {
  @include tags;
  background: rgba(255, 214, 5, 0.12);
  color: #e7c206;
}

.orange-tag {
  @include tags;
  background: #fcefe0;
  color: #ed7c00;
}

.blue-tag {
  @include tags;
  // background: rgba(20, 106, 178, 0.11);
  background: #fff;
  color: #f85d18;
  border: 1px solid #f85d18;
}

.green-tag {
  @include tags;
  background: rgba(24, 214, 32, 0.11);
  color: #18d620;
}

.sidebar-overlay .survey-sidebar .sidebar-content {
  margin: 15px 12px;
}

.cardIcon {
  font-size: 18px;
  color: #fff;
  padding: 15px;
  margin-top: -40px;
  margin-right: 15px;
  border-radius: 3px;
  font-weight: bold;
  background-color: #f85d18;
}

.display-fr {
  display: flow-root;
}

#new_version button.blue-btn,
#new_version button.blue-btn:hover {
  background: #f85d18;
  display: flex;
  border-radius: 40px;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 12px;
  padding: 12px 30px;

  box-shadow: 0 2px 2px 0 rgb(153 153 153 / 14%),
    0 3px 1px -2px rgb(153 153 153 / 20%), 0 1px 5px 0 rgb(153 153 153 / 12%);
}

#new_version button.blue-btn img {
  width: 11px;
  height: 11px;
  margin-right: 5px;
}

#new_version .logDetailsPage h4 {
  margin-bottom: 0;
}

.sidebar-new {
  background: #fff;
  max-width: 70px;
  width: 100%;
  position: fixed;
  height: 100vh;
  z-index: 99;
  transition: 0.3s ease-in-out;
  transition: all 0.4s;
  border-right: 1px solid #f2f2f2;

  &.show {
    .show-lg {
      display: block;
    }

    .show-sm {
      display: none;
    }

    max-width: 260px;
    transition: all 0.4s;
  }
}

nav.navbar {
  position: relative;
  z-index: 9999;
}

nav.navbar li {
  list-style-type: none;
}

#new_version .survey-sidebar button.blue-btn {
  border-radius: 3px;
  font-weight: bold;
  font-size: 14px;
  padding: 10px 0;
  width: 100%;
}

#new_version .survey-sidebar .existing-surveys {
  display: flex;
  justify-content: center;
  padding: 20px 0 0;
  flex-direction: column;
}

.menu-sec {
  display: flex;
}

.menu-sec .Sidebar-list {
  height: 100vh;
  overflow: auto;
}

// .menu-sec .Sidebar-list li {
//   min-width: 100%;
// }
#new_version .survey-sidebar {
  position: absolute;
  top: 0;
  left: 260px;
  width: 280px;
  min-height: 100vh;
  background: #fff;
  left: 0;
  opacity: 0;
  transition: all 0.3s;
  visibility: hidden;
}

#new_version {
  .active {
    .survey-sidebar {
      // left: 260px;
      opacity: 1;
      visibility: visible;
      transition: all 0.3s;
    }
  }
}

.sidebar-new .side_bar_logo {
  flex-direction: column;
  height: 145px;

  padding: 20px 15px;
}

#new_version .Sidebar-list {
  margin: 20px auto 0;
  padding: 0 20px;
}

#new_version .sidebar-new .nav-link {
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  margin-top: 15px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    // background-color: rgba(200, 200, 200, 0.2) !important;
    // color: rgba(0, 0, 0, 0.54);
    color: #f85d18 !important;
  }
}

#new_version .sidebar-new .nav-link.active,
#new_version .sidebar-new .show > .nav-link {
  background: none !important;
  color: #f85d18 !important;

  &:hover {
    // background-color: rgba(200, 200, 200, 0.2) !important;
  }

  &:after {
    background: #f85d18;
    content: "";
    display: inline-block;
    width: 3px;
    height: 100%;
    position: absolute;
    left: -15px;
    border-radius: 0 5px 5px 0;
  }
}

.sidebar-new .side_bar_logo:after {
  border-bottom: 1px solid #ddd;
  content: "";
  width: calc(100% - 0px);
  height: 1px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.sidebar-new a.active {
  box-shadow: none;
}

.actv-txt {
  color: #f85d18 !important;
}

#new_version {
  .Sidebar-list.nav {
    padding: 0 0px;
    margin: 0 15px;
    flex-wrap: nowrap;
    height: auto;
  }

  .collapse:not(.show),
  .collapsing:not(.show) {
    .Sidebar-list.nav {
      margin: 0 10px;
    }
  }

  .collapse:not(.show),
  .collapsing:not(.show),
  .sidebar-new.hide {
    .nav-link {
      i {
        margin: 0;
      }

      font-size: 0;
      padding: 10px;
      justify-content: center;
    }
  }
}

.Sidebar-list li i {
  display: flex;
  width: 24px;
  height: 24px;
  background-color: #ddd;
  margin-right: 10px;
}

.Sidebar-list li .nav-link {
  i {
    filter: brightness(0);
  }
  i.dash-icon {
    background: url(../img/survey/analytics.svg) no-repeat center center / cover;
  }

  i.usermg-icon {
    background: url(../img/survey/user_manage.svg) no-repeat center center /
      cover;
  }

  i.surveys-icon {
    background: url(../img/survey/create.svg) no-repeat center center / cover;
  }

  i.auditLogs-icon {
    background: url(../img/survey/clock-rotate.svg) no-repeat center center /
      19px;
  }

  i.settings-icon {
    background: url(../img/survey/setting.svg) no-repeat center center / 20px;
  }

  i.logout-icon {
    background: url(../img/survey/logouticon.svg) no-repeat center center / 20px;
  }
  i.contacts-icon {
    background: url(../img/survey/contacts.svg) no-repeat center center / 20px;
  }
}

.Sidebar-list li .nav-link.active,
.Sidebar-list li .nav-link:hover {
  i {
    filter: brightness(1);
  }
  i.dash-icon {
    background: url(../img/survey/analytics_sl.svg) no-repeat center center /
      cover;
  }

  i.usermg-icon {
    background: url(../img/survey/user_manage_sel.svg) no-repeat center center /
      cover;
  }

  i.surveys-icon {
    background: url(../img/survey/create_sl.svg) no-repeat center center / cover;
  }

  i.auditLogs-icon {
    background: url(../img/survey/clock-rotate-sl.svg) no-repeat center center /
      19px;
  }

  i.settings-icon {
    background: url(../img/survey/setting_Sel.svg) no-repeat center center /
      20px;
  }
  i.contacts-icon {
    background: url(../img/survey/contacts_sl.svg) no-repeat center center /
      20px;
  }
  i.interviewer-icon {
    background: url(../img/survey/interviewer-icon-sel.svg) no-repeat center
      center/ 24px;
  }
}

.logout-menu {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: calc(100% - 30px);
  // margin-top: 70px;
}

.toggleMenu {
  display: flex;
  width: 50px;
  height: 50px;
  background: #1565d8;
  border-radius: 50%;
}

#new_version button.toggleMenu {
  display: flex;
  width: 26px;
  height: 26px;
  background: #f85d18;
  border-radius: 50% !important;
  position: absolute;
  right: -12px;
  top: 110px;

  img {
    width: 11px;
  }
}

#new_version .sidebar-new.collapse:not(.show),
#new_version .sidebar-new.collapsing:not(.show),
#new_version .sidebar-new.collapsing {
  display: block;
  width: 70px;
  height: 100vh;
  overflow: visible;
}

.Dashboard-mainPanel-2 {
  width: calc(100% - 0px);
}

.sidebar-new {
  .show-lg {
    display: none;
  }

  // &.collapse .side_bar_logo img, .side_bar_logo img,
  // &.collapsing .side_bar_logo img {
  //   min-width: 170px;
  // }
}

.sidebar-new.navbar-collapse {
  &.collapse .side_bar_logo,
  &.collapsing .side_bar_logo {
    overflow: hidden;
    padding: 20px 10px;
  }
}

.full-page {
  width: calc(100% - 70px) !important;
}

// #new_version .navbar-collapse.collapsing {
//   &:not(.show){
//   transition: all 0.3s !important; width: 260px;}
//   &.collapse {
//     transition: all 0.3s !important; width: 0px;
//   }
// }
.settingContainer {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.modal.blck-backdrop {
  background: rgba(0, 0, 0, 0.25);
}

@mixin blue-btn-lg($theme: #000) {
  background: $theme;
  box-shadow: 0 2px 2px 0 rgb(153 153 153 / 14%),
    0 3px 1px -2px rgb(153 153 153 / 20%), 0 1px 5px 0 rgb(153 153 153 / 12%);
  color: #fff;
  border-radius: 3px;
  padding: 10px 14px;
}

#new_version {
  .btn.btn-md,
  .btn.btn-md:hover {
    @include blue-btn-lg;
    width: 130px;
    font-size: 14px;
    font-weight: bold;
  }

  .btn.lg-blue-btn,
  .btn.lg-blue-btn:hover {
    @include blue-btn-lg;
    width: 100%;
    max-width: 300px;
    font-size: 14px;
    text-transform: uppercase;
  }

  #logoutModal {
    h5 {
      font-size: 1rem;
      font-family: $mdb-font-family;
      font-weight: 400;
      margin: 0;
    }
  }
}

// Slider section
#new_version .slider-sec {
  .carousel-indicators {
    width: auto;
    height: auto;
    position: relative;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0;
    gap: 25px;

    .typeBlocks {
      height: auto;
      position: relative;
      flex: 0 0 auto;
      text-indent: 0;
      opacity: 1;
      margin: 0;
      background-clip: border-box;
      box-sizing: border-box;
      border: 0;
      width: calc(33.33% - 30px);
      border: 2px solid transparent;
      border-radius: 10px;
      transition: all 0.5s;

      @media (max-width: 1440px) {
        width: calc(25% - 24px);
      }

      @media (max-width: 767px) {
        width: auto;
        min-width: 30%;
      }

      @media (max-width: 500px) {
        width: auto;
        min-width: 46%;
      }
    }

    .typeBlocks.active {
      border: 2px solid #f85d18;
      background: #f85d18;
      color: #fff;

      img {
        filter: invert(100%);
      }
    }
  }

  .carousel-inner {
    height: 100%;
    width: 350px;

    @media (max-width: 1900px) {
      width: 270px;
    }

    .carousel-item {
      width: 100%;
      max-height: 700px;
      height: 100%;
      max-width: 100%;

      img {
        max-height: 100%;
        height: auto;
      }
    }
  }
}

#new_version.updatePassword {
  .modal.show {
    display: block;
  }

  .modal-dialog {
    max-width: 384px;
  }
}

#new_version.updatePassword {
  .eyeIcon {
    position: absolute;
    right: 0;
    height: 100%;
    max-height: none;
    margin: 0;
    top: 0;
    bottom: 0;
    max-height: none;
  }

  #updateModal h4 {
    font-size: 1.25rem;
    font-family: $mdb-font-family;
    font-weight: 500 !important;
    line-height: 1.6;
  }

  label {
    font-size: 16px;
  }

  .form-control {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    height: calc(3rem + 0px) !important;
    padding: 0.4rem 0.75rem !important;

    &:hover {
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    }
  }
}

.btn.btn-white {
  background-color: #fff !important;
  border: 0 !important;
  text-transform: uppercase !important;
  color: rgba(0, 0, 0, 0.8) !important;
  box-shadow: none !important;
}

// .passTxt input[type="password"] {
//   background: url(../images/eye-fill.svg) no-repeat center right 10px;
// }

// .passTxt input[type="text"] {
//   background: url(../images/eye-slash-fill.svg) no-repeat center right 10px;
// }
.MuiFormControlLabel-label {
  color: #000;
}

#new_version .ms-md-6 {
  @media screen and (max-width: 1152px) {
    margin-left: 20px !important;
  }

  @media screen and (max-width: 990px) {
    margin-left: 0px !important;
  }

  margin-left: 80px !important;
}

#new_version {
  .form-control.input-border {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    font-size: 0.8em;

    &.no-bg {
      background: none;
    }
  }

  .tab-content > .active {
    display: flex !important;
    flex-direction: column;
  }

  .createSurveyForm {
    .card {
      border: 0;
      border-radius: 5px;
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    }

    .card-body {
      padding: 30px 40px 30px 25px;
    }
  }
}

// Accordion Design
#new_version {
  .sub-question #innerAccordion .option-desc .deleteSection {
    color: #fff;
    background: #f85d18;
    border-color: #c53d00;
  }

  .section-number {
    background: #f85d18;
    padding: 16px 14px 13px;
    width: 100%;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 8px 8px 0px 0px;
  }

  .no-data-sec {
    img {
      max-width: 26em;
    }
    h2 {
      font-weight: 600 !important;
    }
    h5 {
      color: rgba(112, 112, 112, 1);
      font-weight: normal !important;
    }
  }

  .main-tab-container > {
    .nav.nav-tabs {
      gap: 20px;
      border-bottom: 1px solid #cacaca;
      width: 100%;

      .nav-item > {
        .nav-link {
          padding: 12px 2px 10px;
          background: none !important;
          font-size: 1em;
          font-weight: 600;
          color: #667085;
          font-family: Lato, sans-serif;

          &.active,
          &:hover {
            color: #f85d18;
            background: none;
            border-bottom: 2px solid #f85d18 !important;
          }
        }
      }
    }
  }

  #survey-accordion {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .text-fields p {
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      margin-bottom: 10px;
      font-family: $mdb-font-family;
    }

    .uniqueId {
      margin: 0;
      align-self: center;
      max-width: 200px;

      .form-switch {
        .form-check-input {
          background: #949494;
          border: 0;
          width: 2.4em;
          height: 14px;
          margin-top: 10px;

          transition: all 0.3s;

          &:checked {
            // background-color: rgba(21, 84, 246, 0.5);
            // background-color: rgb(20 105 178 / 50%);
            background-color: rgba(248, 93, 24, 0.5);

            &::after {
              left: 1.2em;
              // background: #1554F6;
              background: #f85d18;
            }
          }

          &:after {
            content: "";
            display: inline-block;
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            top: -3px;
            background: #fff;
            box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.25);
            transition: all 0.3s;
          }
        }
      }

      p {
        margin-bottom: 0;
        color: #555;
        font-weight: 500;
        font-size: 12px !important;
        line-height: 14px !important;
      }
    }

    .accordion-item {
      background: #fff;
      border-radius: 7px 7px 0 0;
      border-color: #f85d18;
      overflow: hidden;

      .hand-icons {
        position: absolute;
        top: 5px;
        right: 70px;
        border-right: 1px solid #f85d18;
        height: 80%;
        display: flex;
        align-items: center;
        padding: 0px 20px;
        z-index: 9;

        .deleteSection {
          display: flex;
          align-items: center;
          font-weight: normal;
          justify-content: space-between;
          margin: 0;

          img {
            margin: 0;
          }
        }
      }

      .accordion-button {
        // border-top-left-radius: 7px;
        // border-top-right-radius: 7px;
        // background: #f85d18;
        background: rgba(248, 93, 24, 0.06);
        padding: 16px 14px 13px;
        width: 100%;
        // box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
        box-shadow: none;
        border-radius: 0px;
        color: #000;
        border-bottom: 1px solid #f85d18;

        &.collapsed::after {
          background: url(../images/down-arrow.svg) no-repeat center center !important;
          transform: rotate(0deg);
        }

        &:not(.collapsed):after {
          background: url(../images/down-arrow.svg) no-repeat center center !important;
          transform: rotate(180deg);
        }
      }

      &:last-of-type {
        border-radius: 7px 7px 0 0;

        .accordion-button {
          border-radius: 7px 7px 0 0;
        }
      }
    }

    .form-group {
      position: relative;
    }
  }

  .text-danger.errorMsg {
    color: #930a2b !important;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: $mdb-font-family;
    font-weight: 400;
    line-height: 1.66;
  }

  .sub-question {
    #innerAccordion {
      .accordion-item {
        box-shadow: 0px 1px 4px rgb(0 0 0 / 50%);
        border-radius: 7px;
        overflow: hidden;
        background: rgba(248, 93, 24, 0.06);

        .accordion-button {
          border-radius: 0;
          background: rgb(255 245 241);
          // border: 1px solid #f85d18;
          box-shadow: none;
          padding-left: 40px;
          color: #000;
          font-weight: normal;
          border-bottom: 1px solid #f85d18;
          line-height: normal;

          &.collapsed::after {
            background: url(../images/drop-down-blue.svg) no-repeat center
              center !important;
            transform: rotate(0deg);
          }

          &:not(.collapsed)::after {
            background: url(../images/drop-down-blue.svg) no-repeat center
              center !important;
            transform: rotate(180deg);
          }

          &::before {
            content: "";
            display: inline-block;
            width: 30px;
            height: 100%;
            position: absolute;
            left: 0;
            background: #f85d18 url(../images/dots_accd.svg) no-repeat center
              center;
            cursor: move;
            cursor: grab;
            top: 0;
          }
        }

        .accordion-body {
          position: relative;
          padding-left: 30px;
          padding-right: 0;

          &::before {
            content: "";
            display: inline-block;
            width: 30px;
            height: 100%;
            position: absolute;
            left: 0;
            background: #f85d18;
            top: 0;
          }

          .question-options {
            width: 100%;
          }

          .delRowButton,
          .addRowButton {
            width: 22px;
            height: 22px;
            margin-left: 10px;
            margin-top: 16px;
            padding: 9px 5px;
            position: relative;
            cursor: pointer;

            &:after {
              content: "";
              width: 10px;
              height: 1px;
              top: 11px;
              background-color: rgb(255, 255, 255);
              border-radius: 3px;
              display: inline-block;
              position: absolute;
              margin: 0 auto;
              left: 0;
              right: 0;
            }
          }

          .addRowButton {
            background: #f85d18 url(../img/add_white.svg) no-repeat center
              center / 10px;

            &::after {
              display: none;
            }

            // &::before {
            //   content: "";
            //   display: inline-block;
            //   width: 2px;
            //   height: 12px;
            //   background: #fff;
            //   position: absolute;
            //   left: 0;
            //   right: 0;
            //   margin: auto;
            //   border-radius: 3px;
            //   top: 50%;
            //   transform: translate(0, -50%);
            // }
          }

          .question-options .form-floating > .form-control {
            height: calc(2.4rem + 2px);
          }
        }
      }

      .question-num {
        flex-basis: 60px;
        font-size: 20px;
        color: #000;
        font-weight: 700;
        margin-left: 10px;
        padding-top: 14px;
      }

      .deleteSection {
        color: rgb(183, 96, 89);
        border: 1px solid rgb(183, 96, 89);
      }
    }

    .jumpItem {
      display: flex;
      align-items: center;
    }
  }

  .sm-icon {
    width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
  }

  .light-blue-btn {
    background-color: #f85d18;
    text-transform: uppercase;
    color: #fff;
    border-radius: 3px;
    padding: 12px 30px;
    position: relative;
    font-size: 12px;
    min-width: auto;
    transition: all 0.3s;
    box-shadow: 0 2px 2px 0 rgb(153 153 153 / 14%),
      0 3px 1px -2px rgb(153 153 153 / 20%), 0 1px 5px 0 rgb(153 153 153 / 12%);

    &:hover {
      box-shadow: 0 14px 26px -12px rgb(153 153 153 / 42%),
        0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(153 153 153 / 20%);
    }
  }

  .MuiSelect-select {
    display: flex !important;
    align-items: center;
  }

  .card.options-sec {
    flex-direction: row;

    .opt-no {
      min-width: 40px;
      display: flex;
      background: #000000 url(../images/dots_accd.svg) no-repeat 4px 10px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      font-size: 26px;
      color: #fff;
      cursor: grab;
      text-align: center;
      margin-left: -1px;
      float: left;

      &::before {
        background: #f85d18 url(../images/dots_accd.svg) no-repeat center center;
      }
    }

    textarea.form-control {
      resize: none;
    }

    .form-control.grey {
      background: #efefef;
    }

    .card-body {
      padding: 16px 15px;
    }

    .option-desc {
      // box-shadow: none;

      .langLabel {
        justify-content: space-between;
      }

      .hand-icons {
        right: 0 !important;
        top: 0 !important;
        border: 0 !important;
        z-index: 9;

        .deleteSection {
          color: rgb(183, 96, 89);
          border: 1px solid rgb(183, 96, 89);
        }
      }
    }
  }

  .add-dropdown {
    .dropdown-menu {
      border-radius: 0;
      padding: 0;
    }

    li a {
      font-size: 0.875rem;
      font-family: $mdb-font-family;
      font-weight: 500;
      line-height: 32px;
      color: #000000;
      padding: 0 5px;
      text-decoration: none;
      display: block;
      cursor: pointer;

      &:active {
        background-color: #e9e9e9;
        color: #000;
      }
    }

    .btn.dropdown-toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 130px;
      background-color: #000000;
      color: #fff;
      border-radius: 4px;
      font-size: 0.875rem;
      font-family: $mdb-font-family;

      span {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }

    display: flow-root;
    clear: both;
  }

  .addLink {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    font-family: $mdb-font-family;
    font-weight: 500;
    padding: 8px 10px;
    margin: 10px auto;
    cursor: pointer;
    display: inline-block;
    border-radius: 4px;
    transition: all 0.3s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .closeIcon {
    background: none;
  }

  .addBtn {
    display: flex;
    width: auto !important;
    background: #f85d18;
    color: #fff;
    padding: 7px 10px;
    font-weight: 500;
    font-family: $mdb-font-family;
    border-radius: 4px;
    font-size: 13px;
    align-items: center;

    svg {
      font-size: 22px;
    }
  }

  .addSection-btn {
    font-weight: 700 !important;
    font-size: 18px;
    line-height: 21px;
    color: #555555;
    display: flex;
    font-family: $mdb-font-family;
    align-items: center;
    background: none;
    gap: 4px;
    align-self: start;

    svg {
      font-size: 32px;
    }
  }

  .btn.btn-wht {
    box-shadow: 0 2px 2px 0 rgb(153 153 153 / 14%),
      0 3px 1px -2px rgb(153 153 153 / 20%), 0 1px 5px 0 rgb(153 153 153 / 12%);
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    align-self: start;
    width: auto;
    border-radius: 4px;
    background: #fff;
    gap: 10px;
    font-weight: 500;
    font-size: 14px;
    padding: 8px 20px;
    transition: all 0.3s;

    &:hover {
      box-shadow: 0 14px 26px -12px rgb(153 153 153 / 42%),
        0 4px 23px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(153 153 153 / 20%);
    }
  }
}

span.drop-icon {
  width: 16px;
  margin: 0 10px 0 0 !important;
  display: inline-block;
  padding: 0 !important;

  img {
    max-width: 100%;
  }
}

#new_version {
  .modal {
    z-index: 9999 !important;
  }

  .modalPopup {
    &.blck-backdrop {
      background-color: rgba(0, 0, 0, 0.5);
    }

    .modal-content {
      border: 0;
      border-radius: 20px;
      max-width: 995px;
      margin: 0 auto;
      box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
        0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);

      button.btn-close {
        background: #fff;
        padding: 0px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        right: 20px;
        opacity: 0.9;

        svg {
          fill: #f85d18;
        }
      }
    }
  }

  .planListDetail {
    align-items: center;
  }

  .packageDetail .bottom-row {
    gap: 0px;
  }

  // Font Family Roboto
  p.planHeading,
  p.planPrice,
  p.planInfo,
  .table thead th,
  .table tbody td,
  .card-title {
    font-family: $mdb-font-family;
  }
}

#new_version {
  .col-box {
    min-height: 690px;
    display: flex;
    padding: 0 20px !important;

    @media screen and (max-width: 1500px) {
      min-height: 524px;
    }

    @media screen and (max-width: 565px) {
      margin-bottom: 24px;
      min-height: 0;
    }
  }

  #image-container {
    @media screen and (max-width: 565px) {
      padding: 20px 0;
    }
  }

  .rotate20 {
    transform: rotate(20deg) translate(-100px, 0px);

    @media screen and (max-width: 980px) {
      transform: rotate(20deg) translate(0px, 0px);
      max-height: 100%;
    }

    @media screen and (max-width: 565px) {
      transform: rotate(20deg) translate(-37px, 0px);
      max-height: 100%;
      width: 82%;
    }

    @media screen and (max-width: 400px) {
      transform: rotate(20deg) translate(-27px, 0px);
    }
  }
}

// Add user
#new_version {
  .title-sec {
    display: flex;
    padding: 20px 0;
    align-items: center;
    text-align: left;
  }

  .card {
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06),
      0px 1px 3px rgba(16, 24, 40, 0.1);
    border-radius: 5px;
    text-align: left !important;
  }

  button.bckBtn {
    width: 30px;
    height: 30px;
    background: #f85d18 url(../images/left-angle.svg) no-repeat center center;
    border-radius: 4px;
    margin-right: 10px;
  }

  h3.titleHeading {
    font-weight: 700 !important;
    font-size: 1.5em;
    margin: 0;
    text-align: left !important;
  }

  .card-title {
    font-size: 1.25em;
    text-align: left !important;
    font-weight: 500 !important;
  }

  .contentSec {
    label {
      color: #525252;
      font-size: 1em;
      font-weight: 400;

      sup {
        color: #e20000;
        font-size: 1em;
        top: 0;
      }
    }

    .form-control {
      background: rgba(31, 18, 30, 0.05);
      border: 1px solid rgba(31, 18, 30, 0.05);
      border-radius: 4px;
    }
  }

  .roundedTable {
    border: 1px solid #ddd;
    border-radius: 15px;
  }

  table.newTable {
    margin: 0;
    color: $colorgreyFont;

    thead {
      box-shadow: 0px 1px 2px rgb(16 24 40 / 6%),
        0px 1px 3px rgb(16 24 40 / 10%);
      border: 1px solid #eaecf0;

      th {
        background: #f9fafb !important;
        padding: 10px 20px;
        font-size: 1em;
        font-weight: 500;
        border: 0;
      }
    }

    tbody td {
      padding: 25px 20px;
      font-size: 1em;
      vertical-align: middle;

      strong {
        font-weight: 500;
      }
    }
  }

  .radio-btn {
    display: flex;
    justify-content: center;

    input[type="radio"] {
      display: none;
    }

    label {
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 50%;
      border: 1px solid #000;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);

      &::before {
        content: "";
        width: 10px;
        height: 10px;
        background: #f85d18;
        border-radius: 50%;
        opacity: 0;
        transition: all 0.3s;
      }
    }

    input:checked {
      + label {
        border: 1px solid #f85d18;

        &::before {
          display: inline-block;
          opacity: 1;
        }
      }
    }
  }

  .btn.btn-md,
  .btn.btn-md:hover {
    font-weight: normal;
    font-size: 1em;
  }

  .survey-setup {
    .label {
      font-size: 14px;
      font-weight: bold;
      padding-bottom: 10px;
      color: #646464;
      font-family: $mdb-font-family;
    }

    p.note-des {
      color: #757575;
    }

    .upload-file {
      border: 2px dashed #949494;
      border-radius: 8px;
      height: 100%;
      width: 100%;
      cursor: pointer;
      padding: 10px;
      height: 125px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      p.browse-txt {
        color: #f85d18;
        cursor: pointer;
        margin: 0;
        font-weight: 500;
        font-size: 12px;
      }

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: pointer;
        opacity: 0;
      }
    }
  }

  .surveyList {
    max-width: 426px;
  }

  .minMax {
    align-items: center;

    .form-floating > .form-control {
      height: auto;
    }
  }
}

#new_version button.btn {
  font-family: Lato, sans-serif !important;
  font-weight: 600 !important;
}

.placeHolder {
  position: absolute;
  right: 0;
  top: 30px;
}

.display-flow-root {
  display: flow-root;
}

.jumpItem,
.dropdown {
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    fill: #e77e2e;
  }
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.2);
  border-radius: 25px;
  border: transparent;

  &:hover {
    background-color: rgba(155, 155, 155, 0.3);
  }
}

// Survey List
#new_version {
  .surveyList {
    background: #fff;
    padding: 15px;
    height: 100vh;
    overflow: auto;
    position: sticky;
    top: 0;
    bottom: 0;

    small {
      color: rgba(0, 0, 0, 0.5);
    }

    h4 {
      line-height: 1;
    }

    .icoSm {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      background: #f85d18;

      border-radius: 3px;

      &.delete {
        background: rgba(248, 93, 24, 0.08) url(../images/delete_icon_inactive.svg)
        no-repeat center;
        @media screen and (max-width:1440px) {
          background-size: 22px;
        }
      }

      &.share {
        background: rgba(248, 93, 24, 0.08) url(../images/share_blue.svg)
          no-repeat center;
      }

      &.link {
        background: rgba(255, 190, 92, 0.06) url(../images/link_blue.svg)
          no-repeat center;
      }

      &.pause {
        background: rgba(248, 93, 24, 0.08) url(../images/pause_blue.svg)
          no-repeat center;
      }

      &.play {
        background: rgba(248, 93, 24, 0.08) url(../images/play_arrow_blue.svg)
          no-repeat center / 24px;
      }

      img {
        max-width: 30px;
      }
    }

    .active {
      .icoSm {
        
        &.delete {
          background: #f85d18 url(../images/delete_icon_active.svg)
          no-repeat center;
          @media screen and (max-width:1440px) {
            background-size: 22px;
          }
        }

        &.share {
          background: #f85d18 url(../images/share.svg) no-repeat center;
        }

        &.link {
          background: #ffbe5c url(../images/link.svg) no-repeat center;
        }

        &.pause {
          background: #f85d18 url(../images/pause.svg) no-repeat center;
        }

        &.play {
          background: #f85d18 url(../images/play_arrow_white.svg) no-repeat
            center / 24px;
        }
      }
    }

    ul.no-listing {
      position: relative;
      top: 10%;
      transform: translate(0px, 0);

      li {
        list-style: none;
      }
    }

    .filterIcon.disabled {
      pointer-events: none;
      opacity: 0.6;
    }

    ul.listings {
      display: flex;
      flex-direction: column;
      font-family: $mdb-font-family;
      padding: 15px 7px;
      max-height: calc(100vh - 100px);
      overflow: auto;
      margin: 0 -15px;

      li.list {
        list-style: none;
        padding: 10px 15px;
        margin: 0 -7px;
        display: flex;
        justify-content: space-between;
        gap: 2px;
        cursor: pointer;

        .listname {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          max-width: calc(100% - 190px);
          @media screen and (max-width:1440px) {
            max-width: calc(100% - 150px);
          }

          strong {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
        }
      }

      .right-end {
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .timings {
          color: #707070;
        }
      }

      .nameInitial {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        min-width: 45px;
        height: 45px;
        border-radius: 50%;
        background: rgba(248, 93, 24, 0.08);
        font-size: 28px;
        font-weight: 400;
        color: #f85d18;
        aspect-ratio: 1;
      }
    }

    .list {
      border-top: 1px solid transparent;
      border-bottom: 1px solid rgba(239, 239, 239, 1);
    }

    .list.active {
      background: rgba(248, 93, 24, 0.06);
      border-top: 1px solid #f85d18;
      border-bottom: 1px solid #f85d18;

      .nameInitial {
        background: #f85d18;
        color: #fff;
      }
    }
  }

  .filterIcon {
    width: 45px;
    height: 45px;
    background: #000;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    .dropdown-header {
      font-family: $mdb-font-family;
      font-weight: 500;
      font-size: 16px;
      color: #4c5773;
      text-transform: none;
    }

    svg {
      font-size: 2rem;
      color: #fff;
      fill: #fff;
    }
  }

  .form-control.search-bar {
    border: 1px solid #eaecf0;
    border-radius: 3px;
    height: 100%;
  }

  button.btn.addSurvey {
    &:hover {
      width: auto;
    }

    width: auto;
  }

  .Search-sec {
    position: relative;

    .search-bar {
      padding-left: 45px;
    }

    .searchBtn {
      right: auto;
      left: 10px;
      top: 50%;
      transform: translate(0, -50%);

      svg {
        font-size: 28px;
        opacity: 0.54;
      }
    }
  }

  .fileIcon {
    margin: 10px auto;

    svg {
      font-size: 40px;
      color: rgb(117, 117, 117);
    }
  }

  .searchFilterSec {
    display: none;
  }

  .surveyResponse {
    .admin-content-box {
      padding: 0;
    }

    .search-filter-all {
      display: none;
    }

    .searchFilterSec {
      display: block;
    }

    table {
      min-width: 0;

      tr th:nth-of-type(4) {
        white-space: nowrap;
      }
    }

    .editIcon {
      img {
        // max-height: 30px !important;
        // max-width: 30px !important;
        max-height: 1.875em !important;
        max-width: 1.875em !important;
      }
    }
  }

  .fraudStatus {
    position: relative;

    .dropdown-toggle {
      padding: 3px 15px 2px;
      border-radius: 26px;
      font-size: 14px;
      font-weight: 500;
      font-family: Lato, sans-serif;
      width: 100%;

      svg {
        margin-right: -15px;
      }

      &:after {
        border: 0;
      }

      &.greenColor {
        background: rgba(44, 180, 66, 0.16);
        color: #2cb442;
      }

      &.redColor {
        background: #ffe2e2;
        color: #dd2424;
      }

      &.blueColor {
        background: rgba(248, 93, 24, 0.06);
        color: #f85d18;
      }

      &.yellowColor {
        background: rgba(237, 124, 0, 0.14);
        color: #ed7c00;
      }
    }

    .dropdown-menu {
      border: 0;
      overflow: hidden;
      width: 100%;
    }

    li {
      padding: 10px 15px;
      line-height: normal;
      margin-bottom: 5px;
      cursor: pointer;

      &:last-child {
        margin: 0;
      }

      &.greenColor {
        background: rgba(44, 180, 66, 0.16);
        color: #2cb442;
      }

      &.redColor {
        background: #ffe2e2;
        color: #dd2424;
      }

      &.blueColor {
        background: rgba(248, 93, 24, 0.06);
        color: #f85d18;
      }

      &.yellowColor {
        background: rgba(237, 124, 0, 0.14);
        color: #ed7c00;
      }
    }
  }

  .modal.fade.half-slide-modal {
    &.show {
      .modal-dialog {
        transform: translate(0px, 0px);
      }
    }

    .modal-header {
      border-bottom: 1px solid #cacaca;
    }

    .blue-link {
      .nav-link {
        background: none;
        font-weight: bold;
        font-family: Lato, sans-serif;
        border-bottom: 2px solid transparent;
        color: #667085;
        display: flex;
        align-items: center;
        gap: 5px;
        line-height: normal;

        &.active {
          color: #f85d18;
          background: none;
          border-bottom: 2px solid #f85d18;
        }
      }
    }

    .modal-dialog {
      position: fixed;
      right: 0;
      top: 0;
      left: auto;
      width: 35%;
      max-width: none;
      height: 100%;
      margin: 0;
      border-radius: 0;
      border: 0;
      transform: translate(100%, 0px);

      @media screen and (max-width: 1280px) {
        width: 50%;
      }

      .modal-content {
        border: 0;
        border-radius: 0;
        height: 100%;
      }
    }

    .info-listing {
      max-height: calc(100vh - 90px);
      overflow: auto;
    }
  }

  .respondent-sec {
    .btn-close {
      margin-top: -1.5rem;
    }

    .nav {
      gap: 20px;
    }
  }

  .info-listing {
    font-family: $mdb-font-family;

    li {
      list-style: none;
      display: flex;
      justify-content: space-between;
      padding: 10px 0;

      * {
        flex: 0 0 45%;
      }
    }
  }

  .green-txt {
    color: #2cb442;
  }

  .red-txt {
    color: #dd2424;
  }

  .publishedOn {
    h5 {
      font-size: 1em;
      display: flex;
      justify-content: end;
      text-align: right;
      margin: 0;
    }
  }

  #surveySetting {
    .admin-content-box {
      padding: 13px;
    }

    .form-floating {
      input.form-control {
        height: 3.5em;
        padding: 5px 0;
        font-size: 1em;
      }
    }

    .form-switch {
      .form-check-input {
        background: #949494;
        border: 0;
        width: 2.4em;
        height: 14px;
        margin-top: 10px;

        transition: all 0.3s;

        &:checked {
          // background-color: rgba(21, 84, 246, 0.5);
          background-color: rgba(248, 93, 24, 0.5);
          &::after {
            left: 1.2em;
            background: #1554f6;
          }
        }

        &:after {
          content: "";
          display: inline-block;
          position: relative;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          top: -3px;
          background: #fff;
          box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.25);
          transition: all 0.3s;
        }
      }
    }
  }

  .createdBy {
    .info-listing li {
      span {
        color: rgba(0, 0, 0, 0.54);
      }
    }
  }

  .qrcode {
    margin: 0;

    svg {
      font-size: 10em;
    }
  }

  .org-sec {
    &.dropdown-toggle::after {
      background: url(../images/drop-down-blue.svg) no-repeat center center /
        18px !important;
      border: 0 !important;
      width: 20px;
      height: 20px;
    }

    align-items: center;

    color: #3d3d3d;
  }

  li.dropdown,
  li.dropdown:hover {
    background: rgba(248, 93, 24, 0.06);
    border: 1px solid #f85d18;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;

    a {
      color: #3d3d3d;
      padding: 12px 10px;
      min-width: 100%;
      display: flex;
      justify-content: start;
      gap: 8px;

      &:hover {
        background: rgba(248, 93, 24, 0.06);
      }
    }

    .dropdown-menu {
      padding: 0 10px;
      width: 100%;

      li {
        cursor: pointer;
        padding: 10px 0;
      }
    }
  }

  figure.circleImg {
    display: flex;
    background: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 0;
    justify-content: center;
    align-items: center;
    align-self: start;
    margin-top: 5px;
  }

  .preview-box {
    justify-content: start;
    gap: 15px;

    .imgBox {
      max-width: 120px;
      position: relative;

      a.crossIcon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: -8px;
        top: -8px;
        width: 27px;
        height: 27px;
        background: #f85d18;
        border: 1px solid #fff;
        border-radius: 50%;
        color: #fff;
        cursor: pointer;

        svg {
          width: 19px;
        }
      }
    }

    figure {
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
      margin: 0;
      height: 120px;
      width: 120px;
      border-radius: 10px;
      overflow: hidden;
      border: 2px dashed #666666;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        background-color: #fff;
      }
    }
  }

  .smaller {
    font-size: 14px;
    line-height: 20px;
  }

  select.blue-select {
    border: 0;
    background: none;
    color: #f85d18;
    min-width: 120px;
    text-indent: -0px;
  }

  .dropdownSelect {
    min-width: 0;
  }

  // .userMgmt, .auditLogs {
  //  #new_version .container { max-width: none;}
  // }
}

.userMgmt,
.auditLogs,
#dashboardWrap {
  #new_version .container {
    max-width: none;
    width: 100%;
  }
}

.search-filter-all {
  padding: 0 !important;
}
.searchFilter button {
  background: #000;
}

#new_version .Sidebar-list.nav {
  overflow: visible;
}

.br-3 {
  border-radius: 3px;
}

.MuiTooltip-tooltip {
  background: #525252 !important;
}

.MuiTooltip-arrow {
  color: #525252 !important;
}

.exportBtn button {
  border-radius: 3px !important;
  // background:#000;
}

.filters {
  display: inline-flex;
  justify-content: center;
  padding: 6px 10px;
  font-size: 11px;
  color: #fff;
  background: #000;
  border-radius: 20px;
  margin: 10px auto 0;
}

.placeHolderTxt {
  opacity: 0.5;
}

.surveyHeading .addQuota span {
  white-space: nowrap;
}

#new_version .collapse:not(.show).errorActive,
#new_version .collapse.errorActive {
  display: block;
}

.accordion-button.disabled {
  pointer-events: none;
}

.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root,
.css-1sucic7 {
  position: absolute;
}

.progressBar {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  flex-direction: column;

  .progressDiv {
    position: relative;
    display: flex;
    width: 200px;
    height: 200px;
    justify-content: center;
    align-items: center;
  }
}
